import React, { useState } from "react";
import { Box, Stack } from "@mui/material";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SVG } from "../../icon/svg.file";

export default function SingleDatePicker({ onData, minDate, disabled }) {
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date); // Update local state
    onData(date); // Invoke parent callback with selected date
  };

  return (
    <Box
      sx={{
        "& .react-datepicker-wrapper": { display: "block" },
        "& .datepiker-single": {
          width: "100%",
          borderRadius: "10px",
          background: "#00000066",
          height: "56px",
          border: "0px",
          outline: "none",
          fontSize: "15px",
          color: "#fff",
          paddingLeft:
            localStorage.getItem("i18nextLng") === "ar" ? "40px" : "15px",
          paddingRight:
            localStorage.getItem("i18nextLng") === "ar" ? "40px" : "15px",
        },
        "& .react-datepicker__calendar-icon": {
          marginRight: "10px",
          left: localStorage.getItem("i18nextLng") === "ar" && "0px",
          marginLeft: localStorage.getItem("i18nextLng") === "ar" && "10px",
          right: localStorage.getItem("i18nextLng") === "ar" && "auto",
        },
        "& .react-datepicker": {
          background: "#1B1B1B",
          fontSize: "15px",
          color: "#fff",
          borderRadius: "10px",
          border: "0px",
        },
        "& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name":
          {
            color: "#fff",
          },
        "& .react-datepicker__header": {
          background: "#1B1B1B",
          padding: "10px",
        },
        "& .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover":
          {
            background: "#FBC515",
            borderRadius: "100%",
          },
        "& .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected":
          {
            background: "#FBC515",
            borderRadius: "100%",
          },
        "& .react-datepicker__day--selected": {
          background: "#FBC515",
          borderRadius: "100%",
        },
        "& .react-datepicker__day--disabled": {
          color: "#666666", // Faded color for disabled dates
          backgroundColor: "#1B1B1B", // Ensure the background is the same as the calendar background
        },
      }}
    >
      <DatePicker
        className="datepiker-single"
        selected={selectedDate}
        onChange={handleDateChange} // Call handleDateChange on date change
        showIcon
        toggleCalendarOnIconClick
        showPopperArrow={false}
        minDate={minDate ? minDate : new Date()} // Disable past dates
        disabled={disabled ? disabled : false}
        dateFormat={"dd/MM/yyyy"}
        customInput={
          <div
            style={{ display: "flex", alignItems: "center" }}
            readOnly
            //value={selectedDate ? selectedDate.toLocaleDateString("en-US") : ""}
            className="datepiker-single"
          >
            {selectedDate ? selectedDate.toLocaleDateString("en-US") : ""}
          </div>
        }
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          monthDate,
        }) => (
          <>
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              sx={{
                mx: 1,
                borderRadius: "10px",
                px: 2,
                py: 1,
                mb: 1,
                color: "#fff",
                border: "1px solid #fff",
                "& input": {
                  background: "transparent",
                  border: "0px",
                  outline: "none",
                  p: 1,
                  width: "100%",
                  color: "#fff",
                  fontSize: "15px",
                },
              }}
            >
              <SVG.CalenIcon />
              <div
                style={{ display: "flex", alignItems: "center" }}
                onTouchStart={(e) => e.preventDefault()}
                readOnly
                // value={date.toLocaleDateString("en-US")}
                onChange={(event) =>
                  setSelectedDate(new Date(event.target.value))
                }
              >
                {date.toLocaleDateString("en-US")}
              </div>
            </Stack>
            <Box
              sx={{
                position: "relative",
                my: 2,
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <button
                aria-label="Previous Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--previous"
                }
                onClick={decreaseMonth}
                disabled={monthDate <= new Date()} // Disable the previous button if the current month is reached
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--previous"
                  }
                >
                  {"<"}
                </span>
              </button>
              <span
                className="react-datepicker__current-month"
                style={{
                  height: "100%",
                  display: "inline-block",
                  color: "#fff",
                  marginTop: "8px",
                }}
              >
                {monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
              </span>
              <button
                aria-label="Next Month"
                className={
                  "react-datepicker__navigation react-datepicker__navigation--next"
                }
                onClick={increaseMonth}
              >
                <span
                  className={
                    "react-datepicker__navigation-icon react-datepicker__navigation-icon--next"
                  }
                >
                  {">"}
                </span>
              </button>
            </Box>
          </>
        )}
      />
    </Box>
  );
}
