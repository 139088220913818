import { MenuItem, Select } from "@mui/material";
import React, { useEffect } from "react";
import EnglishFlag from "../images/english.png";
import SpanishFlag from "../images/spanish.png";
import ArabicFlag from "../images/arbic.png";
import FrenchFlag from "../images/french.png";
import RussianFlag from "../images/russian.png";

import { useTranslation } from "react-i18next";

export default function Language(props) {
  const { i18n } = useTranslation("en");
  const [selectLang, setSelectLang] = React.useState("en-US");


  const handleChange = (event,popupState) => {
    const language = event.target.value;
    i18n.changeLanguage(language);
    setSelectLang(event.target.value);
    if (language === "ar") {
      document.body.classList.add("activeClass");
    } else {
      document.body.classList.remove("activeClass");
    }
    localStorage.setItem("selectedLanguage", language);

    // Close the popover after language select
    if(popupState && popupState?.close){
      popupState.close();
    }
  };

  useEffect(() => {
    // console.log(">>>>>", i18n.language);
    const selectedLanguage = localStorage.getItem("i18nextLng");
    if (selectedLanguage) {
      if (selectedLanguage.startsWith("en")) {
        setSelectLang("en-US");
      } else {
        setSelectLang(selectedLanguage);
      }
      if (selectedLanguage === "ar") {
        document.body.classList.add("activeClass");
      } else {
        document.body.classList.remove("activeClass");
      }
    }
  }, []);

  const MenuProps = {
    PaperProps: {
      sx: {
        background: "#0E0E0E",
        "& .MuiList-root": {
          padding: "15px !important",
          color: "#fff",

          "& .MuiButtonBase-root": {
            background: "#282520",
            mb: 1,
            borderRadius: "5px",
            gap: "10px",
            "& img": { width: "20px", height: "20px" },
          },
        },
      },
    },
  };
  return (
    <>
      <Select
        defaultValue="Select language"
        displayEmpty
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={selectLang || "en-US"}
        size="small"
        onChange={(e)=>handleChange(e,props.popupState)}
        MenuProps={MenuProps}
        sx={{
          width: "200px",
          border: props.border || "1px solid #ccc",
          background: props.bgColor || "",
          color: "#fff",
          "& fieldset": {
            display: "none",
          },
          "& .MuiSelect-icon": { color: "#fff" },
          "& img": { width: "20px", height: "20px" },
          "& .MuiSelect-select": {
            display: "flex",
            alignItems: "center",
            gap: "10px",
          },
        }}
      >
        <MenuItem value="" disabled sx={{ display: "none" }}>
          Select language
        </MenuItem>
        <MenuItem value="en-US">
          {" "}
          <img src={EnglishFlag} alt="English" /> <span>English</span>
        </MenuItem>
        <MenuItem value="es">
          <img src={SpanishFlag} alt="Spanish" />
          <span>Spanish</span>
        </MenuItem>
        <MenuItem value="ar">
          <img src={ArabicFlag} alt="Arabic" />
          <span>Arabic</span>
        </MenuItem>
        <MenuItem value="fr">
          <img src={FrenchFlag} alt="French" />
          <span>French</span>
        </MenuItem>
        <MenuItem value="ru">
          <img src={RussianFlag} alt="Russian" />
          <span>Russian</span>
        </MenuItem>
      </Select>
    </>
  );
}
