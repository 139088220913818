import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserProfile from "../../images/userprofile.jpeg";
import { SVG } from "../../icon/svg.file";
import SaveIcon from "@mui/icons-material/Save";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PublicIcon from "@mui/icons-material/Public";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import BusinessIcon from "@mui/icons-material/Business";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import CustomLoader from "../../CustomLoader/CustomLoader";
import alertShow from "../../utility-files/custom-hooks/useAlert";
import {
  alertMessages,
  alertTypes,
} from "../../utility-files/helper-function/HelperFunction";
import { ImagebaseUrl } from "../../utility-files/default-values/DefaultValues";
import * as global from "../../constant/global";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import action from "../../new redux/Action";
import constants from "../../new redux/Types/actionTypes";
import { useTranslation } from "react-i18next";

export default function Profile() {
  const { t } = useTranslation();
  const [editName, setEditName] = useState(false);
  const [pending, setPending] = useState(false);
  const [isValue, setIsValue] = useState("");
  let [changeUserName, setChangeUserName] = useState({
    email: "",
    firstname: "",
    lastname: "",
    country: "",
    city: "",
    pincode: "",
    street: "",
    phone: "",
  });

  const [resetData, setResetData] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const UserData = useSelector((state) => state.UserData);
  const dispatch = useDispatch();
  const fileName = UserData?.data?.doc?.photo;
  const imageUrl = ImagebaseUrl + fileName;
  const [imageBinary, setImageBinary] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);

  const validatePasswords = () => {
    let isValid = true;
    if (resetData.password.length < 8) {
      setPasswordError(
        `${t("profile.components.changePassword.passValidation")}`
      );
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (resetData.password !== resetData.passwordConfirm) {
      setConfirmPasswordError(
        `${t("profile.components.changePassword.confPassValidation")}`
      );
      isValid = false;
    } else {
      setConfirmPasswordError("");
    }

    return isValid;
  };

  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  const handleEditName = () => {
    setEditName(true);
    setIsValue(UserData?.data?.doc?.firstname || "");
  };

  const handleSave = () => {
    setChangeUserName({ ...changeUserName, firstname: isValue });
    setEditName(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    // reader.onloadend = () => {
    //   setImageBinary(reader.result);
    // };
    reader.onload = function () {
      setPreviewImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setImageBinary(file);
    }
  };

  const getUserProfile = async () => {
    let requset, variables;
    requset = getRequestForApi(global.USER_PROFILE, variables, methodType.GET);
    await callHttpRequest(requset)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          const getData = response?.data;
          dispatch(action(constants.UserData, getData));
          setChangeUserName({
            ...changeUserName,
            firstname: getData?.data?.doc?.firstname,
            lastname: getData?.data?.doc?.lastname,
          });
          const userPic = getData?.data?.doc?.photo;
          const profileImage = `${global.BASE_URL}/media/users/${userPic}`;
          setPreviewImage(profileImage);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserProfile();
    }
  }, [isAuthenticated]);

  const handleSubmit = async (e) => {
    setPending(true);
    const formData = new FormData();
    formData.append(
      "firstname",
      changeUserName.firstname || UserData?.data?.doc?.firstname
    );
    formData.append(
      "lastname",
      changeUserName.lastname || UserData?.data?.doc?.lastname
    );
    formData.append("email", UserData?.data?.doc?.email);
    formData.append("country", UserData?.data?.doc?.address?.country);
    formData.append("city", UserData?.data?.doc?.address?.city);
    formData.append("pincode", UserData?.data?.doc?.address?.pincode);
    formData.append("street", UserData?.data?.doc?.address?.street);
    formData.append("phone", UserData?.data?.doc?.phone);

    if (imageBinary) {
      formData.append("photo", new Blob([imageBinary], { type: "image/jpeg" }));
    }
    try {
      const response = await axios.patch(global.UPDATE_USERNAME, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${isAuthenticated}`, // Ensure token is in Bearer format
        },
      });
      toast.success(`${t("profile.components.common.updateSuccess")}`);
      if (isAuthenticated) {
        getUserProfile();
      }
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setPending(false);
    }
  };

  const resetPassword = async () => {
    setPending(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("password", resetData.password);
      formDataToSend.append("passwordConfirm", resetData.passwordConfirm);
      const request = getRequestForApi(
        global.UPDATE_PASSWORD,
        formDataToSend,
        methodType.PATCH
      );
      const response = await callHttpRequest(request);
      if (response.status === 200 || response.status === 201) {
        toast.success(`${t("profile.components.common.passSuccess")}`);
        setResetData({ password: "", passwordConfirm: "" });
      } else {
        alertShow(response.data, alertTypes.ERROR);
      }
    } catch (error) {
      console.error("enter a valid password");
    } finally {
      setPending(false);
    }
  };

  const handleChangeSave = () => {
    if (validatePasswords()) {
      resetPassword();
      setResetData({ password: "", passwordConfirm: "" });
    }
  };

  return (
    <>
      {pending && <CustomLoader />}
      <Card sx={{ borderRadius: "12px", mb: 2 }} elevation={0}>
        <CardContent
          sx={{
            background: "#322E27",
            color: "#fff",
            p: 4,
            "&:last-child": { pb: 4 },
            "@media(max-width:992px)": {
              p: 1.5,
              "&:last-child": { pb: 1.5 },
            },
          }}
        >
          <Box component={"h2"} sx={{ m: 0, fontSize: "16px", pl: 5, mb: 2 }}>
            {t("profile.components.userProfile.userProfile")}
          </Box>
          <Stack direction={{ xs: "column", lg: "row" }} spacing={3}>
            <Box
              sx={{
                width: "186px",
                height: "186px",
                borderRadius: "100%",
                border: "2px solid #fff",

                position: "relative",
                "& img": {
                  borderRadius: "100%",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                },
                "@media(max-width:992px)": {
                  margin: "auto !important",
                },
              }}
            >
              <img alt="" src={previewImage} />
              <IconButton
                sx={{
                  position: "absolute",
                  bottom: "0px",
                  right: "16px",
                  color: "#fff",
                  background:
                    "linear-gradient(180deg, #F7C409 0%, #B44C06 100%)",
                }}
              >
                <CameraAltIcon />
                <input
                  accept="image/*"
                  type="file"
                  style={{
                    width: "100%",
                    position: "absolute",
                    top: "0px",
                    opacity: 0,
                    zIndex: 1,
                    bottom: "0px",
                    cursor: "pointer",
                    textIndent: "-5000px",
                  }}
                  onChange={handleImageChange}
                />
              </IconButton>
            </Box>
            <Box sx={{ flex: "1", pr: { lg: 8 } }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={6}>
                  <Stack
                    direction={"row"}
                    spacing={1}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{ borderRadius: "10px", p: 2, background: "#2d2923" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "56px",
                          height: "56px",
                          borderRadius: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          background: "#494130",
                          justifyContent: "center",
                        }}
                      >
                        <SVG.UserIcon />
                      </Box>
                      <Box
                        sx={{
                          fontSize: "14px",
                          display: "flex",
                          alignItems: "center",
                          flex: 1,
                          position: "relative",
                          gap: "5px",
                          "& input": {
                            width: "100%",
                            outline: "none",
                            background: "#282520",
                            border: "1px solid #A0AEC080",
                            color: "#fff",
                            fontSize: "14px",
                            borderRadius: "5px",
                            padding: "5px",
                            flex: 1,
                          },
                        }}
                      >
                        <span style={{ whiteSpace: "nowrap" }}>
                          {" "}
                          {t("profile.components.userProfile.name")} -
                        </span>
                        {!editName ? (
                          UserData?.data?.doc?.fullname || "N/A"
                        ) : (
                          <>
                            <input
                              type="text"
                              onChange={(e) =>
                                setChangeUserName({
                                  ...changeUserName,
                                  firstname: e.target.value,
                                })
                              }
                              value={changeUserName.firstname}
                            />
                            <input
                              type="text"
                              onChange={(e) =>
                                setChangeUserName({
                                  ...changeUserName,
                                  lastname: e.target.value,
                                })
                              }
                              value={changeUserName.lastname}
                            />
                          </>
                        )}
                      </Box>
                    </Box>
                    {!editName && (
                      <IconButton
                        sx={{ ml: "auto" }}
                        onClick={handleEditName} // Apply onClick to IconButton
                      >
                        {
                          <SVG.EditIcon />
                          // : (
                          //   <SaveIcon
                          //     sx={{ fontSize: "18px", color: "#fff" }}
                          //     onClick={handleSave}
                          //   />

                          // )
                        }
                      </IconButton>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{ borderRadius: "10px", p: 2, background: "#2d2923" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "56px",
                          height: "56px",
                          borderRadius: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          background: "#494130",
                          justifyContent: "center",
                        }}
                      >
                        <SVG.EmailIcon />
                      </Box>
                      <Box sx={{ fontSize: "14px" }}>
                        {t("profile.components.userProfile.email")} -{" "}
                        {UserData?.data?.doc?.email}
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{ borderRadius: "10px", p: 2, background: "#2d2923" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "56px",
                          height: "56px",
                          borderRadius: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          background: "#494130",
                          justifyContent: "center",
                        }}
                      >
                        <SVG.PhoneIcon />
                      </Box>
                      <Box sx={{ fontSize: "14px" }}>
                        {t("profile.components.userProfile.phoneNumber")}- +
                        {UserData?.data?.doc?.phone}
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{ borderRadius: "10px", p: 2, background: "#2d2923" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "56px",
                          height: "56px",
                          borderRadius: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          background: "#494130",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <PublicIcon />
                      </Box>
                      <Box sx={{ fontSize: "14px" }}>
                        {t("profile.components.userProfile.country")}-{" "}
                        {UserData?.data?.doc?.address?.country}
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{ borderRadius: "10px", p: 2, background: "#2d2923" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "56px",
                          height: "56px",
                          borderRadius: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          background: "#494130",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <LocationCityIcon />
                      </Box>
                      <Box sx={{ fontSize: "14px" }}>
                        {t("profile.components.userProfile.city")}-{" "}
                        {UserData?.data?.doc?.address?.city}
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{ borderRadius: "10px", p: 2, background: "#2d2923" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "56px",
                          height: "56px",
                          borderRadius: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          background: "#494130",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <BusinessIcon />
                      </Box>
                      <Box sx={{ fontSize: "14px" }}>
                        {t("profile.components.userProfile.address")}-{" "}
                        {UserData?.data?.doc?.address?.street}
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    alignItems="center"
                    justifyContent={"space-between"}
                    sx={{ borderRadius: "10px", p: 2, background: "#2d2923" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "56px",
                          height: "56px",
                          borderRadius: "100%",
                          display: "inline-flex",
                          alignItems: "center",
                          background: "#494130",
                          justifyContent: "center",
                          color: "#fff",
                        }}
                      >
                        <LocationOnIcon />
                      </Box>
                      <Box sx={{ fontSize: "14px" }}>
                        {t("profile.components.userProfile.zipCode")}-{" "}
                        {UserData?.data?.doc?.address?.pincode}
                      </Box>
                    </Box>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: { xs: "center", lg: "end" },
                      mt: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      tabIndex={-1}
                      sx={{
                        background:
                          "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
                        color: "#fff !important",
                        width: "232px",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        handleSubmit();
                        setEditName(false);
                      }}
                    >
                      {t("profile.components.userProfile.save")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </CardContent>
      </Card>
      <Card sx={{ borderRadius: "12px", mb: 2 }} elevation={0}>
        <CardContent
          sx={{
            background: "#322E27",
            color: "#fff",
            p: 4,
            "&:last-child": { pb: 4 },
          }}
        >
          <Box component={"h2"} sx={{ mt: 0, mb: 2, fontSize: "16px" }}>
            {t("profile.components.changePassword.changePassword")}
          </Box>
          <Box
            sx={{
              pr: { lg: localStorage.getItem("i18nextLng") === "ar" ? 0 : 8 },
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Box
                  sx={{
                    "& label": {
                      mb: 1,
                      fontSize: "14px",
                      display: "inline-block",
                    },
                  }}
                >
                  <label>
                    {" "}
                    {t("profile.components.changePassword.newPassword")}
                  </label>
                  <TextField
                    size="small"
                    placeholder={t(
                      "profile.components.changePassword.newPassword"
                    )}
                    type="password"
                    value={resetData.password}
                    onChange={(e) =>
                      setResetData({ ...resetData, password: e.target.value })
                    }
                    fullWidth
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#2d2923 !important",
                        borderRadius: "10px",
                        border: "1px solid #687588",
                        color: "#fff",
                        input: {
                          "&::placeholder": {
                            color: "#fff",
                            opacity: 1,
                          },
                        },
                      },
                    }}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Box
                  sx={{
                    "& label": {
                      mb: 1,
                      fontSize: "14px",
                      display: "inline-block",
                    },
                  }}
                >
                  <label>
                    {" "}
                    {t("profile.components.changePassword.confirmPassword")}
                  </label>
                  <TextField
                    size="small"
                    placeholder={t(
                      "profile.components.changePassword.confirmPassword"
                    )}
                    type="password"
                    value={resetData.passwordConfirm}
                    onChange={(e) =>
                      setResetData({
                        ...resetData,
                        passwordConfirm: e.target.value,
                      })
                    }
                    fullWidth
                    sx={{
                      "& .MuiInputBase-root": {
                        background: "#2d2923 !important",
                        borderRadius: "10px",
                        border: "1px solid #687588",
                        color: "#fff",
                        input: {
                          "&::placeholder": {
                            color: "#fff",
                            opacity: 1,
                          },
                        },
                      },
                    }}
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "center", lg: "end" },
                    mt: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    tabIndex={-1}
                    disabled={
                      !resetData.password ||
                      !resetData.passwordConfirm ||
                      pending
                    }
                    className={` ${
                      !resetData.passwordConfirm || !resetData.password
                        ? "disable-button"
                        : ""
                    }`}
                    sx={{
                      background:
                        "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
                      color: "#fff !important",
                      width: "232px",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      handleChangeSave();
                      setResetData({});
                    }}
                  >
                    {pending
                      ? t("profile.components.changePassword.submitting")
                      : t("profile.components.changePassword.save")}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
