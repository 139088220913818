import { Box, Card, IconButton, Pagination, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { SVG } from "../../icon/svg.file";
import { useTranslation } from "react-i18next";
import { getDateWithTimeFormat } from "../../utility-files/date-util/DateHandling";
import { isIntegerValue } from "../../utility-files/data-util/DataHandler";
import moment from "moment";

export default function AdMobileTable(props) {
  const rowData = props.rowData || []
  const { t } = useTranslation();
  // console.log("<MobileTable> rowData", rowData);
  return (
    <>
      {Array.isArray(rowData) &&
        rowData?.map((index) => (
          <Card
            sx={{
              borderRadius: "8px",
              mb: 3,
              border: "1px solid #ccc",
              "& table": {
                "& th": {
                  background: "#493932",
                  padding: "10px",
                  borderBottom: "1px solid #ccc",
                  color: "#CBD5E0",
                  width: "50%",
                },
                "& td": {
                  padding: "10px",
                  borderBottom: "1px solid #ccc",
                  background: "#160B01",
                  color: "#fff",
                },
              },
            }}
          >
            <table width="100%" cellPadding={"0"} cellSpacing={"0"} border={0}>
              <tr>
                <th>{t("Ad_Add.Name")}</th>
                <td>
                  <Link
                    to={`/advertisement/detail/${index?._id}?id=1`}
                    style={{ color: "#fff", textDecoration: "none" }}
                  >
                    {index.title}
                  </Link>
                </td>
              </tr>
              <tr>
                <th>{t("Ad_Details.adType")}</th>
                <td>{index.type || t("Ad_index.commonTable.rows.noData")}</td>
              </tr>
              <tr>
                <th>{t("Ad_index.commonTable.columns.cost_forecast")}</th>
                <td>
                  {index.campaign_cost || t("Ad_index.commonTable.rows.noData")}
                </td>
              </tr>
              <tr>
                <th>{t("Ad_index.commonTable.columns.startDate")}</th>
                <td>{getDateWithTimeFormat(index.start_at)}</td>
              </tr>

              <tr>
                <th>{t("Ad_index.commonTable.columns.endDate")}</th>
                <td>{getDateWithTimeFormat(index.closing_at)}</td>
              </tr>
              <tr>
                <th>{t("Ad_index.commonTable.columns.ad_exposures")}</th>
                <td>{isIntegerValue(index.total_adExposure)}</td>
              </tr>
              <tr>
                <th>{t("Ad_index.commonTable.columns.actions")}</th>
                <td>
                  {index?.status === "pending" ? (
                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                      <IconButton
                        disableRipple
                        sx={{
                          background: "#322E27",
                          borderRadius: "10px",
                          width: "30px",
                          height: "30px",
                        }}
                        onClick={() => props.handleModal(index._id)}
                      >
                        <SVG.Delete />
                      </IconButton>
                      <IconButton
                        LinkComponent={Link}
                        to={`/advertisement/edit/${index?._id}?id=2`}
                        disableRipple
                        sx={{
                          background: "#322E27",
                          borderRadius: "10px",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <SVG.Pencil />
                      </IconButton>
                      <IconButton
                        LinkComponent={Link}
                        to={`/advertisement/detail/${index?._id}?id=2`}
                        disableRipple
                        sx={{
                          background: "#322E27",
                          borderRadius: "10px",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <SVG.EyeIcon />
                      </IconButton>
                      {/* Delete confirmation modal */}
                    </Stack>
                  ) : (
                    <IconButton
                      component={Link}
                      to={`/advertisement/detail/${index?._id}?id=1`}
                      disableRipple
                      sx={{
                        background: "#322E27",
                        borderRadius: "10px",
                        width: "30px",
                        height: "30px",
                      }}
                    >
                      <SVG.EyeIcon />
                    </IconButton>
                  )}
                </td>
              </tr>
            </table>
          </Card>
        ))}
      {(props?.currentPage || props?.totalPages) && (
        <Pagination
          page={props?.currentPage}
          count={props?.totalPages}
          variant="outlined"
          shape="rounded"
          sx={{
            m: ".5rem 0",
            "& *": { color: "#fff !important" },
            "& ul": {
              justifyContent: "end",
              "& button": { border: "1px solid rgb(255 255 255 / 0.3)" },
              "& .Mui-selected": { background: "rgba(255,255,255,.3)" },
            },
          }}
          onChange={(e, val) => props?.handlePageChange(val)}
        />
      )}
    </>
  );
}
