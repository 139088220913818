import React, { useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import action from "../../new redux/Action";
import constants from "../../new redux/Types/actionTypes";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import * as global from "../../constant/global";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import { useMediaQuery } from "@mui/material";
import { FidgetSpinner } from "react-loader-spinner";

const center = {
  lat: 32.997199319414534,
  lng: 42.12479037645339,
};

const GoogleMapComponent = () => {
  const matches = useMediaQuery("(max-width:768px)");
  const mapContainerStyle = {
    width: "100%",
    height: matches ? "calc(100vh - 242px)" : "92vh",
  };
  const cameraBillBoard = useSelector((state) => state.cameraBillBoard) || [];
  const UserData = useSelector((state) => state.UserData);
  const dispatch = useDispatch();
  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  const spots = cameraBillBoard[0] || [];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyA-SGeGL2gFSI3MsiwMqshTNmmQleaOA7A", // Replace with your API key
  });

  useEffect(() => {
    const getBoradCamera = async () => {
      let request = getRequestForApi(
        global.GET_BILLBOARD_CAMERA +
          `${UserData?.data?.doc?.address?.country}`,
        methodType.GET
      );
      await callHttpRequest(request)
        .then((response) => {
          if (response?.status === 200 || response?.status === 201) {
            if (response?.data?.data?.docs) {
              let dataList = response?.data?.data?.docs;
              dispatch(action(constants.cameraBillBoard, [dataList]));
            }
          }
        })
        .catch((err) => {});
    };

    if (isAuthenticated) {
      getBoradCamera();
    }
  }, [isAuthenticated, UserData, dispatch]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading Maps</div>;

  return (
    <>
      {!(spots?.length > 0) ? (
        <FidgetSpinner />
      ) : (
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={3}
          center={center}
        >
          {Array.isArray(spots) &&
            spots.map((marker, index) => {
              const coordinates = marker?.coordinates?.lat
                ? marker?.coordinates
                : marker?.location?.coordinates;
              console.log("<GoogleMapComponent> All maps", coordinates);
              if (coordinates) {
                // const [lat, lng] = coordinates;
                const lat = Array.isArray(coordinates)
                  ? coordinates[0]
                  : coordinates.lat;
                const lng = Array.isArray(coordinates)
                  ? coordinates[1]
                  : coordinates.lang;
                return <Marker key={index} position={{ lat, lng }} />;
              }
              return null; // Skip invalid markers
            })}
        </GoogleMap>
      )}
    </>
  );
};

export default GoogleMapComponent;
