export const ar = {
  Ad_Add: {
    CustomLoader: "جاري التحميل...",
    Uploader: "المحمل",
    VideoTitleHere: "عنوان الفيديو هنا",
    AddType: "نوع الإعلان",
    Local: "محلي",
    Global: "عالمي",
    Partner: "شريك",
    AdvertiserDetails: "تفاصيل المعلن",
    SelectAdvertiser: "اختر المعلن",
    Name: "الاسم",
    PhoneNumber: "رقم الهاتف",
    PhoneNumberPlaceholder: "اكتب نوع رقم الهاتف هنا",
    CampaignDetails: "تفاصيل الحملة",
    CampaignStartDate: "تاريخ بدء الحملة",
    CampaignEndDate: "تاريخ نهاية الحملة",
    SelectABillboard: "اختر لوحة إعلانية",
    AutomaticCalculation: "الحساب التلقائي",
    SalesAgentProposal: "اقتراح وكيل المبيعات",
    Upload: "تحميل",
    UploadFiles: "تحميل ملفات",
    Save: "حفظ",
    YouShouldKnow: "يجب أن تعرف:",
    OnlyVideosShouldBeUploaded: "يجب تحميل الفيديوهات فقط",
    VideoResolutionRequirement:
      "يجب أن يكون الفيديو بدقة 1:2 بكسل أو فيديو تيك توك",
    VideoDurationRequirement: "يجب ألا تزيد مدة الفيديو عن 30 ثانية",
    CompanyPolicyRequirement:
      "لن يتم الموافقة على الفيديو الذي يتعارض مع سياسة الشركة",
    adUploadSuccess: "تم رفع الإعلان بنجاح",
    adUploadFail: "فشل رفع الإعلان. يرجى المحاولة مرة أخرى لاحقًا.",
    adUploadFail2: "خطأ في رفع الإعلان. يرجى المحاولة مرة أخرى لاحقًا.",
    partners: "الشركاء",
    endDateError: "يجب أن يكون تاريخ انتهاء الحملة بعد تاريخ البدء",
    startDateValidation: "يرجى تحديد تاريخ البدء",
    endDateValidation: "يرجى تحديد تاريخ الانتهاء",
  },
  Ad_Details: {
    advertisementDetail: "تفاصيل الإعلان",
    advertiserDetails: "تفاصيل المعلن",
    noDataAvailable: "لا توجد بيانات متاحة",
    phone: "هاتف",
    email: "البريد الإلكتروني",
    campaignDetails: "تفاصيل الحملة",
    campaignPeriod: "مدة الحملة",
    from: "من",
    to: "إلى",
    billboard: "لوحة إعلانية",
    createdAt: "تاريخ الإنشاء",
    campaignCost: "تكلفة الحملة",
    automaticCalculation: "الحساب التلقائي",
    agentProposal: "اقتراح الوكيل",
    finalCost: "التكلفة النهائية",
    videoAdPreview: "معاينة الإعلان بالفيديو",
    date: "التاريخ",
    adExposure: "التعرض للإعلان",
    totalExposure: "إجمالي التعرض",
    dataNotAvailable: "البيانات غير متوفرة",
    adType: "النوع",
    adStatus: "الحالة",
  },
  Ad_Edit: {
    advertisement_update: {
      title: "تحديث الإعلان",
      video_title_label: "عنوان الفيديو هنا",
      add_type_label: "نوع الإعلان",
      advertiser_details_title: "تفاصيل المعلن",
      select_advertiser: "اختر المعلن",
      advertiser_name_label: "الاسم",
      phone_number_label: "رقم الهاتف",
      email_label: "البريد الإلكتروني",
      campaign_details_title: "تفاصيل الحملة",
      campaign_start_date_label: "تاريخ بدء الحملة",
      campaign_end_date_label: "تاريخ نهاية الحملة",
      select_billboard_label: "اختر لوحة إعلانية *",
      automatic_calculation_label: "الحساب التلقائي",
      sales_agent_proposal_label: "اقتراح وكيل المبيعات",
      upload_title: "تحميل *",
      upload_files_text: "تحميل ملفات",
      resubmit_button_text: "إعادة الإرسال",
      you_should_know_title: "يجب أن تعرف:",
      you_should_know_text: [
        "يجب تحميل الفيديوهات فقط",
        "يجب أن يكون الفيديو بدقة 1:2 بكسل أو فيديو تيك توك",
        "يجب ألا تزيد مدة الفيديو عن 30 ثانية",
        "لن يتم الموافقة على الفيديو الذي يتعارض مع سياسة الشركة",
      ],
    },
  },
  Ad_index: {
    advertisements: {
      title: "الإعلانات",
      tabs: {
        all: "الكل",
        pending: "قيد الانتظار",
        approved: "الموافقة",
        rejected: "المرفوض",
      },
      billboardCount: {
        totalBillboards: "إجمالي اللوحات الإعلانية",
        activeBillboards: "اللوحات الإعلانية النشطة",
        inactiveBillboards: "اللوحات الإعلانية غير النشطة",
        billboardsInMaintenance: "اللوحات الإعلانية قيد الصيانة",
      },
      addAdvertisement: {
        add: "إضافة",
        advertisements: "الإعلانات",
      },
      billboard: {
        title: "لوحة إعلانية",
        video: {
          src: "https://www.youtube.com/embed/0njtNjgy-cA?si=DyHFmHets3EahWqx",
          title: "مشغل فيديو يوتيوب",
        },
      },
    },
    commonTable: {
      columns: {
        id: "المعرف",
        title: "العنوان",
        type: "النوع",
        startDate: "تاريخ البدء",
        endDate: "تاريخ الانتهاء",
        status: "الحالة",
        actions: "الإجراءات",
        cost_forecast: "توقعات التكلفة",
        ad_exposures: "تعرضات الإعلانات",
      },
      rows: {
        noData: "لا توجد بيانات متاحة",
      },
    },
  },
  Ad_main: {
    advertisements: {
      title: "الإعلانات",
      tabs: {
        all: "الكل",
        pending: "قيد الانتظار",
        active: "نشط",
        approved: "الموافقة",
        unapproved: "غير الموافقة",
        expired: "منتهي الصلاحية",
      },
      addButton: {
        add: "إضافة",
      },
    },
    commonTable: {
      columns: {
        id: "المعرف",
        title: "العنوان",
        type: "النوع",
        startDate: "تاريخ البدء",
        endDate: "تاريخ الانتهاء",
        status: "الحالة",
        actions: "الإجراءات",
      },
      rows: {
        noData: "لا توجد بيانات متاحة",
      },
    },
    deleteConfirmationModal: {
      open: "فتح",
      close: "إغلاق",
      itemName: "اسم العنصر",
      refetch: "إعادة جلب",
    },
  },
  Ad_tabs: {
    advertisements: "الإعلانات",
    add: "إضافة",
    all: "الكل",
    pending: "قيد الانتظار",
    active: "نشط",
    approved: "الموافقة",
    unapproved: "غير الموافقة",
    expired: "منتهي الصلاحية",
  },
  auth_email: {
    labels: {
      email: "البريد الإلكتروني",
      password: "كلمة المرور",
      rememberMe: "تذكرني",
      submitButton: "إرسال",
      submittingButton: "جاري الإرسال...",
      showPassword: "إظهار كلمة المرور",
      hidePassword: "إخفاء كلمة المرور",
      forgotPassword: "نسيت كلمة المرور؟",
    },
    placeholders: {
      email: "أدخل بريدك الإلكتروني",
      password: "أدخل كلمة المرور",
      emailSuccess: "تم إرسال رمز التحقق إلى بريدك الإلكتروني بنجاح.",
      emailFail: "بيانات الاعتماد غير صالحة. يرجى المحاولة مرة أخرى.",
    },
    errors: {
      requiredField: "هذا الحقل مطلوب",
      invalidEmail: "تنسيق عنوان البريد الإلكتروني غير صالح",
      invalidPass: "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل",
      invalidPhone: "فشل إرسال رمز التحقق. يرجى المحاولة مرة أخرى.",
      noUser: "لم يتم العثور على المستخدم.",
    },
  },
  Auth_phone: {
    Email: "البريد الإلكتروني",
    Password: "كلمة المرور",
    "Show Password": "إظهار كلمة المرور",
    "Hide Password": "إخفاء كلمة المرور",
    "Remember Me": "تذكرني",
    "Submitting...": "جاري الإرسال...",
    Submit: "إرسال",
    "Forgot Password?": "نسيت كلمة المرور؟",
    Phone: "الهاتف",
    "Send OTP": "إرسال رمز OTP",
    "Sending...": "جاري الإرسال...",
  },
  Auth_forgotPassword: {
    Advertisements: "الإعلانات",
    Add: "إضافة",
    "Forgot Password?": "نسيت كلمة المرور؟",
    "Don’t worry! It happens. Please enter the email address associated with your account":
      "لا تقلق! يحدث ذلك. الرجاء إدخال عنوان البريد الإلكتروني المرتبط بحسابك",
    Email: "البريد الإلكتروني",
    Submit: "إرسال",
    "Send OTP": "إرسال رمز OTP",
    Phone: "الهاتف",
    "Remember Me": "تذكرني",
    "Show Password": "إظهار كلمة المرور",
    "Hide Password": "إخفاء كلمة المرور",
    success: "تم إرسال رابط إعادة تعيين كلمة المرور إلى بريدك الإلكتروني",
  },
  Auth_index: {
    forgot_password_title: "نسيت كلمة المرور؟",
    forgot_password_subtitle:
      "لا تقلق! يحدث ذلك. الرجاء إدخال عنوان البريد الإلكتروني المرتبط بحسابك",
    forgot_password_email_label: "البريد الإلكتروني",
    forgot_password_submit_button: "إرسال",
    login_with_email_title: "تسجيل الدخول باستخدام البريد الإلكتروني",
    login_with_phone_title: "تسجيل الدخول باستخدام الهاتف",
    email_login_button: "تسجيل الدخول بالبريد الإلكتروني",
    phone_login_button: "تسجيل الدخول بالهاتف",
    email_placeholder: "أدخل بريدك الإلكتروني",
    send_otp_button: "إرسال رمز OTP",
    submit_button: "إرسال",
    show_password_button: "إظهار كلمة المرور",
    hide_password_button: "إخفاء كلمة المرور",
    remember_me_label: "تذكرني",
    phone_label: "الهاتف",
    form_error_required: "هذا الحقل مطلوب",
    form_error_email_invalid: "الرجاء إدخال عنوان بريد إلكتروني صالح",
    form_error_password_length: "يجب أن تتكون كلمة المرور من 6 أحرف على الأقل",
  },
  Auth_otp: {
    forgot_password: {
      title: "نسيت كلمة المرور؟",
      instruction:
        "لا تقلق! يحدث ذلك. الرجاء إدخال عنوان البريد الإلكتروني المرتبط بحسابك",
      email_placeholder: "أدخل بريدك الإلكتروني",
      submit_button: "إرسال",
    },
    login: {
      email: "تسجيل الدخول باستخدام البريد الإلكتروني",
      phone: "تسجيل الدخول باستخدام الهاتف",
      email_login_button: "تسجيل الدخول بالبريد الإلكتروني",
      phone_login_button: "تسجيل الدخول بالهاتف",
    },
    otp_page: {
      title: "رمز التحقق",
      otp_label: "رمز رمز التحقق المكون من 4 أرقام",
      otp_placeholder: "النص",
      sign_in_button: "تسجيل الدخول",
      otpSuccess: "تم التحقق من رمز التحقق بنجاح.",
      otpFail: "فشل التحقق من رمز التحقق",
      otpValidation: "الرجاء إدخال رمز التحقق الصحيح",
      otpResend: "تم إرسال رمز التحقق بنجاح",
      resendDisable: "لم تحصل على الكود؟ أعد إرساله",
      sec: "ثانية",
      resendButton: "إعادة إرسال",
      verifyButton: "جاري التحقق...",
    },
  },
  Auth_resetPassword: {
    ResetPassword: {
      title: "إعادة تعيين كلمة المرور",
      newPassword: "كلمة المرور الجديدة",
      confirmPassword: "تأكيد كلمة المرور",
      submitButton: "إرسال",
      validationMessages: {
        passwordLength: "مطلوب 8 أحرف أبجدية رقمية على الأقل",
        passwordMismatch: "كلمات المرور غير متطابقة",
      },
    },
  },
  Billboard_main: {
    Billboards: {
      title: "اللوحات الإعلانية",
      tabs: {
        all: "الكل",
        someTab1: "علامة تبويب 1",
        someTab2: "علامة تبويب 2",
        offline: "غير متصل",
        online: "متصل",
        billboard_address: "عنوان اللوحة الإعلانية",
        owner: "المالك",
        installation: "التثبيت",
        ads_amount: "عدد الإعلانات",
        ads_exposures: "تعرض الإعلانات",
        offline_started: "بدأ عدم الاتصال",
        linked_tickets: "التذاكر المرتبطة",
      },
      buttons: {
        arrowRight: "سهم لليمين",
      },
    },
  },
  Billboard_Edit: {
    BillboardTraffic: {
      title: "حركة اللوحات الإعلانية",
      placeholder: {
        billboardName: "اسم اللوحة الإعلانية",
        contactName: "اسم الاتصال",
        address: "العنوان",
        phoneNumber: "رقم الهاتف",
        email: "البريد الإلكتروني",
        remark: "اكتب هنا",
        uploadFiles: "تحميل ملفات",
      },
      labels: {
        billboardLocation: "موقع اللوحة الإعلانية",
        contactDetails: "تفاصيل الاتصال",
        remarks: "ملاحظات",
        uploadGalleryImages: "تحميل صور المعرض",
        advertisingContent: "محتوى الإعلان",
        amountAdsDisplayed: "عدد الإعلانات المعروضة",
        spotsPerAdPerDay: "النقاط لكل إعلان في اليوم",
        installationDate: "تاريخ التركيب",
        nextPayment: "الدفعة التالية",
        monthlyPayment: "الدفع الشهري",
      },
      messages: {
        imageUploadSuccess: "تم تحميل الصورة بنجاح",
        remarkSuccess: "تم إنشاء الملاحظة بنجاح!..",
        error: "خطأ!..",
      },
      buttons: {
        save: "حفظ",
      },
    },
  },
  Billboard_index: {
    typography: {
      billboards: "اللوحات الإعلانية",
      installation_date: "تاريخ التركيب",
      next_payment: "الدفعة التالية",
      edit_billboard: "تعديل اللوحة الإعلانية",
      billboard_ads: "إعلانات اللوحات الإعلانية",
      views_and_impressions: "المشاهدات والانطباعات",
      offline_heat_map: "خريطة الحرارة الغير متصلة",
      billboard_location: "موقع اللوحة الإعلانية",
      interaction: "التفاعل",
      qr_code: "رمز الاستجابة السريعة",
      remarks: "ملاحظات",
      payments: "الدفعات",
      spot_ad_daily: "إعلان يومي",
      gallery: "معرض",
      no_data_available: "لا توجد بيانات متاحة",
      click_here_to_open_link: "انقر هنا لفتح الرابط",
      download: "تحميل",
      rushHour: "ساعة الذروة اليوم",
      week: "هذا الأسبوع",
      month: "هذا الشهر",
      year: "هذا العام",
      activeAd: "الإعلانات النشطة",
    },
    select: {
      no_address_available: "لا يوجد عنوان متاح",
    },
    date_format: {
      date: "التاريخ",
    },
    button: {
      download: "تحميل",
      sizes: ["300 *300", "400*400", "500*500", "1000*1000", "2000*2000"],
    },
    modal: {
      social: {
        facebook: "فيسبوك",
        whatsapp: "واتساب",
        linkedin: "لينكد إن",
        email: "البريد الإلكتروني",
        tel: "الهاتف",
      },
    },
    interaction: {
      qr_code: "رمز الاستجابة السريعة",
    },
    formats: {
      amount: "المبلغ",
    },
  },
  Billboard_mapView: {
    errors: {
      coordinatesUnavailable: "الإحداثيات غير متوفرة",
      mapContainerUnavailable: "حاوية الخريطة غير متوفرة",
    },
    messages: {
      noLocationAvailable: "لا يوجد موقع متاح",
    },
  },
  Dashboard: {
    components: {
      customLoader: "جاري التحميل...",
      billboardPlacement: "توضع اللوحات الإعلانية",
      dashboard: "لوحة التحكم",
      now: "الآن",
      lastUpdate: "آخر تحديث",
      dailyStatistics: "الإحصائيات اليومية",
      rushHour: "ساعة الذروة",
      impressions: "الانطباعات",
      map: "الخريطة",
      goToMap: "اذهب إلى الخريطة",
      impressionsTitle: "الانطباعات",
      billboardStatus: "حالة اللوحة الإعلانية",
      clearAll: "مسح الكل",
      respond: "يرد",
      noMessage: "لا توجد رسائل جديدة",
      notification: "إشعارات",
      read: "يقرأ",
      noNotification: "لا يوجد إشعار جديد",
      online: "متصل",
      offline: "غير متصل على الانترنت",
      profile: "حساب تعريفي",
      logout: "تسجيل خروج",
    },
  },
  modal: {
    components: {
      dialog: {
        deleteConfirmation: "تأكيد الحذف",
        close: "إغلاق",
        areYouSure: "هل أنت متأكد أنك تريد الحذف؟",
        cancel: "إلغاء",
        delete: "حذف",
        deleteSuccess: "تم حذف الإعلان بنجاح.",
        deleteFail: "فشل في حذف الإعلان. يرجى المحاولة مرة أخرى.",
      },
    },
  },
  profile: {
    components: {
      userProfile: {
        userProfile: "ملف المستخدم",
        name: "الاسم الكامل",
        email: "البريد الإلكتروني",
        phoneNumber: "رقم الهاتف",
        country: "البلد",
        city: "المدينة",
        address: "العنوان",
        zipCode: "الرمز البريدي",
        save: "حفظ",
        edit: "تعديل",
      },
      changePassword: {
        changePassword: "تغيير كلمة المرور",
        newPassword: "كلمة المرور الجديدة",
        confirmPassword: "تأكيد كلمة المرور",
        submitting: "جاري الإرسال...",
        save: "حفظ",
        passValidation: "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل",
        confPassValidation: "كلمة المرور غير مطابقة",
      },
      common: {
        cancel: "إلغاء",
        delete: "حذف",
        areYouSure: "هل أنت متأكد أنك تريد الحذف؟",
        updateSuccess: "تم تحديث تفاصيل المستخدم بنجاح",
        passSuccess: "تم تغيير كلمة المرور بنجاح",
      },
    },
  },
  Ticket_add: {
    add_ticket: {
      title: "إضافة تذكرة",
      labels: {
        ticket_name: "اسم التذكرة",
        ticket_description: "وصف التذكرة",
        billboard_name: "اسم اللوحة الإعلانية",
        closing_date: "تاريخ الإغلاق",
        ticket_type: "نوع التذكرة",
        ticket_subtype: "النوع الفرعي للتذكرة",
        ticket_assignee: "المكلف بالتذكرة",
        ticket_followers: "المتابعون للتذكرة",
      },
      placeholders: {
        ticket_name: "اسم التذكرة",
        ticket_description: "الوصف",
        billboard_name: "اختر اللوحة الإعلانية",
        ticket_type: "اختر نوع التذكرة",
        ticket_subtype: "اختر النوع الفرعي للتذكرة",
      },
      buttons: {
        approve: "الموافقة",
        save: "حفظ",
        cancel: "إلغاء",
      },
      errors: {
        ticket_name: "اسم التذكرة مطلوب",
        description: "الوصف مطلوب",
        billboard_name: "اسم اللوحة الإعلانية مطلوب",
        closing_date: "تاريخ الإغلاق مطلوب",
        ticket_type: "نوع التذكرة مطلوب",
        ticket_subtype: "النوع الفرعي للتذكرة مطلوب",
        ticket_assignee: "المكلف بالتذكرة مطلوب",
        ticket_followers: "المتابعون للتذكرة مطلوبون",
        duplicateName: "تم أخذ اسم التذكرة بالفعل",
        ticketSuccess: "تم إنشاء التذكرة بنجاح",
      },
      admin_roles: ["المدير", "المدير الأعلى"],
    },
  },
  Ticket_allTicket: {
    search: "بحث",
    billboard_sort: "ترتيب حسب اللوحة الإعلانية",
    ticket_id: "رقم التذكرة:",
    created_at: "{date}",
    ticket_name: "{name}",
    message_prefix: "{name}: {message}",
    tickets_title: "التذاكر",
    messages_tab: "الرسائل",
    details_tab: "التفاصيل",
  },
  Ticket_details: {
    "Add Ticket": "إضافة تذكرة",
    "Ticket Name": "اسم التذكرة",
    "Ticket Description": "وصف التذكرة",
    "Billboard Name": "اسم اللوحة الإعلانية",
    "Closing Date": "تاريخ الإغلاق",
    "Ticket Type": "نوع التذكرة",
    "Ticket Subtype": "النوع الفرعي للتذكرة",
    "Ticket Assignee": "المكلف بالتذكرة",
    "Ticket Followers": "المتابعون للتذكرة",
    Search: "بحث",
    Messages: "الرسائل",
    Details: "التفاصيل",
    Less: "أقل",
    More: "المزيد",
    "Ticket Assignee:": "المكلف بالتذكرة:",
    creationDate: "تاريخ الإنشاء:",
  },
  Ticket_index: {
    tickets: {
      title: "التذاكر",
      tabs: {
        all: "جميع التذاكر",
        approve: "التذاكر الموافق عليها",
        unapprove: "التذاكر غير الموافق عليها",
        close: "التذاكر المغلقة",
        disapprove: "التذاكر المرفوضة",
        archieve: "التذاكر المؤرشفة",
        inProgress: "تذاكر قيد التنفيذ",
        completed: "التذاكر المكتملة",
      },
      addButton: "إضافة",
      noTicketsMessage: "لا توجد تذاكر متاحة.",
      ticketCounts: {
        approve: "الموافقة على التذكرة",
        unapprove: "عدم الموافقة على التذكرة",
        close: "إغلاق التذكرة",
        disapprove: "رفض التذكرة",
        archieve: "أرشفة التذكرة",
      },
      drawer: {
        title: "إضافة تذكرة",
      },
    },
    common: {
      loaders: {
        custom: "جاري التحميل...",
        fidgetSpinner: "جاري التحميل...",
      },
      buttons: {
        approve: "الموافقة",
        disapprove: "الرفض",
        delete: "حذف",
      },
      labels: {
        search: "بحث",
        billboardSort: "ترتيب حسب اللوحة الإعلانية",
      },
      messages: {
        more: "المزيد",
        less: "أقل",
      },
      placeholders: {
        search: "بحث...",
      },
      statuses: {
        approve: "موافق",
        close: "مغلق",
      },
      ticketDetails: {
        assignee: "المكلف بالتذكرة",
        followers: "المتابعون للتذكرة",
      },
    },
  },
  Ticket_message: {
    header: {
      tickets: "التذاكر",
    },
    tabs: {
      approve: "الموافقة",
      unapprove: "عدم الموافقة",
      close: "الإغلاق",
      disapprove: "الرفض",
      archieve: "الأرشفة",
    },
    buttons: {
      add: "إضافة",
    },
    messages: {
      noTickets: "لا توجد تذاكر متاحة.",
      uploadingFile: "جاري تحميل الملف...",
      typeHere: "اكتب هنا...",
      cannotChatArchived: "لا يمكنك الدردشة على تذكرة مؤرشفة.",
      cannotChatClosed: "لا يمكنك الدردشة على تذكرة مغلقة.",
      cannotChatDisapproved: "لا يمكنك الدردشة على تذكرة مرفوضة.",
      cannotChatUnapproved: "لا يمكنك الدردشة على تذكرة غير موافق عليها.",
      uploadFail: "غير قادر على تحميل الملف.",
      sizeExceed: "حجم الملف يتجاوز 15 ميجا بايت",
    },
  },
};
