import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { SVG } from "../icon/svg.file";
import UserProfile from "../images/message.png";
import { Link } from "react-router-dom";
import { FidgetSpinner } from "react-loader-spinner";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function Notification(props) {
  const { t } = useTranslation();
  const Notifications = props?.Notifications?.data?.notifications;
  // console.log("<Message> Notifications", Notifications);

  const NavigationLinks = {
    AD: "/advertisement/detail/",
  };

  return (
    <>
      <Box>
        <Typography
          variant="h2"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "16px",
            borderBottom: "1px solid #E4E6E8",
            px: 3,
            py: 1,
            fontWeight: 700,
            direction:
              localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr",
          }}
        >
          <span>{t("Dashboard.components.notification")}</span>
          <Box
            component={"span"}
            sx={{ cursor: "pointer" }}
            onClick={props.handleClose}
          >
            <SVG.CloseIcon />
          </Box>
        </Typography>
        {props?.loader ? (
          <FidgetSpinner />
        ) : Notifications?.length > 0 ? (
          <Box sx={{ overflow: "auto", height: "445px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                mb: 0,
                p: 2,
                py: 1,
                gap: "10px",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "center",
                  fontWeight: "600",
                  "& svg": {
                    fontWeight: "600",
                    color: "#7D8592",
                  },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  props?.handleClearAllNotification();
                }}
              >
                <SVG.Close /> <span>{t("Dashboard.components.clearAll")}</span>
              </Button>
              {/* <Button
                variant="contained"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  justifyContent: "center",
                  fontWeight: "600",
                  borderRadius: "12px !important",
                  background:
                    "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
                  color: "#fff !important",
                }}
              >
                <SVG.Eye /> <span>View All</span>
              </Button> */}
            </Box>
            <Stack
              direction={"column"}
              spacing={1}
              divider={
                <Divider
                  sx={{ borderColor: "#E4E6E8" }}
                  orientation="horizontal"
                  flexItem
                />
              }
            >
              {Notifications.map((notification, indexNumber) => (
                <Stack
                  direction={"row"}
                  spacing={2}
                  key={notification._id}
                  sx={{ py: 2, pl: 4, pr: 3 }}
                >
                  <Avatar
                    src={UserProfile}
                    sx={{ width: 45, height: 45 }}
                    alt=""
                  />
                  <Box sx={{ flex: 1 }}>
                    <Typography
                      variant="h3"
                      sx={{
                        display: "flex",
                        fontSize: "18px",
                        color: "#0A1629",
                        lineHeight: "24px",
                      }}
                    >
                      <Link
                        to={
                          notification?.notificationType &&
                          notification?.relevantId
                            ? `${
                                NavigationLinks[notification?.notificationType]
                              }${notification?.relevantId}`
                            : "#"
                        }
                        onClick={(e) => {
                          props?.handleReadNotification(notification?._id);
                        }}
                      >
                        <span style={{ width: "85%" }}>
                          {`${notification?.title} : ${notification?.message}`}
                        </span>
                      </Link>
                      {/* <Box
                        component={"span"}
                        sx={{
                          ml: "auto",
                          color: "#F65160",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "inline-flex",
                          mr: 2,
                          cursor: "pointer",
                        }}
                        onClick={(e)=>{
                          e?.preventDefault()
                          props?.handleCloseNotification(indexNumber);
                        }}
                      >
                        <SVG.Close />
                      </Box> */}
                    </Typography>
                    <Stack
                      direction={"row"}
                      spacing={1}
                      justifyContent={"space-between"}
                      sx={{ mt: 2 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15px",
                          "& b": { fontWeight: "600" },
                        }}
                      >
                        <span>
                          {moment(notification?.createdAt).format(
                            "DD, MMM YYYY"
                          )}
                        </span>
                        <b>{moment(notification?.createdAt).format("HH:mm")}</b>
                      </Box>
                      <Link
                        style={{
                          fontSize: "13px",
                          fontWeight: 400,
                          textDecoration: "none",
                          color: "#7D8592",
                          borderBottom: "1px solid #7D8592",
                          paddingBottom: "5px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          props?.handleReadNotification(notification?._id);
                        }}
                      >
                        {t("Dashboard.components.read")}
                      </Link>
                    </Stack>
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              marginTop: "10px",
            }}
          >
            <h4>
              <i>{t("Dashboard.components.noNotification")}</i>
            </h4>
          </Box>
        )}
      </Box>
    </>
  );
}
