import { Avatar, Box, Button, Divider, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { SVG } from "../icon/svg.file";
import UserProfile from "../images/message.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import constants from "../new redux/Types/actionTypes";
import action from "../new redux/Action";
import {
  SocketContext,
  useSocket,
} from "../utility-files/socket/socket.provider";
import { FidgetSpinner } from "react-loader-spinner";
import { BASE_URL } from "../constant/global";
import { useTranslation } from "react-i18next";

export default function Message({
  handleClose,
  handleClearAll,
  tickets,
  loader,
}) {
  const { t } = useTranslation();
  const ticketData = tickets;
  const dispatch = useDispatch();
  const socket = useSocket();

  const displayDate = (date) => {
    const currentDate = moment();
    const otherDate = moment(date);
    const diffInDays = currentDate.diff(otherDate, "days");
    let dateFormat;

    if (diffInDays < 1) {
      dateFormat = moment(date).format("HH:mm");
    } else {
      dateFormat = moment(date).format("DD/MMM/YY");
    }
    return dateFormat;
  };

  return (
    <Box>
      <Typography
        variant="h2"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "16px",
          borderBottom: "1px solid #E4E6E8",
          px: 3,
          py: 1,
          fontWeight: 700,
          direction:
            localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr",
        }}
      >
        <span>{t("Ticket_details.Messages")}</span>
        <Box
          component={"span"}
          sx={{ cursor: "pointer" }}
          onClick={handleClose}
        >
          <SVG.CloseIcon />
        </Box>
      </Typography>
      {loader ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <FidgetSpinner />
        </Box>
      ) : (
        <>
          {ticketData?.data?.filter((index) => index?.unmarkmsgcount > 0)
            .length > 0 ? (
            <Box sx={{ overflow: "auto", height: "445px" }}>
              <Box sx={{ display: "flex", justifyContent: "end", mb: 0, p: 2 }}>
                <Button
                  variant="outlined"
                  onClick={handleClearAll}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "center",
                    fontWeight: "600",
                    "& svg": {
                      fontWeight: "600",
                      color: "#7D8592",
                    },
                  }}
                >
                  <SVG.Close />{" "}
                  <span>{t("Dashboard.components.clearAll")}</span>
                </Button>
              </Box>
              <Stack
                direction={"column"}
                spacing={2}
                divider={
                  <Divider
                    sx={{ borderColor: "#E4E6E8" }}
                    orientation="horizontal"
                    flexItem
                  />
                }
              >
                {ticketData.data &&
                  ticketData?.data
                    ?.filter((index) => index?.unmarkmsgcount > 0)
                    ?.sort((a, b) => {
                      const dateA = a.recentmsg?.createdAt || "";
                      const dateB = b.recentmsg?.createdAt || "";
                      return new Date(dateB) - new Date(dateA);
                    })
                    .map((index) => (
                      <Link
                        to={`/ticket?id=1&ticketTabs=1&ticket=${index?.ticket}`}
                        style={{
                          fontSize: "13px",
                          fontWeight: 400,
                          textDecoration: "none",
                          color: "#7D8592",
                          borderBottom: "1px solid #7D8592",
                          paddingBottom: "5px",
                        }}
                        onClick={() => {
                          dispatch(
                            action(constants.ticket, index?.ticket || "")
                          );
                          // console.log("<Message> ticket", {
                          //   data: { ticket: index?.tickets[0] },
                          // });
                          dispatch(
                            action(constants.selectedTicket, {
                              data: { ticket: index?.tickets[0] },
                            })
                          );
                          dispatch(
                            action(constants.mobileViewTicketToggle, true)
                          );
                          handleClose();
                        }}
                      >
                        <Stack
                          direction={"row"}
                          spacing={3}
                          key={index.ticket}
                          sx={{ py: 2, pl: 4, pr: 3 }}
                        >
                          <Avatar
                            src={
                              index?.resent_msg_by[0]?.photo
                                ? `${BASE_URL}/media/users/${index?.resent_msg_by[0]?.photo}`
                                : UserProfile
                            }
                            sx={{ width: 45, height: 45 }}
                            alt=""
                          />

                          <Box sx={{ flex: 1 }}>
                            <Typography
                              variant="h3"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "16px",
                              }}
                            >
                              <span>
                                {index?.resent_msg_by[0]?.fullname || ""}
                              </span>{" "}
                              {index.unmarkmsgcount > 0 && (
                                <Box
                                  component={"span"}
                                  sx={{
                                    ml: "auto",
                                    background: "#34A853",
                                    width: "18px",
                                    height: "18px",
                                    color: "#fff",
                                    borderRadius: "100%",
                                    fontSize: "12px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "inline-flex",
                                    mr: 2,
                                  }}
                                >
                                  {index.unmarkmsgcount}
                                </Box>
                              )}
                            </Typography>
                            <Box sx={{ width: "75%", mt: 1 }}>
                              <Box
                                component={"p"}
                                sx={{
                                  m: 0,
                                  fontSize: "13px",
                                  color: "#7D8592",
                                }}
                              >
                                {index?.recentmsg?.message || ""}
                              </Box>
                            </Box>
                            <Stack
                              direction={"row"}
                              spacing={2}
                              justifyContent={"space-between"}
                              sx={{ mt: 0.5 }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "15px",
                                  "& i": { fontWeight: "600" },
                                }}
                              >
                                <span>
                                  {displayDate(index?.recentmsg?.createdAt)}
                                </span>
                              </Box>

                              <Link
                                to={`/ticket?id=1&ticketTabs=1&ticket=${index?.ticket}`}
                                style={{
                                  fontSize: "13px",
                                  fontWeight: 400,
                                  textDecoration: "none",
                                  color: "#7D8592",
                                  borderBottom: "1px solid #7D8592",
                                  paddingBottom: "5px",
                                }}
                                onClick={() => {
                                  dispatch(
                                    action(
                                      constants.ticket,
                                      index?.ticket || ""
                                    )
                                  );
                                  // console.log("<Message> ticket", {
                                  //   data: { ticket: index?.tickets[0] },
                                  // });
                                  dispatch(
                                    action(constants.selectedTicket, {
                                      data: { ticket: index?.tickets[0] },
                                    })
                                  );
                                  handleClose();
                                  dispatch(
                                    action(
                                      constants.mobileViewTicketToggle,
                                      true
                                    )
                                  );
                                }}
                              >
                                {t("Dashboard.components.respond")}
                              </Link>
                            </Stack>
                          </Box>
                        </Stack>
                      </Link>
                    ))}
              </Stack>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginTop: "10px",
              }}
            >
              <h4>
                <i> {t("Dashboard.components.noMessage")}</i>
              </h4>
            </Box>
          )}
        </>
      )}
    </Box>
  );
}
