import React from 'react';
import '../CustomLoader/CustomLoader.css';

const SmallLoader = () => (
    <div style={{
        display: "loading" ? 'block' : 'none',
        position: 'absolute',
        top: '130%',
        left: '27%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1,
        color: 'white',
        fontSize:"20px"
      }}>
    Loading...
  </div>
);

export default SmallLoader;