import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function AdvertisementTabs(props) {
  const {t}= useTranslation()
  const userTabs = [
    {
      id: 1,
      label: t("Ad_index.advertisements.tabs.all"),
    },
    {
      id: 2,
      label: t("Ad_index.advertisements.tabs.pending"),
    },
    {
      id: 3,
      label: t("Ad_main.advertisements.tabs.active"),
    },
    {
      id: 4,
      label: t("Ad_main.advertisements.tabs.approved"),
    },
    {
      id: 5,
      label: t("Ad_main.advertisements.tabs.unapproved"),
    },
    {
      id: 6,
      label: t("Ad_main.advertisements.tabs.expired"),
    },
  ];

  return (
    <>
      <Box
        sx={{
          borderBottom: "1px solid #322E27",
          mb: 2,
          "& ul": {
            display: "flex",
            margin: "0px",
            padding: "0px",
            listStyle: "none",
            "@media (max-width:768px)": {
              overflow: "auto",
            },
            "& li": {
              color: "#fff",
              fontSize: "16px",
              padding: "16px 25px",
              cursor: "pointer",
              fontWeight: "600",

              borderBottom: "2px solid transparent",
              "&:hover": {
                background:
                  "linear-gradient(to right, #F7C409 0%, #B44C06 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                borderBottom: "2px solid #B44C06",
              },
            },
          },
        }}
      >
        <ul>
          {userTabs.map((item) => (
            <li
              key={item.id}
              onClick={() => props.handleTabs(item.id)}
              style={{
                background:
                  props.tabs === item.id
                    ? "linear-gradient(to right, #F7C409 0%, #B44C06 100%)"
                    : "",
                WebkitBackgroundClip: props.tabs === item.id ? "text" : "",
                WebkitTextFillColor:
                  props.tabs === item.id ? "transparent" : "",
                borderBottom:
                  props.tabs === item.id ? "2px solid #B44C06 " : "",
              }}
            >
              {item.label}
            </li>
          ))}
        </ul>
      </Box>
    </>
  );
}
