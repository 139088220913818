export const vFirstName = /^[A-Za-z][A-Za-z0-9\-_.\s]{2,49}$/;
export const vName = /^[A-Z][A-Za-z\d\s]{2,99}$/;
export const vCodeRqAlphaNumeric = /^(?:[0-9]+[a-z]|[a-z]+[0-9])[a-z0-9]*$/i;
export const vCode = /^[A-Z0-9]{3,7}$/;
export const vCompanyName = /^[a-z\d\-_.\s]{2,99}$/i;
export const vEmail = /^[\w.-]+@[a-zA-Z_-]+?\.[a-zA-Z]{2,4}$/;
export const vAadhar = /^\d{4}\s\d{4}\s\d{4}$/;
export const vPan = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/;
export const vMob = /^\d{10}$/;
export const vDes = /^[\w\s.,!?()-]*$/;
export const vMobnum = /^((\+[1-9]{1,12}[ \-])|(\([0-9]{1,13}\)[ \-])|([0-9]{1,13})[ \-]*)/;
export const vAge = /^\d{1,3}$/;
export const vAcc = /^\d{15}$/;
export const vHsn = /^\d{8}$/;
export const vPin = /^[1-9][0-9]{5}$/;
export const vNum = /^\d{10}$/;
export const vPrice = /^[0-9.]+$/; // Improved regular expression
export const vTim = /^(10|11|12|13|14|15|16|17|18|19|20|21|22|23|[0-9]):[0-5][0-9]$/; // Fixed regular expression
export const vMinTenPer = /^(10?[1-9]|[1-9][0-9]|100)$/; // Improved regular expression
export const vNumNew = /^(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/; // Improved regular expression
export const vPercent = /^(0|[1-9]\d?)(\.\d{1,4})?|100\.0000$/; // Improved regular expression
export const vTelephoneNum = /^[1-9][0-9]{8,12}$/; // Corrected regular expression
export const vGst = /^([1-9][0-9]|[1-2][0-9]|[3][0-5])([A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
export const vPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!#^()-_+=%*?&]).{8,}$/; // Corrected regular expression
export const vCountrycode = /^(\+?\d{1,3}|\d{1,6})$/;
export const vIfsccode = /^[A-Za-z]{4}\d{6,7}$/;
export const vCurrencyCode = /^[A-Z]{2,3}$/;
export const vSiteUrl = /^(https?:\/\/(?:www\.)?|ftp:\/\/(?:www\.)?|www\.)[0-9A-Za-z-_.@:%+~#=]+(?:\.[a-zA-Z]{2,3})+(?:\/.*)?(\?.*)?$/; // Corrected regular expression
export const strongRegularExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/; // Corrected regular expression
