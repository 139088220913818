import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material"; // Import CheckCircleOutline icon from Material-UI icons
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SVG } from "../../../icon/svg.file";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SingleDatePiker from "../../../components/chart/SingleDatePiker";
import {
  callHttpRequest,
  methodType,
} from "../../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../../utility-files/api-caller/CommonRequest";
import * as global from "../../../constant/global";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import action from "../../../new redux/Action";
import constants from "../../../new redux/Types/actionTypes";
import { getDateCalender } from "../../../utility-files/date-util/DateHandling";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import axios from "axios";
import * as StorageHandling from "../../../utility-files/storage-util/StorageHandling";
import {
  isArrayValue,
  isIntegerValue,
} from "../../../utility-files/data-util/DataHandler";
import ReactPlayer from "react-player";
import { useTranslation } from "react-i18next";

export default function AddAvertisement() {
  const matches = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const [uploadData, setUploadData] = useState({
    billboard: [],
    type: "",
    title: "",
    status: "pending",
    video: "",
    closing_at: "",
    start_at: "",
    advertiser: "",
    campaign_cost: 0,
    sales_proposal_cost: 0,
    duration: 0,
  });
  const [pending, setPending] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);
  const [cameraBillBoard, setCameraBillBoard] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [dateError, setDateError] = useState("");
  const [cost, setCost] = useState(null);
  const [videoBinary, setVideoBinary] = useState(null);
  const [selectedItems, setSelectedItems] = React.useState(
    uploadData.billboard
  );
  const AdDetails = useSelector((state) => state.AdDetails) || [];
  const UserData = useSelector((state) => state.UserData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [billboards, setBillboards] = useState([]);
  const [billBoardData, setBillboardData] = useState([]);
  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  const countryName =
    localStorage.getItem(StorageHandling.storageKey.USERDATA) ||
    sessionStorage.getItem(StorageHandling.storageKey.USERDATA);

  useEffect(() => {
    getAdvertiserList();
    getBoardCamera();
  }, []);
  const convertToLowerCase = (inputString) => {
    return inputString.toLowerCase();
  };

  const getBoardCamera = async () => {
    let request;
    request = getRequestForApi(
      global.GET_BILLBOARD_CAMERA + `${countryName}`,
      methodType.GET
    );
    await callHttpRequest(request)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          if (response?.data?.data?.docs) {
            let dataList = response?.data?.data?.docs;
            setCameraBillBoard([...dataList]);
          }
        }
      })
      .catch((err) => {
        console.error("Error in API call:", err);
      });
  };

  const addAdvertisement = async () => {
    setPending(true);

    // console.log("billboard", selectedItems);

    const formData = new FormData();
    formData.append("billboard", uploadData.billboard);
    formData.append("type", convertToLowerCase(uploadData.type));
    formData.append("title", uploadData.title);
    formData.append("status", uploadData.status);
    formData.append("video", selectedVideo);
    formData.append("closing_at", formatDate(uploadData.closing_at));
    formData.append("start_at", formatDate(uploadData.start_at));
    formData.append("advertiser", uploadData.advertiser);
    formData.append(
      "campaign_cost",
      parseFloat(uploadData.campaign_cost).toFixed(2)
    );
    formData.append(
      "sales_proposal_cost",
      parseInt(uploadData.sales_proposal_cost)
    );
    formData.append("duration", parseInt(uploadData.duration));

    try {
      const response = await axios.post(global.ADD_ADD, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      });

      if (response.status === 200 || response.status === 201) {
        toast.success(t("Ad_Add.adUploadSuccess"));
        navigate("/advertisement");
      } else {
        console.error("Failed to upload ad. Status:", response.status);
        toast.error(t("Ad_Add.adUploadFail"));
      }
    } catch (error) {
      console.error("Error uploading ad:", error);
      toast.error(t("Ad_Add.adUploadFail2"));
    } finally {
      setPending(false);
    }
  };

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const day = `0${d.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
  };

  const getAdvertiserList = async () => {
    setPending(true);

    try {
      const request = getRequestForApi(
        global.ADDVERTISER_LIST + `&country=${countryName}`,
        {},
        methodType.GET
      );
      const response = await callHttpRequest(request);

      if (response?.status === 200 || response?.status === 201) {
        dispatch(action(constants.AdDetails, response?.data.data.docs));
      }
    } catch (err) {
      console.error("Error fetching advertiser list:", err);
    } finally {
      setPending(false);
    }
  };
  const getCost = async () => {
    try {
      // Ensure all necessary data is available
      if (
        !selectedItems ||
        selectedItems.length === 0 ||
        !startDate ||
        !endDate ||
        uploadData.duration <= 0
      ) {
        return; // Exit early if any required data is missing
      }

      const filteredBillboardNames = selectedItems.join(","); // Join selected items into a string
      const startdate = formatDate(startDate);
      const enddate = formatDate(endDate);
      const duration = uploadData.duration;
      const subtract = 0;

      const requestUrl = `${global.BASE_URL}/api/v2/ad/get-cost/${filteredBillboardNames}/${startdate}/${enddate}/${duration}?subtract=${subtract}`;
      const request = getRequestForApi(requestUrl, {}, methodType.GET);

      const response = await callHttpRequest(request);

      if (response?.status === 200 || response?.status === 201) {
        const responseData = response.data?.data;

        if (Array.isArray(responseData)) {
          // Calculate total campaign cost
          let totalCampaignCost = 0;
          responseData.forEach((item) => {
            totalCampaignCost += item.campaign_cost || 0;
          });
          // console.log("totalCampaignCost", totalCampaignCost);
          handleChange("campaign_cost", totalCampaignCost); // Update campaign cost in state
          setCost(responseData); // Set response data to state as needed
        } else {
          console.warn("Response data is not an array or is empty");
        }
      } else {
        console.error("Failed to fetch cost. Status:", response?.status);
      }
    } catch (err) {
      console.error("Error fetching cost:", err);
    } finally {
    }
  };
  useEffect(() => {
    // Check if all necessary data is available to make the API call
    if (
      selectedItems &&
      selectedItems.length > 0 &&
      startDate &&
      endDate &&
      uploadData.duration > 0
    ) {
      getCost(); // Call getCost function when dependencies change
    }
  }, [selectedItems, startDate, endDate, uploadData.duration]);

  useEffect(() => {
    if (fullname) {
      const selectedUser = AdDetails.find((user) => user.fullname === fullname);
      if (selectedUser) {
        setPhoneNumber(selectedUser.phone);
        setEmail(selectedUser.email);
        setUserId(selectedUser._id);
        handleChange("advertiser", selectedUser._id);
      }
    }
  }, [fullname, AdDetails]);

  const handleChange = (key, value) => {
    setUploadData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    // console.log("<AddAvertisement> handleChange UploadData", key, value);
  };

  const handleVideoChange = (event) => {
    const file = event.target.files[0];
    setSelectedVideo(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setVideoBinary(new Uint8Array(e.target.result));
        handleChange("video", new Uint8Array(e.target.result));
      };
      reader.readAsArrayBuffer(file);
      calculateVideoDuration(file);
    }
  };

  const showAlert = (message) => {
    toast.info(message);
  };

  const calculateVideoDuration = (file) => {
    const videoElement = document.createElement("video");
    videoElement.preload = "metadata";

    videoElement.onloadedmetadata = () => {
      window.URL.revokeObjectURL(videoElement.src);
      const duration = Math.floor(videoElement.duration) + 1; // Adjust the duration calculation
      const width = videoElement.videoWidth;
      const height = videoElement.videoHeight;
      const aspectRatio = width / height;

      if (duration > 30) {
        showAlert(t("Ad_Add.VideoDurationRequirement"));
        setSelectedVideo(null);
        handleChange("video", null);
        return;
      }

      setVideoDuration(duration);
      handleChange("duration", duration);
    };

    videoElement.onerror = () => {
      showAlert(t("Ad_Add.adUploadFail2"));
      setSelectedVideo(null);
      handleChange("video", null);
    };

    videoElement.src = URL.createObjectURL(file);

    // Clean up the video element after use
    return () => {
      window.URL.revokeObjectURL(videoElement.src);
      videoElement.remove();
    };
  };

  const handleOpenFileDialog = () => {
    const inputElement = document.getElementById("video-upload");
    if (inputElement) {
      inputElement.click();
    }
  };

  // const billboards = AdDetails.length > 0 ? AdDetails[0]?.billboards || [] : [];

  useEffect(() => {
    if (AdDetails.length > 0 && fullname) {
      const billboardList =
        AdDetails.length > 0
          ? AdDetails?.filter(
              (Advertiser) => Advertiser.fullname === fullname
            )[0]?.billboards || []
          : [];
      setBillboards(billboardList);
    }
  }, [fullname]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleChange("start_at", formatDate(date));
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleChange("closing_at", formatDate(date));
  };

  const handleSelect = (item) => {
    if (!selectedItems.includes(item.billboardName)) {
      setSelectedItems([...selectedItems, item.billboardName]);
    }
  };

  const handleCancel = (item) => {
    setSelectedItems(
      selectedItems.filter(
        (selectedItem) => selectedItem !== item.billboardName
      )
    );
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    handleChange("billboard", value);
    setSelectedItems(value);
  };

  // const billBoardData = Array.isArray(cameraBillBoard)
  //   ? cameraBillBoard.filter(
  //       (element) =>
  //         billboards.includes(element.billboardName) &&
  //         element.status === "online"
  //     )
  //   : [];
  useEffect(() => {
    if (billboards.length > 0) {
      const auxilaryBillboardData = Array.isArray(cameraBillBoard)
        ? cameraBillBoard.filter(
            (element) =>
              billboards.includes(element.billboardName) &&
              element.status === "online"
          )
        : [];
      setBillboardData([...auxilaryBillboardData]);
    }
  }, [billboards]);
  // console.log(billBoardData);
  return (
    <>
      {pending && <CustomLoader />}
      <Typography
        variant="h1"
        sx={{
          fontSize: "30px",
          color: "#fff",
          fontWeight: "600",
          display: "flex",
          alignItems: "center",
          gap: "5px",
          "@media(max-width:992px)": {
            fontSize: "24px",
          },
        }}
      >
        <IconButton LinkComponent={Link} to="/advertisement">
          <SVG.ArrowRight />
        </IconButton>{" "}
        <span> {t("Ad_Add.Uploader")}</span>
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} lg={6}>
            <label
              style={{
                color: "#fff",
                marginBottom: "5px",
                display: "block",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
            >
              {t("Ad_Add.VideoTitleHere")}
            </label>
            <TextField
              placeholder={t("Ad_Add.VideoTitleHere")}
              fullWidth
              value={uploadData.title}
              onChange={(e) => handleChange("title", e.target.value)}
              sx={{
                input: {
                  color: "#fff",
                  "&::placeholder": {
                    color: "#687588",
                    opacity: 1,
                  },
                },
                "& .MuiInputBase-root": {
                  borderRadius: "15px",
                  background: "transparent",
                  color: "#687588",
                  border: "1px solid #687588",
                  fontSize: "15px",

                  "&:hover": { background: "transparent", color: "#687588" },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={6}>
            <label
              style={{
                color: "#fff",
                marginBottom: "5px",
                display: "block",
                fontSize: "14px",
                paddingLeft: "5px",
              }}
            >
              {t("Ad_Add.AddType")}
            </label>
            <Select
              sx={{
                "&.MuiInputBase-root": {
                  border: "1px solid #687588",
                  fontSize: "15px",
                  width: "100%",
                  textAlign:
                    localStorage.getItem("i18nextLng") === "ar"
                      ? "right"
                      : "left",
                  borderRadius: "15px",
                  background: "transparent",
                  color: "#fff",
                },
                "& .MuiSelect-select": {
                  paddingRight:
                    localStorage.getItem("i18nextLng") === "ar" &&
                    "14px !important",
                  paddingLeft:
                    localStorage.getItem("i18nextLng") === "ar"
                      ? "32px !important"
                      : "14px",
                },

                "& fieldset": {
                  display: "none",
                },
                "& .MuiSvgIcon-root": {
                  color: "#687588",
                  marginRight:
                    localStorage.getItem("i18nextLng") === "ar" ? "0" : "10px",
                  marginLeft:
                    localStorage.getItem("i18nextLng") === "ar"
                      ? "10px"
                      : "0px",
                  right:
                    localStorage.getItem("i18nextLng") === "ar" ? "auto" : "0",
                  left:
                    localStorage.getItem("i18nextLng") === "ar" ? "0" : "auto",
                },
              }}
              defaultValue=""
              value={uploadData.type}
              onChange={(e) => handleChange("type", e.target.value)}
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value="" disabled>
                {t("Ad_Add.AddType")}
              </MenuItem>
              <MenuItem value="Local"> {t("Ad_Add.Local")}</MenuItem>
              {/* <MenuItem value="Global">Global</MenuItem> */}
              <MenuItem value="Partner">{t("Ad_Add.Partner")}</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 1.5 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} lg={4}>
            <Card sx={{ borderRadius: "16px", mb: 2 }} elevation={0}>
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,
                  pt: 3,
                  "&:last-child": { pb: 2 },
                }}
              >
                <Box
                  sx={{
                    borderRadius: "8px",
                    overflow: "hidden",
                    height: "100%",
                    color: "#fff",
                  }}
                >
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: "16px",
                      color: "#fff",
                      fontWeight: "600",
                      mb: 2,
                    }}
                  >
                    {t("Ad_Add.AdvertiserDetails")}
                  </Typography>
                  <Box
                    sx={{
                      fontSize: "15px",
                      mb: 1,
                      color: "#fff",
                      textAlign:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "right"
                          : "left",
                    }}
                  >
                    {t("Ad_Add.SelectAdvertiser")}
                  </Box>
                  <Box>
                    <Accordion
                      expanded={true}
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid #687588",
                        overflow: "hidden",
                        mb: 2,
                        "& .MuiAccordionSummary-root.Mui-expanded": {
                          minHeight: "50px",
                        },
                        "& .MuiAccordionSummary-content.Mui-expanded": { m: 0 },
                      }}
                    >
                      <AccordionSummary
                        sx={{
                          background: "#221F1A",
                          borderBottom: "1px solid #687588",
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography sx={{ color: "#fff", fontSize: "13px" }}>
                          {t("Ad_Add.partners")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ background: "#322E27", color: "#fff" }}
                      >
                        <Stack direction={"column"} spacing={2}>
                          <Box
                            sx={{
                              "& label": {
                                color: "#fff",
                                fontSize: "13px",
                                display: "block",
                                mb: 1,
                                ml: 0.5,
                              },
                            }}
                          >
                            <label> {t("Ad_Add.Name")}</label>
                            <Select
                              sx={{
                                "&.MuiInputBase-root": {
                                  border: "1px solid #687588",
                                  fontSize: "15px",
                                  width: "100%",
                                  textAlign:
                                    localStorage.getItem("i18nextLng") === "ar"
                                      ? "right"
                                      : "left",
                                  borderRadius: "15px",
                                  background: "transparent",
                                  color: "#fff",
                                },
                                "& .MuiSelect-select": {
                                  paddingRight:
                                    localStorage.getItem("i18nextLng") ===
                                      "ar" && "14px !important",
                                  paddingLeft:
                                    localStorage.getItem("i18nextLng") === "ar"
                                      ? "32px !important"
                                      : "14px !imporant",
                                  fontFamily:
                                    localStorage.getItem("i18nextLng") === "ar"
                                      ? `"Cairo", sans-serif`
                                      : `"Manrope", sans-serif`,
                                },
                                "& fieldset": {
                                  display: "none",
                                },
                                "& .MuiSvgIcon-root": {
                                  color: "#fff",
                                  marginRight:
                                    localStorage.getItem("i18nextLng") === "ar"
                                      ? "0"
                                      : "10px",
                                  marginLeft:
                                    localStorage.getItem("i18nextLng") === "ar"
                                      ? "10px"
                                      : "0px",
                                  right:
                                    localStorage.getItem("i18nextLng") === "ar"
                                      ? "auto"
                                      : "0",
                                  left:
                                    localStorage.getItem("i18nextLng") === "ar"
                                      ? "0"
                                      : "auto",
                                },
                              }}
                              defaultValue=""
                              value={fullname}
                              onChange={(e) => setFullname(e.target.value)}
                              displayEmpty
                              IconComponent={KeyboardArrowDownIcon}
                            >
                              <MenuItem
                                value=""
                                disabled
                                sx={{
                                  justifyContent:
                                    localStorage.getItem("i18nextLng") === "ar"
                                      ? "flex-end"
                                      : "flex-start",
                                }}
                              >
                                {t("Ad_Add.Name")}
                              </MenuItem>
                              {AdDetails &&
                                AdDetails?.map((option) => (
                                  <MenuItem
                                    sx={{
                                      justifyContent:
                                        localStorage.getItem("i18nextLng") ===
                                        "ar"
                                          ? "flex-end"
                                          : "flex-start",
                                    }}
                                    key={option._id}
                                    value={option.fullname}
                                  >
                                    {option.fullname}
                                  </MenuItem>
                                ))}
                            </Select>
                          </Box>
                          <Box
                            sx={{
                              "& label": {
                                color: "#fff",
                                fontSize: "13px",
                                display: "block",
                                mb: 1,
                                ml: 0.5,
                              },
                            }}
                          >
                            <label> {t("Ad_Add.PhoneNumber")}</label>
                            <TextField
                              type="number"
                              fullWidth
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              disabled="true"
                              sx={{
                                input: {
                                  "&::placeholder": {
                                    color: "#fff",
                                    opacity: 1,
                                  },
                                  color: "#fff", // Add color conditionally based on value
                                },
                                "& .MuiInputBase-root": {
                                  borderRadius: "15px",
                                  background: "transparent",
                                  color: "#fff",
                                  border: "1px solid #687588",
                                  fontSize: "15px",

                                  "&:hover": {
                                    background: "transparent",
                                    color: "#fff",
                                  },
                                  "& .Mui-disabled": {
                                    WebkitTextFillColor: "#fff",
                                  },
                                },
                              }}
                            />
                          </Box>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </Box>
              </CardContent>
            </Card>
            <Card
              sx={{
                borderRadius: "16px",
                mb: 2,
                minHeight: "calc(100% - 392px)",
                background: "#322E27",
              }}
              elevation={0}
            >
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,
                  pt: 3,
                  "&:last-child": { pb: 2 },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "16px",
                    color: "#fff",
                    fontWeight: "600",
                    mb: 2,
                  }}
                >
                  {t("Ad_Add.CampaignDetails")}
                </Typography>
                <Stack direction={"column"} spacing={2}>
                  <Box>
                    {" "}
                    <Box
                      sx={{
                        "& label": {
                          color: "#fff",
                          fontSize: "13px",
                          display: "block",
                          mb: 1,
                          ml: 0.5,
                        },
                      }}
                    >
                      <label> {t("Ad_Add.CampaignStartDate")}</label>

                      <Box
                        sx={{
                          mb: 1,
                          "& .datepiker-single": {
                            background: "transparent !important",
                            border: "1px solid #687588 !important",
                            fontSize: "13px !important",
                          },
                        }}
                        onBlur={() => {
                          if (uploadData?.start_at && uploadData?.closing_at) {
                            if (
                              new Date(uploadData?.start_at) >
                              new Date(uploadData?.closing_at)
                            )
                              return setDateError(t("Ad_Add.endDateError"));
                            setDateError("");
                          }
                        }}
                      >
                        <SingleDatePiker
                          date={startDate}
                          onData={handleStartDateChange}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        "& label": {
                          color: "#fff",
                          fontSize: "13px",
                          display: "block",
                          mb: 1,
                          ml: 0.5,
                        },
                      }}
                    >
                      <label>{t("Ad_Add.CampaignEndDate")}</label>

                      <Box
                        sx={{
                          "& .datepiker-single": {
                            background: "transparent !important",
                            border: "1px solid #687588 !important",
                            fontSize: "13px !important",
                          },
                        }}
                        onBlur={() => {
                          if (uploadData?.start_at && uploadData?.closing_at) {
                            if (
                              new Date(uploadData?.closing_at) <
                              new Date(uploadData?.start_at)
                            )
                              return setDateError(t("Ad_Add.endDateError"));
                            setDateError("");
                          }
                        }}
                      >
                        <SingleDatePiker
                          date={endDate}
                          onData={handleEndDateChange}
                          minDate={startDate}
                          disabled={startDate ? false : true}
                        />
                      </Box>
                    </Box>
                    {dateError && (
                      <Typography color="error" sx={{ textAlign: "center" }}>
                        {dateError}
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      "& label": {
                        color: "#fff",
                        fontSize: "13px",
                        display: "block",
                        mb: 1,
                        ml: 0.5,
                      },
                    }}
                  >
                    <label>{t("Ad_Add.SelectABillboard")}</label>
                    <Select
                      sx={{
                        "&.MuiInputBase-root": {
                          border: "1px solid #687588",
                          fontSize: "15px",
                          width: "100%",
                          borderRadius: "15px",
                          background: "transparent",
                          color: "#fff",
                          textAlign:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "right"
                              : "left",
                        },
                        "& fieldset": {
                          display: "none",
                        },
                        "& .MuiSelect-select": {
                          paddingRight:
                            localStorage.getItem("i18nextLng") === "ar" &&
                            "14px !important",
                          paddingLeft:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "32px !important"
                              : "14px",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#fff",
                          marginRight:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "0"
                              : "10px",
                          marginLeft:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "10px"
                              : "0px",
                          right:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "auto"
                              : "0",
                          left:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "0"
                              : "auto",
                        },
                      }}
                      defaultValue={[]}
                      multiple
                      onChange={handleSelectChange}
                      displayEmpty
                      IconComponent={KeyboardArrowDownIcon}
                      value={selectedItems || []} // Ensure selectedItems is always an array
                      renderValue={(selected) =>
                        selected.length > 0
                          ? selected
                              .map(
                                (item) =>
                                  billBoardData.find(
                                    (board) => board.billboardName === item
                                  )?.address
                              )
                              .join(", ")
                          : t("Ad_Add.SelectABillboard")
                      }
                    >
                      <MenuItem
                        disabled
                        value=""
                        sx={{
                          justifyContent:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "flex-end"
                              : "flex-start",
                        }}
                      ></MenuItem>
                      {billBoardData.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.billboardName}
                          sx={{
                            display: "flex",
                            justifyContent:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "flex-end"
                                : "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            flexDirection={
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "row-reverse"
                                : "row"
                            }
                            sx={{
                              ml:
                                localStorage.getItem("i18nextLng") === "ar"
                                  ? "auto"
                                  : null,
                            }}
                          >
                            {selectedItems &&
                              selectedItems.includes(item.billboardName) && (
                                <CheckCircleOutline
                                  sx={{
                                    fontSize: 18,
                                    marginRight:
                                      localStorage.getItem("i18nextLng") ===
                                      "ar"
                                        ? "0px"
                                        : "10px",
                                    marginLeft:
                                      localStorage.getItem("i18nextLng") ===
                                      "ar"
                                        ? "10px"
                                        : "0px",
                                  }}
                                />
                              )}
                            {item.address}{" "}
                            {/* Always show address in the menu */}
                          </Box>
                          {selectedItems &&
                            selectedItems.includes(item.billboardName) && (
                              <Box
                                component="span"
                                sx={{
                                  cursor: "pointer",
                                  color: "red",
                                  marginLeft: "10px",
                                }}
                                onClick={(e) =>
                                  handleCancel(e, item.billboardName)
                                }
                              >
                                {t("modal.components.dialog.cancel")}
                              </Box>
                            )}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box
                    sx={{
                      "& label": {
                        color: "#fff",
                        fontSize: "13px",
                        display: "block",
                        mb: 1,
                        ml: 0.5,
                      },
                    }}
                  >
                    <label>{t("Ad_Add.AutomaticCalculation")}</label>

                    <TextField
                      placeholder="0"
                      fullWidth
                      value={parseFloat(uploadData.campaign_cost).toFixed(2)}
                      InputProps={{
                        readOnly: true,
                      }}
                      sx={{
                        input: {
                          "&::placeholder": {
                            color: "#fff",
                            opacity: 1,
                          },
                        },
                        "& .MuiInputBase-root": {
                          borderRadius: "15px",
                          background: "transparent",
                          color: "#fff",
                          border: "1px solid #687588",
                          fontSize: "15px",
                          "&:hover": {
                            background: "transparent",
                            color: "#fff",
                          },
                        },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      "& label": {
                        color: "#fff",
                        fontSize: "13px",
                        display: "block",
                        mb: 1,
                        ml: 0.5,
                      },
                    }}
                  >
                    <label>{t("Ad_Add.SalesAgentProposal")}</label>
                    <TextField
                      placeholder={t("Ad_Add.SalesAgentProposal")}
                      fullWidth
                      type="number"
                      value={uploadData.sales_proposal_cost}
                      onChange={(e) =>
                        handleChange("sales_proposal_cost", e.target.value)
                      }
                      sx={{
                        input: {
                          "&::placeholder": {
                            color: "#687588",
                            opacity: 1,
                          },
                        },
                        "& .MuiInputBase-root": {
                          borderRadius: "15px",
                          background: "transparent",
                          color: "#687588",
                          border: "1px solid #687588",
                          fontSize: "15px",

                          "&:hover": {
                            background: "transparent",
                            color: "#687588",
                          },
                        },
                      }}
                    />
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={8} lg={8}>
            <Card sx={{ borderRadius: "16px", height: "99%" }} elevation={0}>
              <CardContent
                sx={{
                  background: "#322E27",
                  color: "#fff",
                  p: 2,
                  minHeight: "100%",
                  "&:last-child": { pb: 2 },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "16px",
                    color: "#fff",
                    fontWeight: "600",
                    mb: 2,
                  }}
                >
                  {t("Ad_Add.Upload")}
                </Typography>
                <Box
                  sx={{
                    borderRadius: "20px",
                    border: "2px dashed #C4C4C4",
                    background: "#D8DEE933",
                    position: "relative",

                    minHeight: matches
                      ? "calc(100vh - 150px)"
                      : "calc(100vh - 0px)",
                    "& input": {
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: "0px",
                      left: "0px",
                      opacity: 0,
                      cursor: "pointer",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      p: 2,
                      borderBottom: "2px dashed #C4C4C4",
                    }}
                  >
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleVideoChange}
                      id="video-upload"
                    />
                    <SVG.UploadUp />
                    <Box
                      sx={{
                        mt: 1,
                        color: "#FDBF2D",
                        borderBottom: "1px solid #FDBF2D",
                      }}
                      onClick={handleOpenFileDialog}
                    >
                      {t("Ad_Add.UploadFiles")}
                    </Box>
                  </Box>
                  {selectedVideo && (
                    <Box sx={{ mt: 1, width: "50%", overflow: "hidden", p: 1 }}>
                      <ReactPlayer
                        url={URL.createObjectURL(selectedVideo)}
                        controls
                        width="100%"
                        height="100%"
                        playing={matches ? false : true}
                        loop={matches ? false : true}
                        muted={matches ? false : true}
                      />
                      <Typography variant="body2" sx={{ mt: 1, color: "#ccc" }}>
                        Selected: {selectedVideo.name}
                        {videoDuration && (
                          <span>, Duration: {videoDuration} seconds</span>
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>

                <Box sx={{ textAlign: "center", mt: 3 }}>
                  <Button
                    sx={{
                      background: "#fff",
                      borderRadius: "10px",
                      color: "#160B01",
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontWeight: "700",
                      width: "150px",
                      height: "38px",
                      "&:hover": { background: "#fff", color: "#160B01" },
                    }}
                    onClick={() => {
                      // console.log(
                      //   "<AddAdvertisemnt> Onclick uploadData",
                      //   new Date(uploadData.start_at) >
                      //     new Date(uploadData.closing_at)
                      // );
                      if (!uploadData.start_at)
                        return setDateError(t("Ad_Add.startDateValidation"));

                      if (!uploadData.closing_at)
                        return setDateError(t("Ad_Add.endDateValidation"));

                      if (uploadData.start_at && uploadData.closing_at) {
                        if (
                          new Date(uploadData.start_at) >
                          new Date(uploadData.closing_at)
                        )
                          return setDateError(t("Ad_Add.endDateError"));
                        if (
                          new Date(uploadData.closing_at) <
                          new Date(uploadData.start_at)
                        )
                          return setDateError(t("Ad_Add.endDateError"));
                      }
                      addAdvertisement();
                    }}
                  >
                    {t("Ad_Add.Save")}
                  </Button>
                </Box>

                <Box
                  sx={{
                    padding: "5px",
                    borderRadius: "8px",
                    color: "#4CAF50",
                    marginBottom: "20px",
                    marginTop: "40px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      padding: "20px",
                      fontSize: "20px",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "8px",
                      color: "#fff",
                    }}
                  >
                    {t("Ad_Add.YouShouldKnow")}:
                  </Typography>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "20px",
                          fontWeight: 600,
                          marginBottom: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleOutline
                          sx={{ fontSize: 20, marginRight: "10px" }}
                        />
                        {t("Ad_Add.OnlyVideosShouldBeUploaded")}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "20px",
                          marginBottom: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleOutline
                          sx={{ fontSize: 20, marginRight: "10px" }}
                        />
                        {t("Ad_Add.VideoResolutionRequirement")}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "20px",
                          marginBottom: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleOutline
                          sx={{ fontSize: 20, marginRight: "10px" }}
                        />
                        {t("Ad_Add.VideoDurationRequirement")}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleOutline
                          sx={{ fontSize: 20, marginRight: "10px" }}
                        />
                        {t("Ad_Add.CompanyPolicyRequirement")}
                      </Typography>
                    </div>
                  </div>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
