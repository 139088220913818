import { Box, IconButton } from "@mui/material";
import { SVG } from "../../icon/svg.file";
import { Link } from "react-router-dom";


//  useEffect(()=>{
//     getAllBillBorad()
//   },[])
//  const getAllBillBorad=async()=>{
//   let requset ,variables;
//   requset=getRequestForApi(global.GET_ALL_BILLBOARD,variables,methodType.GET);
//   await callHttpRequest(requset).then((response)=>{
//    if(response?.status===200 ||response?.status ===201){
//     setBillBoardData(response?.data)
//    };
//   }).catch((err)=>{
//    setError(err);
//   })
//  }

export const columns = [
  {
    id: 1,
    name: "Billboard Address",
    key: "billboard_address",
  },
  {
    id: 2,
    name: "Owner",
    key: "owner",
  },
  {
    id: 3,
    name: "Offline Started",
    key: "offline_started",
  },
  {
    id: 4,
    name: "Linked Tickets",
    key: "linked_tickets",
  },
  {
    id: 5,
    name: "Action",
    key: "action",
  },
];

export const rows = [1, 2, 3, 4, 5].map(() => {
  return {
    billboard_address: "13th Street. 47 W 13th St, New York",
    owner: "James Burles",
    offline_started: "03/02/2023 06:30",
    linked_tickets: "4",
    action: "action",
  };
});

export const OnlineColumnData = [
  {
    id: 1,
    name: "Billboard Address",
    key: "billboard_address",
  },
  {
    id: 2,
    name: "Owner",
    key: "owner",
  },

  {
    id: 3,
    name: "Installation",
    key: "installation",
  },
  {
    id: 4,
    name: "ADS Amount",
    key: "ads_amount",
  },
  {
    id: 5,
    name: "ADS Exposures",
    key: "ads_exposures",
  },
  {
    id: 6,
    name: "Action",
    key: "action",
  },
];

export const OnlineRows = [1, 2, 3, 4, 5].map(() => {
  return {
    billboard_address: "13th Street. 47 W 13th St, New York",
    owner: "James Burles",
    installation: "03/02/2023",
    ads_amount: "20",
    ads_exposures: "8765",
    action: (
      <IconButton
        LinkComponent={Link}
        to="/billboard/details"
        disableRipple
        sx={{
          background: "#322E27",
          borderRadius: "10px",
          width: "30px",
          height: "30px",
        }}
      >
        <SVG.EyeIcon />
      </IconButton>
    ),
  };
});

export const AllDataColumn = [
  {
    id: 1,
    name: "Billboard Address",
    key: "billboard_address",
  },
  {
    id: 2,
    name: "Owner",
    key: "owner",
  },
  {
    id: 3,
    name: "Status",
    key: "status",
  },
  {
    id: 4,
    name: "Installation",
    key: "installation",
  },
  {
    id: 5,
    name: "ADS Amount",
    key: "ads_amount",
  },
  {
    id: 6,
    name: "ADS Exposures",
    key: "ads_exposures",
  },
  {
    id: 7,
    name: "Action",
    key: "action",
  },
];

export const AllRowData = [1, 2, 3, 4, 5].map((index) => {
  return {
    billboard_address: (
      <Link
        to="/billboard/details"
        style={{ color: "#fff", textDecoration: "none" }}
      >
        13th Street. 47 W 13th St, New York
      </Link>
    ),
    owner: "James Burles",
    installation: "03/02/2023",
    status:
      index === 1 ? (
        <Box component={"span"} sx={{ color: "#F01F1F", fontWeight: "600" }}>
          Offline
        </Box>
      ) : (
        <Box component={"span"} sx={{ color: "#349734", fontWeight: "600" }}>
          Online
        </Box>
      ),
    ads_amount: "20",
    ads_exposures: "8765",
    action: (
      <IconButton
        LinkComponent={Link}
        to="/billboard/details"
        disableRipple
        sx={{
          background: "#322E27",
          borderRadius: "10px",
          width: "30px",
          height: "30px",
        }}
      >
        <SVG.EyeIcon />
      </IconButton>
    ),
  };
});
