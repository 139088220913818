import React from 'react';
import { Navigate } from 'react-router-dom';
import { storageKey } from "../../utility-files/storage-util/StorageHandling"
import Profile from '../../page/profile/userProfile';
const PrivateRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem(storageKey.TOKEN) || sessionStorage.getItem(storageKey.TOKEN);
    return isAuthenticated ? <>
        {children}
    </> : <Navigate to="/" />
};

export default PrivateRoute;