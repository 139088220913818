import React, { useState, useEffect } from "react";
import { Grid, Box, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import SmallLoader from "../../../CustomLoader/miniLoader";
import { useSelector } from "react-redux";
import moment from "moment";

const Item = styled(Box)(({ theme, bgColor }) => ({
  backgroundColor: bgColor,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: "1px solid #ccc",
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  position: "relative",
}));

const SevenBySevenGrid = () => {
  const [loading, setLoading] = useState(true);
  const [colorsArray, setColorsArray] = useState(Array(49).fill("#BFFCC6")); // Default color is set here
  const heatMapDetails = useSelector((state) => state.heatMapDetail);
  const rows = 7;
  const columns = 7;

  // Generate an array of dates for the grid
  const getDateArray = () => {
    const dates = [];
    const today = new Date();
    for (let i = 0; i < rows * columns; i++) {
      const date = new Date();
      date.setDate(today.getDate() - i - 1);
      dates.push(date.toISOString().split("T")[0]);
    }
    return dates;
  };

  const dateArray = getDateArray();

  // Function to map counts to colors
  const getColorForCount = (logsCount) => {
    let color;
    if (logsCount > 6) {
      color = "#F8B4B4";
      return color;
    }
    switch (logsCount) {
      case 1:
        color = "#CAE7B9";
        break;
      case 2:
        color = "#DADFB7";
        break;
      case 3:
        color = "#E8F8A2";
        break;
      case 4:
        color = "#F3E9A2";
        break;
      case 5:
        color = "#F9D6A3";
        break;
      case 6:
        color = "#F8B4B4";
        break;
      default:
        color = "#BFFCC6"; // Default color if count is 0 or undefined
        break;
    }
    return color;
  };

  useEffect(() => {
    if (
      heatMapDetails &&
      Array.isArray(heatMapDetails) &&
      heatMapDetails.length > 0
    ) {
      const countByDate = heatMapDetails.reduce((acc, log) => {
        if (!log.online) {
          const date = moment(log?.time).format("YYYY-MM-DD");
          // console.log("<SevenBySevenGrid> log.online", log.online, date);
          acc[date] = (acc[date] || 0) + 1;
        }
        return acc;
      }, {});

      // Map colors based on counts
      const colors = dateArray.map((date) =>
        getColorForCount(countByDate[date] || 0)
      );
      // console.log("<SevenBySevenGrid> dateArray", dateArray);
      // console.log("<SevenBySevenGrid> countByDate", countByDate);
      // console.log("<SevenBySevenGrid> colors", colors);

      // Function to update colors sequentially
      const updateColorsSequentially = (index) => {
        if (index < colors.length) {
          setColorsArray((prevColors) => {
            const newColors = [...prevColors];
            newColors[index] = colors[index];
            return newColors;
          });

          // Animate color changes one by one
          setTimeout(() => {
            updateColorsSequentially(index + 1);
          }, 100); // Adjust the interval time as needed (100ms here for a clearer effect)
        } else {
          setLoading(false);
        }
      };

      updateColorsSequentially(0);
    } else {
      setLoading(false); // If no heatMapDetails, set loading to false
    }
  }, [heatMapDetails]);

  return (
    <div>
      <Grid
        container
        spacing={0}
        style={{
          width: "100%",
          height: "50vh",
          display: "grid",
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
        }}
      >
        {dateArray.map((date, index) => (
          <Grid item key={index}>
            <Tooltip title={`Date: ${date}`}>
              {loading && !colorsArray[index] && <SmallLoader />}
              {!loading || colorsArray[index] ? (
                <Item bgColor={colorsArray[index] || "#BFFCC6"}></Item>
              ) : null}
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default SevenBySevenGrid;
