import "./App.css";
import "@fontsource/manrope"; // Defaults to weight 400
import "@fontsource/manrope/400.css"; // Specify weight
import routes from "./routes";
import ThemeCustomization from "./theme";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { SocketProvider } from "./utility-files/socket/socket.provider";
function App() {
  return (
    <ThemeCustomization>
      <SocketProvider>
        <RouterProvider router={createBrowserRouter(routes)}></RouterProvider>
        <ToastContainer rtl={false} />
      </SocketProvider>
    </ThemeCustomization>
  );
}

export default App;
