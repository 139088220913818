/* eslint-disable jsx-a11y/iframe-has-title */
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useTransition } from "react";
import { SVG } from "../../icon/svg.file";
// import DountChart from "../../components/chart/dount-chart";
import LineChart from "../../components/chart/LineChart";
import PieChart from "../../components/chart/PieChart";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import * as global from "../../constant/global";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import { useDispatch, useSelector } from "react-redux";
import { getDateWithTimeFormat } from "../../utility-files/date-util/DateHandling";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { format } from "date-fns";
import GoogleMapComponent from "./viewMap";
import action from "../../new redux/Action";
import constants from "../../new redux/Types/actionTypes";
import { useTranslation } from "react-i18next";
import { FidgetSpinner } from "react-loader-spinner";

export default function Dashboard() {
  const { t } = useTranslation();
  const [map, setMap] = useState(false);
  const [pending, setPending] = useState(false);
  const [lastData, setLastData] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [onlineCount, setOnlineCount] = useState(0);
  const [offlineCount, setOfflineCount] = useState(0);
  const cameraBillBoard = useSelector((state) => state.cameraBillBoard);
  const HomeData = useSelector((state) => state.HomeData);
  const dispatch = useDispatch();
  const [currentDateTime, setCurrentDateTime] = useState("");

  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  const handleMap = () => {
    setMap(true);
  };
  const BackPage = () => {
    setMap(false);
  };

  useEffect(() => {
    if (isAuthenticated) homeScreenData();
    lastScan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    const now = new Date();
    const formattedDate = format(now, "dd-MMM-yyyy hh:mm a");
    setCurrentDateTime(formattedDate);
  }, []);

  useEffect(() => {
    billboardStatusCount();
  }, []);

  const billboardStatusCount = async () => {
    setPending(true);
    let requset, variables;
    requset = getRequestForApi(
      global.BILLBOARD_STATUS_COUNT,
      variables,
      methodType.GET
    );
    await callHttpRequest(requset)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          setOnlineCount(response.data.data.online || 0);
          setOfflineCount(response.data.data.offline || 0);
          setTotalCount(
            response.data.data.online || 0 + response.data.data.offline || 0
          );
          setPending(false);
        }
      })
      .catch((err) => {
        setPending(false);
      });
  };
  const homeScreenData = async () => {
    setPending(true);
    let requset, variables;
    requset = getRequestForApi(global.DASBOARD_API, variables, methodType.GET);
    await callHttpRequest(requset)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          dispatch(action(constants.HomeData, response?.data));
          setPending(false);
        }
      })
      .catch((err) => {
        setPending(false);
      });
  };

  const lastScan = async () => {
    let requset, variables;
    requset = getRequestForApi(global.LAST_SEEN, variables, methodType.GET);
    await callHttpRequest(requset)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          setLastData(response?.data);
        }
      })
      .catch((err) => {});
  };

  return (
    <>
      {pending ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
          }}
        >
          <CustomLoader />
        </Box>
      ) : (
        <>
          {map ? (
            <Box>
              <Typography
                variant="h1"
                sx={{
                  fontSize: "30px",
                  color: "#fff",
                  fontWeight: "600",
                  mb: 3,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  "@media(max-width:992px)": {
                    fontSize: "24px",
                  },
                }}
              >
                <IconButton onClick={BackPage}>
                  <SVG.ArrowRight />
                </IconButton>
                <span>{t("Dashboard.components.billboardPlacement")}</span>
              </Typography>
              <GoogleMapComponent markers={cameraBillBoard[0]} />
            </Box>
          ) : (
            <div>
              <Stack
                direction={"column"}
                spacing={1}
                sx={{
                  mb: 2,
                  "& p": {
                    m: 0,
                    color: "#687588",
                    fontSize: "13px",
                    textAlign:
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "right"
                        : "",
                  },
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: "30px",
                    color: "#fff",
                    fontWeight: "600",
                    textAlign:
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "right"
                        : "",
                    "@media(max-width:992px)": {
                      fontSize: "24px",
                    },
                  }}
                >
                  {t("Dashboard.components.dashboard")}
                </Typography>
                <p>
                  {t("Dashboard.components.now")} -{" "}
                  {getDateWithTimeFormat(currentDateTime)}
                </p>
                <p>
                  {t("Dashboard.components.lastUpdate")} -{" "}
                  {getDateWithTimeFormat(lastData?.lastScan)}
                </p>
              </Stack>
              <Card sx={{ borderRadius: "0px", mb: 2 }} elevation={0}>
                <CardContent
                  sx={{
                    background: "#322E27",
                    color: "#fff",
                    p: 2,
                    "&:last-child": { pb: 2 },
                  }}
                >
                  <Box
                    component={"h2"}
                    sx={{
                      m: 0,
                      fontSize: "16px",
                      mb: 2,
                      letterSpacing: "1px",
                      textAlign:
                        localStorage.getItem("i18nextLng") === "ar"
                          ? "right"
                          : "left",
                    }}
                  >
                    {t("Dashboard.components.dailyStatistics")}
                  </Box>
                  {!HomeData.total_impression ? (
                    <FidgetSpinner />
                  ) : (
                    <Stack
                      direction={{ xs: "column", lg: "row" }}
                      divider={
                        <Divider
                          sx={{
                            borderColor: "#494130",
                            "@media(max-width:992px)": {
                              display: "none",
                            },
                          }}
                          orientation="vertical"
                          flexItem
                        />
                      }
                      spacing={{ xs: 1, lg: 3 }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          width: "33%",
                          "@media(max-width:992px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "56px",
                            height: "56px",
                            borderRadius: "100%",
                            display: "inline-flex",
                            alignItems: "center",
                            background: "#494130",
                            justifyContent: "center",
                          }}
                        >
                          <SVG.Clock />
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            "& p": { fontSize: "14px" },
                          }}
                        >
                          <Box
                            component={"h3"}
                            sx={{
                              fontSize: "32px",
                              m: 0,
                              "@media(max-width:992px)": {
                                fontSize: "20px",
                              },
                            }}
                          >
                            {HomeData?.rush_hour}
                          </Box>
                          <p>{t("Dashboard.components.rushHour")}</p>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          width: "33%",
                          "@media(max-width:992px)": {
                            width: "100%",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            width: "56px",
                            height: "56px",
                            borderRadius: "100%",
                            display: "inline-flex",
                            alignItems: "center",
                            background: "#494130",
                            justifyContent: "center",
                          }}
                        >
                          <SVG.Eye />
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            "& p": { fontSize: "14px" },
                          }}
                        >
                          <Box
                            component={"h3"}
                            sx={{
                              fontSize: "32px",
                              m: 0,
                              "@media(max-width:992px)": {
                                fontSize: "20px",
                              },
                            }}
                          >
                            {HomeData?.total_impression}
                          </Box>
                          <p>{t("Dashboard.components.impressions")}</p>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "16px",
                          width: "33%",
                          cursor: "pointer",
                          "@media(max-width:992px)": {
                            width: "100%",
                          },
                        }}
                        onClick={handleMap}
                      >
                        <Box
                          sx={{
                            width: "56px",
                            height: "56px",
                            borderRadius: "100%",
                            display: "inline-flex",
                            alignItems: "center",
                            background: "#494130",
                            justifyContent: "center",
                          }}
                        >
                          <SVG.MapIcon />
                        </Box>
                        <Box
                          sx={{
                            flex: 1,
                            textAlign:
                              localStorage.getItem("i18nextLng") === "ar"
                                ? "right"
                                : "left",
                            "& p": { fontSize: "14px" },
                          }}
                        >
                          <Box
                            component={"h3"}
                            sx={{
                              fontSize: "32px",
                              m: 0,
                              "@media(max-width:992px)": {
                                fontSize: "20px",
                              },
                            }}
                          >
                            {t("Dashboard.components.map")}
                          </Box>
                          <p
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            {t("Dashboard.components.goToMap")}
                          </p>
                        </Box>
                      </Box>
                    </Stack>
                  )}
                </CardContent>
              </Card>
              <Card sx={{ borderRadius: "5px", mb: 2 }} elevation={0}>
                <CardContent
                  sx={{
                    background: "#322E27",
                    color: "#fff",
                    p: 2,
                    "&:last-child": { pb: 2 },
                  }}
                >
                  <Grid container spacing={4}>
                    <Grid item xs={12} lg={7}>
                      <Box
                        component={"h2"}
                        sx={{
                          m: 0,
                          fontSize: "16px",
                          mb: 2,
                          letterSpacing: "1px",
                          textAlign:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "right"
                              : "left",
                        }}
                      >
                        {t("Dashboard.components.impressions")}
                      </Box>
                      {!HomeData.hour_impression ? (
                        <FidgetSpinner />
                      ) : (
                        <LineChart dasboardData={HomeData} />
                      )}
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <Box
                        component={"h2"}
                        sx={{
                          m: 0,
                          fontSize: "16px",
                          mb: 2,
                          letterSpacing: "1px",
                          textAlign:
                            localStorage.getItem("i18nextLng") === "ar"
                              ? "right"
                              : "left",
                        }}
                      >
                        {t("Dashboard.components.billboardStatus")}
                      </Box>
                      {/* <DountChart /> */}
                      {pending ? (
                        <FidgetSpinner />
                      ) : (
                        <PieChart
                          totalCount={totalCount}
                          OnlineCount={onlineCount}
                          OfflineCount={offlineCount}
                        />
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </div>
          )}
        </>
      )}
    </>
  );
}
