import { Close } from "@mui/icons-material";
import { Box, Dialog, IconButton, Paper, Stack } from "@mui/material";
import React from "react";

function ImagePreview({ src, setPreview }) {
  return (
    <Dialog open={!!src} onClose={() => setPreview("")} sx={{ p: 2 }}>
      <Stack justifyContent="flex-end" direction="row">
        <IconButton onClick={() => setPreview("")} color="error">
          <Close />
        </IconButton>
      </Stack>
      <Box p={2}>
        <Paper elevation={3}>
          <img src={src} alt="Preview" />
        </Paper>
      </Box>
    </Dialog>
  );
}

export default ImagePreview;
