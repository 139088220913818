export const ru = {
  Ad_Add: {
    CustomLoader: "Загрузка...",
    Uploader: "Загрузчик",
    VideoTitleHere: "Здесь заголовок видео",
    AddType: "Тип объявления",
    Local: "Локальный",
    Global: "Глобальный",
    Partner: "Партнер",
    AdvertiserDetails: "Детали рекламодателя",
    SelectAdvertiser: "Выберите рекламодателя",
    Name: "Имя",
    PhoneNumber: "Номер телефона",
    PhoneNumberPlaceholder: "Введите номер телефона",
    CampaignDetails: "Детали кампании",
    CampaignStartDate: "Дата начала кампании",
    CampaignEndDate: "Дата окончания кампании",
    SelectABillboard: "Выберите биллборд",
    AutomaticCalculation: "Автоматический расчет",
    SalesAgentProposal: "Предложение агента по продажам",
    Upload: "Загрузить",
    UploadFiles: "Загрузить файлы",
    Save: "Сохранить",
    YouShouldKnow: "Вам следует знать:",
    OnlyVideosShouldBeUploaded: "Должны загружаться только видео",
    VideoResolutionRequirement:
      "Видео должно иметь разрешение 1:2 PX или видео TikTok",
    VideoDurationRequirement: "Длительность видео должна быть до 30 секунд",
    CompanyPolicyRequirement:
      "Видео, противоречащее политике компании, не будет одобрено",
    adUploadSuccess: "Реклама успешно загружена",
    adUploadFail: "Не удалось загрузить рекламу. Пожалуйста, попробуйте позже.",
    adUploadFail2: "Ошибка при загрузке рекламы. Пожалуйста, попробуйте позже.",
    partners: "Партнеры",
    endDateError: "Дата окончания кампании должна быть позже даты начала",
    startDateValidation: "Пожалуйста, выберите дату начала",
    endDateValidation: "Пожалуйста, выберите дату окончания",
  },
  Ad_Details: {
    advertisementDetail: "Детали объявления",
    advertiserDetails: "Детали рекламодателя",
    noDataAvailable: "Данные отсутствуют",
    phone: "Телефон",
    email: "Электронная почта",
    campaignDetails: "Детали кампании",
    campaignPeriod: "Период кампании",
    from: "От",
    to: "До",
    billboard: "Биллборд",
    createdAt: "Создано",
    campaignCost: "Стоимость кампании",
    automaticCalculation: "Автоматический расчет",
    agentProposal: "Предложение агента",
    finalCost: "Итоговая стоимость",
    videoAdPreview: "Предпросмотр видеорекламы",
    date: "Дата",
    adExposure: "Показ объявления",
    totalExposure: "Общий показ",
    dataNotAvailable: "Данные отсутствуют",
    adType: "Тип объявления",
    adStatus: "Статус объявления",
  },
  Ad_Edit: {
    advertisement_update: {
      title: "Обновление объявления",
      video_title_label: "Здесь заголовок видео",
      add_type_label: "Тип объявления",
      advertiser_details_title: "Детали рекламодателя",
      select_advertiser: "Выберите рекламодателя",
      advertiser_name_label: "Имя",
      phone_number_label: "Номер телефона",
      email_label: "Электронная почта",
      campaign_details_title: "Детали кампании",
      campaign_start_date_label: "Дата начала кампании",
      campaign_end_date_label: "Дата окончания кампании",
      select_billboard_label: "Выберите биллборд *",
      automatic_calculation_label: "Автоматический расчет",
      sales_agent_proposal_label: "Предложение агента по продажам",
      upload_title: "Загрузить *",
      upload_files_text: "Загрузить файлы",
      resubmit_button_text: "Повторно отправить",
      you_should_know_title: "Вам следует знать:",
      you_should_know_text: [
        "Должны загружаться только видео",
        "Видео должно иметь разрешение 1:2 PX или видео TikTok",
        "Длительность видео должна быть до 30 секунд",
        "Видео, противоречащее политике компании, не будет одобрено",
      ],
    },
  },
  Ad_index: {
    advertisements: {
      title: "Объявления",
      tabs: {
        all: "Все",
        pending: "В ожидании",
        approved: "Одобрено",
        rejected: "Отклонено",
      },
      billboardCount: {
        totalBillboards: "Всего биллбордов",
        activeBillboards: "Активные биллборды",
        inactiveBillboards: "Неактивные биллборды",
        billboardsInMaintenance: "Биллборды на обслуживании",
      },
      addAdvertisement: {
        add: "Добавить",
        advertisements: "Объявления",
      },
      billboard: {
        title: "Биллборд",
        video: {
          src: "https://www.youtube.com/embed/0njtNjgy-cA?si=DyHFmHets3EahWqx",
          title: "Проигрыватель YouTube видео",
        },
      },
    },
    commonTable: {
      columns: {
        id: "ID",
        title: "Заголовок",
        type: "Тип",
        startDate: "Дата начала",
        endDate: "Дата окончания",
        status: "Статус",
        actions: "Действия",
        cost_forecast: "Прогноз затрат",
        ad_exposures: "Рекламные показы",
      },
      rows: {
        noData: "Данные отсутствуют",
      },
    },
  },
  Ad_main: {
    advertisements: {
      title: "Объявления",
      tabs: {
        all: "Все",
        pending: "В ожидании",
        active: "Активные",
        approved: "Одобрено",
        unapproved: "Не одобрено",
        expired: "Истекло",
      },
      addButton: {
        add: "Добавить",
      },
    },
    commonTable: {
      columns: {
        id: "ID",
        title: "Заголовок",
        type: "Тип",
        startDate: "Дата начала",
        endDate: "Дата окончания",
        status: "Статус",
        actions: "Действия",
      },
      rows: {
        noData: "Данные отсутствуют",
      },
    },
    deleteConfirmationModal: {
      open: "Открыть",
      close: "Закрыть",
      itemName: "Название элемента",
      refetch: "Перезагрузить",
    },
  },
  Ad_tabs: {
    advertisements: "Объявления",
    add: "Добавить",
    all: "Все",
    pending: "В ожидании",
    active: "Активные",
    approved: "Одобрено",
    unapproved: "Не одобрено",
    expired: "Истекло",
  },
  auth_email: {
    labels: {
      email: "Электронная почта",
      password: "Пароль",
      rememberMe: "Запомнить меня",
      submitButton: "Отправить",
      submittingButton: "Отправка...",
      showPassword: "Показать пароль",
      hidePassword: "Скрыть пароль",
      forgotPassword: "Забыли пароль?",
    },
    placeholders: {
      email: "Введите вашу электронную почту",
      password: "Введите ваш пароль",
      emailSuccess:
        "Код подтверждения успешно отправлен на ваш адрес электронной почты.",
      emailFail: "Неверные учетные данные. Попробуйте еще раз.",
    },
    errors: {
      requiredField: "Это поле обязательно для заполнения",
      invalidEmail: "Неверный формат электронной почты",
      invalidPass: "Пароль должен быть длиной не менее 8 символов.",
      invalidPhone:
        "Не удалось отправить код подтверждения. Попробуйте еще раз.",
      noUser: "Пользователь не найден.",
    },
  },
  Auth_phone: {
    Email: "Электронная почта",
    Password: "Пароль",
    "Show Password": "Показать пароль",
    "Hide Password": "Скрыть пароль",
    "Remember Me": "Запомнить меня",
    "Submitting...": "Отправка...",
    Submit: "Отправить",
    "Forgot Password?": "Забыли пароль?",
    Phone: "Телефон",
    "Send OTP": "Отправить ОТП",
    "Sending...": "Отправка...",
  },
  Auth_forgotPassword: {
    Advertisements: "Объявления",
    Add: "Добавить",
    "Forgot Password?": "Забыли пароль?",
    "Don’t worry! It happens. Please enter the email address associated with your account":
      "Не беспокойтесь! Это случается. Пожалуйста, введите адрес электронной почты, связанный с вашим аккаунтом",
    Email: "Электронная почта",
    Submit: "Отправить",
    "Send OTP": "Отправить ОТП",
    Phone: "Телефон",
    "Remember Me": "Запомнить меня",
    "Show Password": "Показать пароль",
    "Hide Password": "Скрыть пароль",
    success: "Ссылка для сброса пароля отправлена ​​на вашу почту",
  },
  Auth_index: {
    forgot_password_title: "Забыли пароль?",
    forgot_password_subtitle:
      "Не беспокойтесь! Это случается. Пожалуйста, введите адрес электронной почты, связанный с вашим аккаунтом",
    forgot_password_email_label: "Электронная почта",
    forgot_password_submit_button: "Отправить",
    login_with_email_title: "Вход с использованием электронной почты",
    login_with_phone_title: "Вход с использованием телефона",
    email_login_button: "Вход с электронной почтой",
    phone_login_button: "Вход с телефоном",
    email_placeholder: "Введите вашу электронную почту",
    send_otp_button: "Отправить ОТП",
    submit_button: "Отправить",
    show_password_button: "Показать пароль",
    hide_password_button: "Скрыть пароль",
    remember_me_label: "Запомнить меня",
    phone_label: "Телефон",
    form_error_required: "Это поле обязательно для заполнения",
    form_error_email_invalid:
      "Пожалуйста, введите действительный адрес электронной почты",
    form_error_password_length: "Пароль должен содержать не менее 6 символов",
  },
  Auth_otp: {
    forgot_password: {
      title: "Забыли пароль?",
      instruction:
        "Не беспокойтесь! Это случается. Пожалуйста, введите адрес электронной почты, связанный с вашим аккаунтом",
      email_placeholder: "Введите вашу электронную почту",
      submit_button: "Отправить",
    },
    login: {
      email: "Вход с использованием электронной почты",
      phone: "Вход с использованием телефона",
      email_login_button: "Вход с электронной почтой",
      phone_login_button: "Вход с телефоном",
    },
    otp_page: {
      title: "Проверочный код",
      otp_label: "4-значный Проверочный код",
      otp_placeholder: "ТЕКСТ",
      sign_in_button: "Войти",
      otpSuccess: "Проверочный код успешно проверен.",
      otpFail: "Не удалось проверить код подтверждения",
      otpValidation: "Введите действительный проверочный код.",
      otpResend: "Код подтверждения успешно отправлен",
      resendDisable: "Не получили код? Отправить повторно",
      sec: "сек",
      resendButton: "Отправить",
      verifyButton: "Проверка...",
    },
  },
  Auth_resetPassword: {
    ResetPassword: {
      title: "Сброс пароля",
      newPassword: "Новый пароль",
      confirmPassword: "Подтвердите пароль",
      submitButton: "Отправить",
      validationMessages: {
        passwordLength: "Требуется не менее 8 алфавитно-цифровых символов",
        passwordMismatch: "Пароли не совпадают",
      },
    },
  },
  Billboard_main: {
    Billboards: {
      title: "Биллборды",
      tabs: {
        all: "Все",
        someTab1: "Вкладка 1",
        someTab2: "Вкладка 2",
        offline: "Офлайн",
        online: "Онлайн",
        billboard_address: "Адрес билборда",
        owner: "Владелец",
        installation: "Установка",
        ads_amount: "Количество рекламных объявлений",
        ads_exposures: "Показов рекламы",
        offline_started: "Офлайн начался",
        linked_tickets: "Связанные заявки",
      },
      buttons: {
        arrowRight: "Стрелка вправо",
      },
    },
  },
  Billboard_Edit: {
    BillboardTraffic: {
      title: "Трафик биллбордов",
      placeholder: {
        billboardName: "Название биллборда",
        contactName: "Имя контакта",
        address: "Адрес",
        phoneNumber: "Номер телефона",
        email: "Электронная почта",
        remark: "Напишите здесь",
        uploadFiles: "Загрузить файлы",
      },
      labels: {
        billboardLocation: "Расположение биллборда",
        contactDetails: "Детали контакта",
        remarks: "Замечания",
        uploadGalleryImages: "Загрузить изображения галереи",
        advertisingContent: "Рекламный контент",
        amountAdsDisplayed: "Количество показанных объявлений",
        spotsPerAdPerDay: "Количество показов на объявление в день",
        installationDate: "Дата установки",
        nextPayment: "Следующий платеж",
        monthlyPayment: "Ежемесячный платеж",
      },
      messages: {
        imageUploadSuccess: "Загрузка изображения прошла успешно",
        remarkSuccess: "Примечание успешно создано!..",
        error: "Ошибка!..",
      },
      buttons: {
        save: "Сохранить",
      },
    },
  },
  Billboard_index: {
    typography: {
      billboards: "Биллборды",
      installation_date: "Дата установки",
      next_payment: "Следующий платеж",
      edit_billboard: "Редактировать биллборд",
      billboard_ads: "Реклама на биллбордах",
      views_and_impressions: "Просмотры и впечатления",
      offline_heat_map: "Карта плотности офлайн",
      billboard_location: "Расположение биллборда",
      interaction: "Взаимодействие",
      qr_code: "QR-код",
      remarks: "Замечания",
      payments: "Платежи",
      spot_ad_daily: "Ежедневное пятно рекламы",
      gallery: "Галерея",
      no_data_available: "Данные отсутствуют",
      click_here_to_open_link: "Нажмите здесь, чтобы открыть ссылку",
      download: "Скачать",
      rushHour: "Сегодня час пик",
      week: "Эта неделя",
      month: "Этот месяц",
      year: "Этот год",
      activeAd: "Активные объявления",
    },
    select: {
      no_address_available: "Адрес недоступен",
    },
    date_format: {
      date: "Дата",
    },
    button: {
      download: "Скачать",
      sizes: ["300*300", "400*400", "500*500", "1000*1000", "2000*2000"],
    },
    modal: {
      social: {
        facebook: "Facebook",
        whatsapp: "WhatsApp",
        linkedin: "LinkedIn",
        email: "Электронная почта",
        tel: "Телефон",
      },
    },
    interaction: {
      qr_code: "QR-код",
    },
    formats: {
      amount: "Сумма",
    },
  },
  Billboard_mapView: {
    errors: {
      coordinatesUnavailable: "Координаты недоступны",
      mapContainerUnavailable: "Контейнер карты недоступен",
    },
    messages: {
      noLocationAvailable: "Местоположение недоступно",
    },
  },
  Dashboard: {
    components: {
      customLoader: "Загрузка...",
      billboardPlacement: "Размещение биллбордов",
      dashboard: "Панель управления",
      now: "Сейчас",
      lastUpdate: "Последнее обновление",
      dailyStatistics: "Ежедневная статистика",
      rushHour: "Час пик",
      impressions: "Впечатления",
      map: "Карта",
      goToMap: "Перейти к карте",
      impressionsTitle: "Впечатления",
      billboardStatus: "Статус биллборда",
      clearAll: "Очистить всё",
      respond: "Отвечать",
      noMessage: "Нет новых сообщений",
      notification: "Уведомления",
      read: "Читать",
      noNotification: "Нет новых уведомлений",
      online: "В сети",
      offline: "Не в сети",
      profile: "Профиль",
      logout: "Выйти",
    },
  },
  modal: {
    components: {
      dialog: {
        deleteConfirmation: "Подтверждение удаления",
        close: "Закрыть",
        areYouSure: "Вы уверены, что хотите удалить?",
        cancel: "Отмена",
        delete: "Удалить",
        deleteSuccess: "Объявление успешно удалено.",
        deleteFail:
          "Не удалось удалить объявление. Пожалуйста, попробуйте еще раз.",
      },
    },
  },
  profile: {
    components: {
      userProfile: {
        userProfile: "Профиль пользователя",
        name: "Полное имя",
        email: "Электронная почта",
        phoneNumber: "Номер телефона",
        country: "Страна",
        city: "Город",
        address: "Адрес",
        zipCode: "Почтовый индекс",
        save: "Сохранить",
        edit: "Редактировать",
      },
      changePassword: {
        changePassword: "Изменить пароль",
        newPassword: "Новый пароль",
        confirmPassword: "Подтвердите пароль",
        submitting: "Отправка...",
        save: "Сохранить",
        passValidation: "Пароль должен быть длиной не менее 8 символов.",
        confPassValidation: "Пароли не совпадают",
      },
      common: {
        cancel: "Отмена",
        delete: "Удалить",
        areYouSure: "Вы уверены, что хотите удалить?",
        updateSuccess: "Данные пользователя успешно обновлены",
        passSuccess: "Пароль успешно изменен",
      },
    },
  },
  Ticket_add: {
    add_ticket: {
      title: "Добавить билет",
      labels: {
        ticket_name: "Название билета",
        ticket_description: "Описание билета",
        billboard_name: "Название биллборда",
        closing_date: "Дата закрытия",
        ticket_type: "Тип билета",
        ticket_subtype: "Подтип билета",
        ticket_assignee: "Назначенный на билет",
        ticket_followers: "Подписчики билета",
      },
      placeholders: {
        ticket_name: "Название билета",
        ticket_description: "Описание",
        billboard_name: "Выберите биллборд",
        ticket_type: "Выберите тип билета",
        ticket_subtype: "Выберите подтип билета",
      },
      buttons: {
        approve: "Одобрить",
        save: "Сохранить",
        cancel: "Отменить",
      },
      errors: {
        ticket_name: "Требуется название билета",
        description: "Требуется описание",
        billboard_name: "Требуется название биллборда",
        closing_date: "Требуется дата закрытия",
        ticket_type: "Требуется тип билета",
        ticket_subtype: "Требуется подтип билета",
        ticket_assignee: "Требуется назначенный на билет",
        ticket_followers: "Требуются подписчики билета",
        duplicateName: "Это имя тикета уже занято",
        ticketSuccess: "Билет успешно создан",
      },
      admin_roles: ["Админ", "Суперадмин"],
    },
  },
  Ticket_allTicket: {
    search: "Поиск",
    billboard_sort: "Сортировка по биллбордам",
    ticket_id: "ID билета:",
    created_at: "{date}",
    ticket_name: "{name}",
    message_prefix: "{name}: {message}",
    tickets_title: "Билеты",
    messages_tab: "Сообщения",
    details_tab: "Детали",
  },
  Ticket_details: {
    "Add Ticket": "Добавить билет",
    "Ticket Name": "Название билета",
    "Ticket Description": "Описание билета",
    "Billboard Name": "Название биллборда",
    "Closing Date": "Дата закрытия",
    "Ticket Type": "Тип билета",
    "Ticket Subtype": "Подтип билета",
    "Ticket Assignee": "Назначенный на билет",
    "Ticket Followers": "Подписчики билета",
    Search: "Поиск",
    Messages: "Сообщения",
    Details: "Детали",
    Less: "Меньше",
    More: "Больше",
    "Ticket Assignee:": "Назначенный на билет:",
    creationDate: "Дата создания:",
  },
  Ticket_index: {
    tickets: {
      title: "Билеты",
      tabs: {
        all: "Все билеты",
        approve: "Одобренные билеты",
        unapprove: "Неодобренные билеты",
        close: "Закрытые билеты",
        disapprove: "Отклоненные билеты",
        archieve: "Архивные билеты",
        inProgress: "Билеты в процессе",
        completed: "Заполненные билеты",
      },
      addButton: "Добавить",
      noTicketsMessage: "Билеты отсутствуют.",
      ticketCounts: {
        approve: "Одобрить билет",
        unapprove: "Не одобрить билет",
        close: "Закрыть билет",
        disapprove: "Отклонить билет",
        archieve: "Архивировать билет",
      },
      drawer: {
        title: "Добавить билет",
      },
    },
    common: {
      loaders: {
        custom: "Загрузка...",
        fidgetSpinner: "Загрузка...",
      },
      buttons: {
        approve: "Одобрить",
        disapprove: "Отклонить",
        delete: "Удалить",
      },
      labels: {
        search: "Поиск",
        billboardSort: "Сортировка по биллбордам",
      },
      messages: {
        more: "Больше",
        less: "Меньше",
      },
      placeholders: {
        search: "Поиск...",
      },
      statuses: {
        approve: "Одобрено",
        close: "Закрыто",
      },
      ticketDetails: {
        assignee: "Назначенный на билет",
        followers: "Подписчики билета",
      },
    },
  },
  Ticket_message: {
    header: {
      tickets: "Билеты",
    },
    tabs: {
      approve: "Одобрить",
      unapprove: "Не одобрить",
      close: "Закрыть",
      disapprove: "Отклонить",
      archieve: "Архивировать",
    },
    buttons: {
      add: "Добавить",
    },
    messages: {
      noTickets: "Билеты отсутствуют.",
      uploadingFile: "Загрузка файла...",
      typeHere: "Напишите здесь...",
      cannotChatArchived: "Вы не можете общаться по архивированному билету.",
      cannotChatClosed: "Вы не можете общаться по закрытому билету.",
      cannotChatDisapproved: "Вы не можете общаться по отклоненному билету.",
      cannotChatUnapproved: "Вы не можете общаться по неодобренному билету.",
      uploadFail: "Не удалось загрузить файл.",
      sizeExceed: "Размер файла превышает 15 МБ",
    },
  },
};
