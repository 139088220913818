import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormGroup,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as Logo } from "../../icon/logo.svg";
import Language from "../../components/select-language";
import { isStringValue } from "../../utility-files/data-util/DataHandler";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import * as global from "../../constant/global";
import alertShow from "../../utility-files/custom-hooks/useAlert";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const [forgetData, setForgetData] = useState({ email: "" });
  const [pending, setPending] = useState(false);
  const [error, setError] = useState("");
  const [messageSucc, setMessageSucc] = useState("");

  const forgetPassword = async () => {
    setPending(true);
    setError("");
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("email", forgetData.email);
      formDataToSend.append(
        "resetPath",
        `${window.location.origin}/reset-Password/<token>`
      );
      let request = getRequestForApi(
        global.FORGET_PASSWORD,
        formDataToSend,
        methodType.POST
      );
      const response = await callHttpRequest(request);
      if (response?.status === 200 || response?.status === 201) {
        toast.success(t("Auth_forgotPassword.success"));
        // setForgetData({ email: "" });
      } else {
        console.error("Unexpected response status:", response.status);
        setError("Unexpected response status: " + response.status);
      }
    } catch (err) {
      setError(err.message || "An error occurred");
      console.error("Error occurred:", err);
    } finally {
      setPending(false);
    }
  };

  const handleSubmit = () => {
    forgetPassword();
  };

  return (
    <>
      {pending && <CustomLoader />}
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", lg: "end" },
          }}
        >
          <Language />
        </Box>
        <Box
          sx={{
            maxWidth: "520px",
            mx: "auto",
            "@media(max-width:992px)": { width: "100%" },
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              mb: { xs: 2, lg: 5 },
              "& svg": {
                marginTop: "25px",
                width: "424px",
                height: "120px",
                "@media(max-width:768px)": {
                  width: "150px",
                  height: "auto",
                },
              },
            }}
          >
            <Link to="/">
              {" "}
              <Logo />
            </Link>
          </Box>

          <Card elevation={0} sx={{ borderRadius: "10px" }}>
            <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "#001D38",
                  textAlign: "center",
                  mb: 3,
                  "@media(max-width:992px)": {
                    fontSize: "24px",
                  },
                }}
              >
                {t("Auth_forgotPassword.Forgot Password?")}
              </Typography>
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  textAlign: "center",
                  color: "rgba(0, 29, 56, 1)",
                  mb: 3,
                }}
              >
                {t(
                  "Auth_forgotPassword.Don’t worry! It happens. Please enter the email address associated with your account"
                )}
              </Box>
              <Stack direction={"column"} spacing={3}>
                <FormGroup>
                  <FormLabel>{t("Auth_forgotPassword.Email")}</FormLabel>
                  <TextField
                    type="email"
                    size="small"
                    value={forgetData.email}
                    onChange={(e) =>
                      setForgetData({
                        email: e.target.value,
                      })
                    }
                    placeholder={t("auth_email.placeholders.email")}
                  />
                </FormGroup>

                {error && (
                  <Typography color="error" sx={{ textAlign: "center" }}>
                    {error}
                  </Typography>
                )}

                <Button
                  variant="contained"
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
                    color: "#fff !important",
                  }}
                  onClick={handleSubmit}
                >
                  {t("Auth_forgotPassword.Submit")}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
}
