import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./line-chart.css";
import { useSelector } from "react-redux";


export default function LineChart() {
  const lineHour = useSelector((state) => state.HomeData);
  const Impressions = lineHour?.hour_impression || {};
  const impressionKey = Object.keys(Impressions);
  const impressionData = Object.values(Impressions);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const hoursValLength = impressionData.reduce(
    (acc, curr) => (curr > 0 ? acc + 1 : acc),
    0
  );

  useEffect(() => {
    if (impressionData.length > 0) {
      const newSeries = [
        {
          name: "View/Impressions",
          data: Array.isArray(impressionData)
            ? impressionData.slice(0, hoursValLength)
            : [],
          color: "#ECB309",
        },
      ];
      if (!areSeriesEqual(series, newSeries)) {
        setSeries(newSeries);
      }
    }
  }, [impressionData, series]);

  useEffect(() => {
    // Update chart options when language changes
    const rtlEnabled = localStorage.getItem("i18nextLng") === "ar";
    setOptions({
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false, // Disable zooming
        },
        selection: {
          enabled: false, // Disable selection (click-and-drag zooming)
        },
        rtl: rtlEnabled,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 0,
          opacityTo: 0,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      grid: {
        show: true,
        borderColor: "#494130",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          colors: undefined,
          opacity: 0.5,
        },
        column: {
          colors: undefined,
          opacity: 0.5,
        },
        padding: {
          left: rtlEnabled ? 10 : 10,
          right: rtlEnabled ? 5 : 10,
        },
      },
      markers: {
        size: 8,
        colors: "#F2683A",
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 1,
        dashArray: 0,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories:
          Array.isArray(impressionKey) && impressionKey.length > 0
            ? impressionKey.slice(0, hoursValLength)
            : [],
        labels: {
          style: {
            fontSize: "14px",
            fontFamily: rtlEnabled
              ? `"Cairo", sans-serif`
              : `"Manrope", sans-serif`,
            colors: [
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
              "#687588",
            ],
          },
          align: rtlEnabled ? "right" : "left",
          offsetX: rtlEnabled ? -5 : 0,
          offsetY: rtlEnabled ? 30 : 5,
          dir: rtlEnabled ? "rtl" : "ltr",
        },
      },
      yaxis: {
        opposite: rtlEnabled,
        labels: {
          offsetX: rtlEnabled ? -40 : -17, // Add horizontal space

          style: {
            fontSize: "14px",
            colors: "#687588",
            fontFamily: rtlEnabled
              ? `"Cairo", sans-serif`
              : `"Manrope", sans-serif`,
          },
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
        style: {
          colors: "#FF0000",
          fontFamily: rtlEnabled
            ? `"Cairo", sans-serif`
            : `"Manrope", sans-serif`,
        },
      },
    });
  }, [hoursValLength, impressionKey]); // Update the chart options when language changes

  const areSeriesEqual = (series1, series2) => {
    if (series1.length !== series2.length) return false;
    for (let i = 0; i < series1.length; i++) {
      if (
        series1[i].name !== series2[i].name ||
        series1[i].color !== series2[i].color ||
        series1[i].data.length !== series2[i].data.length
      ) {
        return false;
      }
      for (let j = 0; j < series1[i].data.length; j++) {
        if (series1[i].data[j] !== series2[i].data[j]) return false;
      }
    }
    return true;
  };

  if (!lineHour) {
    return null; // or you can render a loading indicator
  }

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
        width="100%"
      />
    </>
  );
}
