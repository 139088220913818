import { Outlet } from "react-router-dom";
import Login from "./page/auth";
import Otp from "./page/auth/Otp";
import ForgotPassword from "./page/auth/forgot-password";
import ResetPassword from "./page/auth/reset-password";
import Sidebar from "./components/sidebar";
import Profile from "./page/profile/userProfile";
import Dashboard from "./page/dashboard/dasboard";
import BillBoard from "./page/billboard";
import Tickets from "./page/ticket";
import Details from "./page/billboard/details/index";
import EditBillboard from "./page/billboard/details/EditBillboard";
import Advertisement from "./page/advertisement";
import AdDetails from "./page/advertisement/details";
import AddAvertisement from "./page/advertisement/details/Add";
import EditDetails from "./page/advertisement/details/edit-details";
import Edit from "./page/advertisement/details/Edit";
import PrivateRoute from "./constant/privateRoutes/privateRoutes";

const routes = [
  {
    path: "/",
    element: (
      <div className="login-bg">
        <Outlet />
      </div>
    ),
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/otp",
        element: <Otp />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/reset-password/:token",
        element: <ResetPassword />,
      },
    ],
  },

  {
    element: <Sidebar />,
    children: [
      {
        path: "/profile",
        element: (
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        ),
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/billboard",
        element: <BillBoard />,
      },
      {
        path: "/billboard/details",
        element: <Details />,
      },
      {
        path: "/billboard/details/edit",
        element: <EditBillboard />,
      },
      {
        path: "/ticket",
        element: <Tickets />,
      },
      {
        path: "/advertisement",
        element: <Advertisement />,
      },
      // {
      //   path: "/advertisement/detail/:id",
      //   element: <AdDetails />,
      // },
      {
        path: "/advertisement/detail/add",
        element: <AddAvertisement />,
      },
      {
        path: "/advertisement/detail/:id",
        element: <EditDetails />,
      },
      {
        path: "/advertisement/edit/:id",
        element: <Edit />,
      },
    ],
  },
];
export default routes;
