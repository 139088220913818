import { useEffect, useState } from "react";

// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button() {
  const [fontFamily, setFontFamily] = useState('"Manrope", sans-serif');

  useEffect(() => {
    const language = localStorage.getItem("i18nextLng");
    if (language === "ar") {
      setFontFamily('"Cairo", sans-serif');
    } else {
      setFontFamily('"Manrope", sans-serif');
    }
  }, []);

  const containedStyle = {
    "&.MuiButton-contained": {
      backgroundColor: "#D7E5F6",
      color: "#222943",
      borderRadius: "5px",
      boxShadow: "none",
      textTransform: "capitalize",

      "&:hover": {
        background:
          "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
        color: "#fff",
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        backgroundColor: "#B8BABE !important",
        color: "#FFFFFF !important",
      },
    },
  };

  const outlinedStyle = {
    "&.MuiButton-outlined": {
      borderRadius: "12px",
      boxShadow: "none",
      border: "1px solid #D8E0F0",
      color: "#7D8592",
      textTransform: "capitalize",

      "&:hover": { background: "#fff" },
      "&.Mui-disabled": {
        backgroundColor: "transparent",
        border: "1px solid #D8E0F0 !important",
        color: "#7D8592 !important",
      },
    },
  };

  return {
    MuiButton: {
      defaultProps: {
        disableElevation: false,
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: "18px",
          fontFamily: fontFamily,
          borderRadius: "5px",
          color: "#222943",
          "@media(max-width:992px)": {
            fontSize: "14px",
          },
        },
        contained: {
          ...containedStyle,
        },
        outlined: {
          ...outlinedStyle,
        },
      },
    },
  };
}
