import { Home } from '@mui/icons-material'
import { createSlice } from '@reduxjs/toolkit'

export const reduxSlice = createSlice({
    name: 'BillBoard',
    initialState: {
        BillBoardData:{},
        billBoardDetail:{},
        HomeData:{},
        userData:{}
   },
    reducers: {
        setBillBoardData: (state, action) => {
            state.BillBoardData = action.payload
        },
         billBoardDetail :(state,action)=>{
            state.billBoardDetail = action.payload
         },
         HomeData:(state,action)=>{
            state.HomeData =action.payload       
         },
         userData:(state,action)=>{
            state.userData=action.payload
         }
      
    },
})

// Action creators are generated for each case reducer function
export const {  setBillBoardData ,billBoardDetail,HomeData,userData} = reduxSlice.actions

export default reduxSlice.reducer