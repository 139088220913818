export const en = {
  Ad_Add: {
    CustomLoader: "Loading...",
    Uploader: "Uploader",
    VideoTitleHere: "Video title here",
    AddType: "Ad Type",
    Local: "Local",
    Global: "Global",
    Partner: "Partner",
    AdvertiserDetails: "Advertiser Details",
    SelectAdvertiser: "Select Advertiser",
    Name: "Name",
    PhoneNumber: "Phone Number",
    PhoneNumberPlaceholder: "Phone number type here",
    CampaignDetails: "Campaign Details",
    CampaignStartDate: "Campaign Start Date",
    CampaignEndDate: "Campaign End Date",
    SelectABillboard: "Select a Billboard",
    AutomaticCalculation: "Automatic Calculation",
    SalesAgentProposal: "Sales agent proposal",
    Upload: "Upload",
    UploadFiles: "Upload files",
    Save: "Save",
    YouShouldKnow: "You should know:",
    OnlyVideosShouldBeUploaded: "Only videos should be uploaded",
    VideoResolutionRequirement:
      "The video should have a resolution of 1:2 PX Or TikTok video",
    VideoDurationRequirement: "The video should be up to 30 seconds",
    CompanyPolicyRequirement:
      "Video that contradicts company policy will not be approved",
    adUploadSuccess: "Ad uploaded successfully",
    adUploadFail: "Failed to upload ad. Please try again later.",
    adUploadFail2: "Error uploading ad. Please try again later.",
    partners: "Partners",
    endDateError: "Campaign End date should be after the Start date",
    startDateValidation: "Please select start date",
    endDateValidation: "Please select end date",
  },

  Ad_Details: {
    advertisementDetail: "Advertisement Detail",
    advertiserDetails: "Advertiser Details",
    noDataAvailable: "No data available",
    phone: "Phone",
    email: "Email",
    campaignDetails: "Campaign details",
    campaignPeriod: "Campaign period",
    from: "From",
    to: "To",
    billboard: "Billboard",
    createdAt: "Created At",
    campaignCost: "Campaign cost",
    automaticCalculation: "Automatic calculation",
    agentProposal: "Agent proposal",
    finalCost: "Final cost",
    videoAdPreview: "Video Ad Preview",
    date: "Date",
    adExposure: "Ad Exposure",
    totalExposure: "Total Exposure",
    dataNotAvailable: "Data is not available",
    adType: "Type",
    adStatus: "Status",
  },
  Ad_Edit: {
    advertisement_update: {
      title: "AD Update",
      video_title_label: "Video title here",
      add_type_label: "Add Type",
      advertiser_details_title: "Advertiser Details",
      select_advertiser: "Select Advertiser",
      advertiser_name_label: "Name",
      phone_number_label: "Phone Number",
      email_label: "Email",
      campaign_details_title: "Campaign Details",
      campaign_start_date_label: "Campaign Start Date",
      campaign_end_date_label: "Campaign End Date",
      select_billboard_label: "Select a Billboard *",
      automatic_calculation_label: "Automatic Calculation",
      sales_agent_proposal_label: "Sales agent proposal",
      upload_title: "Upload *",
      upload_files_text: "Upload files",
      resubmit_button_text: "Resubmit",
      you_should_know_title: "You should know:",
      you_should_know_text: [
        "Only videos should be uploaded",
        "The video should have a resolution of 1:2 PX Or TikTok video",
        "The video should be up to 30 seconds",
        "Video that contradicts company policy will not be approved",
      ],
    },
  },
  Ad_index: {
    advertisements: {
      title: "Advertisements",
      tabs: {
        all: "All",
        pending: "Pending",
        approved: "Approved",
        rejected: "Rejected",
      },
      billboardCount: {
        totalBillboards: "Total Billboards",
        activeBillboards: "Active Billboards",
        inactiveBillboards: "Inactive Billboards",
        billboardsInMaintenance: "Billboards in Maintenance",
      },
      addAdvertisement: {
        add: "Add",
        advertisements: "Advertisements",
      },
      billboard: {
        title: "Billboard",
        video: {
          src: "https://www.youtube.com/embed/0njtNjgy-cA?si=DyHFmHets3EahWqx",
          title: "YouTube video player",
        },
      },
    },
    commonTable: {
      columns: {
        id: "ID",
        title: "Title",
        type: "Type",
        startDate: "Start Date",
        endDate: "End Date",
        status: "Status",
        actions: "Actions",
        cost_forecast: "Cost Forecast",
        ad_exposures: "AD exposures",
      },
      rows: {
        noData: "No Data Available",
      },
    },
  },
  Ad_main: {
    advertisements: {
      title: "Advertisements",
      tabs: {
        all: "All",
        pending: "Pending",
        active: "Active",
        approved: "Approved",
        unapproved: "Unapproved",
        expired: "Expired",
      },
      addButton: {
        add: "Add",
      },
    },
    commonTable: {
      columns: {
        id: "ID",
        title: "Title",
        type: "Type",
        startDate: "Start Date",
        endDate: "End Date",
        status: "Status",
        actions: "Actions",
      },
      rows: {
        noData: "No data available",
      },
    },
    deleteConfirmationModal: {
      open: "Open",
      close: "Close",
      itemName: "Item Name",
      refetch: "Refetch",
    },
  },
  Ad_tabs: {
    advertisements: "Advertisements",
    add: "Add",
    all: "All",
    pending: "Pending",
    active: "Active",
    approved: "Approved",
    unapproved: "Unapproved",
    expired: "Expired",
  },
  auth_email: {
    labels: {
      email: "Email",
      password: "Password",
      rememberMe: "Remember Me",
      submitButton: "Submit",
      submittingButton: "Submitting...",
      showPassword: "Show Password",
      hidePassword: "Hide Password",
      forgotPassword: "Forgot Password?",
    },
    placeholders: {
      email: "Enter your email",
      password: "Enter your password",
      emailSuccess: "Verification code sent to your email successfully.",
      emailFail: "Invalid credentials. Please try again.",
    },
    errors: {
      requiredField: "This field is required",
      invalidEmail: "Invalid email address format",
      invalidPass: "Password must be at least 8 characters long",
      invalidPhone: "Failed to send verification code. Please try again.",
      noUser: "User not found.",
    },
  },
  Auth_phone: {
    Email: "Email",
    Password: "Password",
    "Show Password": "Show Password",
    "Hide Password": "Hide Password",
    "Remember Me": "Remember Me",
    "Submitting...": "Submitting...",
    Submit: "Submit",
    "Forgot Password?": "Forgot Password?",
    Phone: "Phone",
    "Send OTP": "Send OTP",
    "Sending...": "Sending...",
  },
  Auth_forgotPassword: {
    Advertisements: "Advertisements",
    Add: "Add",
    "Forgot Password?": "Forgot Password?",
    "Don’t worry! It happens. Please enter the email address associated with your account":
      "Don’t worry! It happens. Please enter the email address associated with your account",
    Email: "Email",
    Submit: "Submit",
    "Send OTP": "Send OTP",
    Phone: "Phone",
    "Remember Me": "Remember Me",
    "Show Password": "Show Password",
    "Hide Password": "Hide Password",
    success:"Reset password link sent to your mail",
  },
  Auth_index: {
    forgot_password_title: "Forgot Password?",
    forgot_password_subtitle:
      "Don’t worry! It happens. Please enter the email address associated with your account",
    forgot_password_email_label: "Email",
    forgot_password_submit_button: "Submit",
    login_with_email_title: "Login With E-Mail",
    login_with_phone_title: "Login With Phone",
    email_login_button: "Email Login",
    phone_login_button: "Phone Login",
    email_placeholder: "Enter Your Email",
    send_otp_button: "Send OTP",
    submit_button: "Submit",
    show_password_button: "Show Password",
    hide_password_button: "Hide Password",
    remember_me_label: "Remember Me",
    phone_label: "Phone",
    form_error_required: "This field is required",
    form_error_email_invalid: "Please enter a valid email address",
    form_error_password_length: "Password must be at least 6 characters long",
  },
  Auth_otp: {
    forgot_password: {
      title: "Forgot Password?",
      instruction:
        "Don’t worry! It happens. Please enter the email address associated with your account",
      email_placeholder: "Enter Your Email",
      submit_button: "Submit",
    },
    login: {
      email: "Login With E-Mail",
      phone: "Login With Phone",
      email_login_button: "Email Login",
      phone_login_button: "Phone Login",
    },
    otp_page: {
      title: "Verification code",
      otp_label: "4-digit Verification code",
      otp_placeholder: "TEXT",
      sign_in_button: "Sign In",
      otpSuccess: "Verification code verified successfully.",
      otpFail: "Failed to verify verification code",
      otpValidation: "Please enter a valid verification code ",
      otpResend: "Verification code sent successfully",
      resendDisable: "Didn’t get the code? Resend in",
      sec: "sec",
      resendButton: "Resend",
      verifyButton: "Verifying...",
    },
  },
  Auth_resetPassword: {
    ResetPassword: {
      title: "Reset Password",
      newPassword: "New Password",
      confirmPassword: "Confirm Password",
      submitButton: "Submit",
      validationMessages: {
        passwordLength: "At least 8 alphanumeric characters required",
        passwordMismatch: "Passwords do not match",
      },
    },
  },
  Billboard_main: {
    Billboards: {
      title: "Billboards",
      tabs: {
        all: "All",
        someTab1: "Some Tab 1",
        someTab2: "Some Tab 2",
        offline: "Offline",
        online: "Online",
        billboard_address: "Billboard Address",
        owner: "Owner",
        installation: "Installation",
        ads_amount: "ADS Amount",
        ads_exposures: "ADS Exposures",
        offline_started: "Offline Started",
        linked_tickets: "Linked Tickets",
      },
      buttons: {
        arrowRight: "Arrow Right",
      },
    },
  },
  Billboard_Edit: {
    BillboardTraffic: {
      title: "Billboard Traffic",
      placeholder: {
        billboardName: "Billboard Name",
        contactName: "Contact Name",
        address: "Address",
        phoneNumber: "Phone Number",
        email: "Email",
        remark: "Type here",
        uploadFiles: "Upload files",
      },
      labels: {
        billboardLocation: "Billboard Location",
        contactDetails: "Contact Details",
        remarks: "Remarks",
        uploadGalleryImages: "Upload gallery images",
        advertisingContent: "Advertising Content",
        amountAdsDisplayed: "Amount ADS displayed",
        spotsPerAdPerDay: "Spots per AD per day",
        installationDate: "Installation Date",
        nextPayment: "Next Payment",
        monthlyPayment: "Monthly payment",
      },
      messages: {
        imageUploadSuccess: "Image upload successFully",
        remarkSuccess: "Create remark  successfully!..",
        error: "Error!..",
      },
      buttons: {
        save: "Save",
      },
    },
  },
  Billboard_index: {
    typography: {
      billboards: "Billboards",
      installation_date: "Installation Date",
      next_payment: "Next Payment",
      edit_billboard: "Edit Billboard",
      billboard_ads: "Billboard Ads",
      views_and_impressions: "Views and Impressions",
      offline_heat_map: "Offline Heat Map",
      billboard_location: "Billboard Location",
      interaction: "Interaction",
      qr_code: "QR Code",
      remarks: "Remarks",
      payments: "Payments",
      spot_ad_daily: "SpotAdDaily",
      gallery: "Gallery",
      no_data_available: "No Data Available",
      click_here_to_open_link: "Click here to open link",
      download: "Download",
      rushHour: "Today Rush Hour",
      week: "This Week",
      month: "This Month",
      year: "This Year",
      activeAd: "Active Ads",
    },
    select: {
      no_address_available: "No Address Available",
    },
    date_format: {
      date: "Date",
    },
    button: {
      download: "Download",
      sizes: ["300 *300", "400*400", "500*500", "1000*1000", "2000*2000"],
    },
    modal: {
      social: {
        facebook: "Facebook",
        whatsapp: "WhatsApp",
        linkedin: "LinkedIn",
        email: "Email",
        tel: "Tel",
      },
    },
    interaction: {
      qr_code: "QR Code",
    },
    formats: {
      amount: "Amount",
    },
  },
  Billboard_mapView: {
    errors: {
      coordinatesUnavailable: "Coordinates not available",
      mapContainerUnavailable: "Map container is not available",
    },
    messages: {
      messages: "No Location Available",
    },
  },
  Dashboard: {
    components: {
      customLoader: "Loading...",
      billboardPlacement: "Billboard Placement",
      dashboard: "Dashboard",
      now: "Now",
      lastUpdate: "Last update",
      dailyStatistics: "Daily Statistics",
      rushHour: "Rush Hour",
      impressions: "Impressions",
      map: "Map",
      goToMap: "Go To Map",
      impressionsTitle: "Impressions",
      billboardStatus: "Billboard Status",
      clearAll: "Clear All",
      respond: "Respond",
      noMessage: "No new messages",
      notification: "Notifications",
      read: "Read",
      noNotification: "No new notification",
      online: "Online",
      offline: "Offline",
      profile: "Profile",
      logout: "Logout",
    },
  },
  modal: {
    components: {
      dialog: {
        deleteConfirmation: "Delete Confirmation",
        close: "Close",
        areYouSure: "Are you sure you want to delete?",
        cancel: "Cancel",
        delete: "Delete",
        deleteSuccess: "Ad successfully deleted.",
        deleteFail: "Failed to delete ad. Please try again.",
      },
    },
  },
  profile: {
    components: {
      userProfile: {
        userProfile: "User Profile",
        name: "FullName",
        email: "Email",
        phoneNumber: "Phone number",
        country: "Country",
        city: "City",
        address: "Address",
        zipCode: "ZipCode",
        save: "Save",
        edit: "Edit",
      },
      changePassword: {
        changePassword: "Change Password",
        newPassword: "New Password",
        confirmPassword: "Confirm Password",
        submitting: "Submitting...",
        save: "Save",
        passValidation: "Password must be at least 8 characters long",
        confPassValidation: "Passwords do not match",
      },
      common: {
        cancel: "Cancel",
        delete: "Delete",
        areYouSure: "Are you sure you want to delete?",
        updateSuccess: "User details updated successfully",
        passSuccess: "Password change successfully",
      },
    },
  },
  Ticket_add: {
    add_ticket: {
      title: "Add Ticket",
      labels: {
        ticket_name: "Ticket Name",
        ticket_description: "Ticket Description",
        billboard_name: "Billboard Name",
        closing_date: "Closing Date",
        ticket_type: "Ticket Type",
        ticket_subtype: "Ticket Subtype",
        ticket_assignee: "Ticket Assignee",
        ticket_followers: "Ticket Followers",
      },
      placeholders: {
        ticket_name: "Ticket Name",
        ticket_description: "Description",
        billboard_name: "Select Billboard",
        ticket_type: "Select Ticket Type",
        ticket_subtype: "Select Ticket Subtype",
      },
      buttons: {
        approve: "Approve",
        save: "Save",
        cancel: "Cancel",
      },
      errors: {
        ticket_name: "Ticket name is required",
        description: "Description is required",
        billboard_name: "Billboard name is required",
        closing_date: "Closing date is required",
        ticket_type: "Ticket type is required",
        ticket_subtype: "Ticket subtype is required",
        ticket_assignee: "Ticket assignee is required",
        ticket_followers: "Ticket followers are required",
        duplicateName: "This ticket name is already taken",
        ticketSuccess: "Ticket Created Successfully",
      },
      admin_roles: ["Admin", "SuperAdmin"],
    },
  },
  Ticket_allTicket: {
    search: "Search",
    billboard_sort: "Billboard Sort",
    ticket_id: "Ticket Id:",
    created_at: "{date}",
    ticket_name: "{name}",
    message_prefix: "{name}: {message}",
    tickets_title: "Tickets",
    messages_tab: "Messages",
    details_tab: "Details",
  },
  Ticket_details: {
    "Add Ticket": "Add Ticket",
    "Ticket Name": "Ticket Name",
    "Ticket Description": "Ticket Description",
    "Billboard Name": "Billboard Name",
    "Closing Date": "Closing Date",
    "Ticket Type": "Ticket Type",
    "Ticket Subtype": "Ticket Subtype",
    "Ticket Assignee": "Ticket Assignee",
    "Ticket Followers": "Ticket Followers",
    Search: "Search",
    Messages: "Messages",
    Details: "Details",
    Less: "Less",
    More: "More",
    "Ticket Assignee:": "Ticket Assignee:",
    creationDate: "Creation Date:",
  },
  Ticket_index: {
    tickets: {
      title: "Tickets",
      tabs: {
        all: "All Tickets",
        approve: "Approved Tickets",
        unapprove: "Unapproved Tickets",
        close: "Closed Tickets",
        disapprove: "Disapproved Tickets",
        archieve: "Archived Tickets",
        inProgress: "In Progress Tickets",
        completed: "Completed Tickets",
      },
      addButton: "Add",
      noTicketsMessage: "No tickets available.",
      ticketCounts: {
        approve: "Approve Ticket",
        unapprove: "Unapprove Ticket",
        close: "Close Ticket",
        disapprove: "Disapprove Ticket",
        archieve: "Archive Ticket",
      },
      drawer: {
        title: "Add Ticket",
      },
    },
    common: {
      loaders: {
        custom: "Loading...",
        fidgetSpinner: "Loading...",
      },
      buttons: {
        approve: "Approve",
        disapprove: "Disapprove",
        delete: "Delete",
      },
      labels: {
        search: "Search",
        billboardSort: "Billboard Sort",
      },
      messages: {
        more: "More",
        less: "Less",
      },
      placeholders: {
        search: "Search...",
      },
      statuses: {
        approve: "Approved",
        close: "Closed",
      },
      ticketDetails: {
        assignee: "Ticket Assignee",
        followers: "Ticket Followers",
      },
    },
  },
  Ticket_message: {
    header: {
      tickets: "Tickets",
    },
    tabs: {
      approve: "Approve",
      unapprove: "Unapprove",
      close: "Close",
      disapprove: "Disapprove",
      archieve: "Archieve",
    },
    buttons: {
      add: "Add",
    },
    messages: {
      noTickets: "No tickets available.",
      uploadingFile: "Uploading File...",
      typeHere: "Type here...",
      cannotChatArchived: "You cannot chat on archived ticket.",
      cannotChatClosed: "You cannot chat on closed ticket.",
      cannotChatDisapproved: "You cannot chat on disapproved ticket.",
      cannotChatUnapproved: "You cannot chat on unapproved ticket.",
      uploadFail: "Unable to upload the file.",
      sizeExceed: "File size exceeds 15mb",
    },
  },
};
