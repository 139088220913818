import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import OtpInput from "react-otp-input";
import { ReactComponent as Logo } from "../../icon/logo.svg";
import Language from "../../components/select-language";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import * as global from "../../constant/global";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../CustomLoader/CustomLoader";
import "react-toastify/dist/ReactToastify.css";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import { requestForToken } from "../../utility-files/firebase";
import { useTranslation } from "react-i18next";
import { PushNotifications } from "@capacitor/push-notifications";
import { isPlatform } from "@ionic/react";
import { FCM } from "@capacitor-community/fcm";

export default function Otp() {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const [otpData, setOtpData] = useState();
  const [rememberme, setRememberme] = useState(false);
  const navigate = useNavigate();
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(30); // Initial countdown time
  const [deviceId, setDeviceId] = useState("");

  const isPhone =
    localStorage.getItem(StorageHandling.storageKey.USERPHONE) ||
    sessionStorage.getItem(StorageHandling.storageKey.USERPHONE);

  const isEmail =
    localStorage.getItem(StorageHandling.storageKey.email) ||
    sessionStorage.getItem(StorageHandling.storageKey.email);

  const parsedPhone = JSON.parse(isPhone);
  const handleOtpChange = (value) => {
    setOtpData(value);
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setPending(true);

    try {
      const formDataToSend = new FormData();
      if (isPhone) {
        formDataToSend.append(
          "phone",
          `${parsedPhone.countryCode}${parsedPhone.phone}`
        );
      } else if (isEmail) {
        formDataToSend.append("email", isEmail);
      }
      formDataToSend.append("otp", otpData);
      formDataToSend.append("deviceId", deviceId);
      const request = getRequestForApi(
        global.OTP_VALID,
        formDataToSend,
        methodType.PATCH
      );

      const response = await callHttpRequest(request);
      if (response.status === 200) {
        const token = response.data.token;
        if (rememberme) {
          StorageHandling.setStorageData(
            StorageHandling.storageType,
            StorageHandling.storageKey.TOKEN,
            token
          );
        } else {
          StorageHandling.setStorageData(
            StorageHandling.storageType,
            StorageHandling.storageKey.TOKEN,
            token
          );
        }
        toast.success(t("Auth_otp.otp_page.otpSuccess"));
        navigate("/dashboard");
        window.location.reload();
        setOtpData("");
      } else {
        toast.error(t("Auth_otp.otp_page.otpFail"));
      }
    } catch (error) {
      toast.error(t("Auth_otp.otp_page.otpValidation"));
      console.error("Error sending data to API:", error);
    } finally {
      setPending(false);
    }
  };

  const handleResendOtp = async () => {
    try {
      setResendDisabled(true);
      const data = {
        phone: `${parsedPhone.countryCode}${parsedPhone.phone}`,
      };
      const request = getRequestForApi(
        global.OTP_RESEND,
        data,
        methodType.PATCH
      );

      const response = await callHttpRequest(request);
      // console.log("<OTP> Resend otp", response);
      // const response = await ResendOTP({
      //   phone,
      //   lng: localStorage.getItem("i18nextLng"),
      // });
      if (response.status === 200) {
        toast.success(t("Auth_otp.otp_page.otpResend"));
      }
    } catch (error) {
      toast.error(`${"otp_error"}:${error}`);
    }
  };

  useEffect(() => {
    if (resendDisabled) {
      const intervalid = setInterval(() => {
        setResendTimer((prevTimer) => {
          if (prevTimer === 1) {
            setResendDisabled(false); // Enable the Resend button when timer reaches zero
            clearInterval(intervalid); // Stop the interval
            return 30; // Reset the counter
          } else {
            return prevTimer - 1; // Decrease the counter
          }
        });
      }, 1000);
      return () => clearInterval(intervalid); // Cleanup the interval on component unmount
    }
  }, [resendDisabled]);

  useEffect(() => {
    if (!deviceId) {
      console.log(
        isPlatform("android"),
        isPlatform("ios"),
        isPlatform("capacitor")
      );
      if (
        isPlatform("android") ||
        isPlatform("ios") ||
        isPlatform("capacitor")
      ) {
        PushNotifications.requestPermissions()
          .then((_) =>
            PushNotifications.register()
              .then(() => {
                FCM.getToken()
                  .then((result) => {
                    console.log("<Otp> FCM", result);
                    result && setDeviceId(result.token);
                  })
                  .catch((err) => {});
              })
              .catch(console.log)
          )
          .catch(console.log);
      } else {
        requestForToken()
          .then((token) => {
            // console.log("<OTP> token", token);
            token && setDeviceId(token);
          })
          .catch((e) => console.log(e));
      }
    }
  }, []);

  return (
    <>
      {pending && <CustomLoader />}
      <Container>
        <Box
          sx={{ display: "flex", justifyContent: { xs: "center", lg: "end" } }}
        >
          <Language />
        </Box>
        <Box
          sx={{
            maxWidth: "520px",
            mx: "auto",
            "@media(max-width:992px)": { width: "100%" },
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              mb: { xs: 2, lg: 5 },
              "& svg": {
                marginTop: "25px",
                width: "424px",
                height: "120px",
                "@media(max-width:768px)": {
                  width: "150px",
                  height: "auto",
                },
              },
            }}
          >
            <Logo />
          </Box>
          <Card elevation={0} sx={{ borderRadius: "10px" }}>
            <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "#001D38",
                  textAlign: "center",
                  mb: 3,
                  "@media(max-width:992px)": {
                    fontSize: "30px",
                  },
                }}
              >
                {t("Auth_otp.otp_page.title")}
              </Typography>
              <Box
                sx={{
                  fontSize: "20px",
                  fontWeight: "700",
                  textAlign: "center",
                  color: "rgba(34, 41, 67, 0.8)",
                  mb: 3,
                }}
              >
                {t("Auth_otp.otp_page.otp_label")}
              </Box>
              <OtpInput
                inputType={"number"}
                value={otpData}
                onChange={handleOtpChange}
                numInputs={4}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "5px",
                  border: "1px solid rgba(216, 218, 225, 0.7)",
                  background: "rgba(240, 240, 240, 1)",
                  fontSize: "18px",
                  fontWeight: "600",
                }}
                containerStyle={{
                  justifyContent: "center",
                  gap: "16px",
                }}
                // placeholder="TEXT"
              />

              {isPhone && (
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    mt: 2,
                    color: "#6A6A6A",
                    textAlign: "right",
                  }}
                >
                  <Link
                    href="#!"
                    // className="disabled-link"
                    style={{
                      color: resendDisabled ? "#6A6A6A" : "#FBC515",
                      fontSize: "16px",
                      fontWeight: "600",
                      textDecoration: "none",
                    }}
                    onClick={() => {
                      if (!resendDisabled) {
                        handleResendOtp();
                      }
                    }}
                    // aria-disabled={resendDisabled ? true : false}
                  >
                    {resendDisabled
                      ? `${t(
                          "Auth_otp.otp_page.resendDisable"
                        )} ${resendTimer} ${t("Auth_otp.otp_page.sec")}`
                      : t("Auth_otp.otp_page.resendButton")}
                  </Link>
                </Box>
              )}

              <Box sx={{ mt: 3 }}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    background:
                      "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
                    color: "#fff !important",
                  }}
                  type="submit"
                  disabled={!otpData || pending}
                  className={`${!otpData ? "disable-button" : ""}`}
                  onClick={verifyOtp}
                >
                  {pending
                    ? t("Auth_otp.otp_page.verifyButton")
                    : t("Auth_otp.otp_page.sign_in_button")}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
}
