export const BASE_URL = process.env.REACT_APP_API_URL;
export const USER_LOGIN = BASE_URL+'/api/v1/login'
export const USER_LOGOUT = BASE_URL + "/api/v1/logout";
export const USER_PROFILE =  BASE_URL+"/api/v1/getself/"
export const BILLBOARD_STATUS_COUNT=BASE_URL+'/api/v1/billboard/status-count'
export const GET_ALL_BILLBOARD=BASE_URL+'/api/v1/billboard/all-billboard'
export const GET_ONLINE_BILLBOARD= BASE_URL+"/api/v1/billboard/offline?live=true&offlineDetails=true"
export const GET_ALL_BILLBOARD_DETAILS=BASE_URL+'/api/v2/billboard/'
export const RESET_PASSWORD =BASE_URL+'/api/v1/resetPassword/'
export const GET_BILLBOARD_SUMMRAY =BASE_URL+'/api/v1/c1/summary'
export const GET_BILLBOARD_CAMERA =BASE_URL+"/api/v1/billboard/all-camera-billboard?country="
export const FORGET_PASSWORD =BASE_URL+"/api/v1/forgotPassword"
export const UPDATE_PASSWORD =BASE_URL+"/api/v1/update-password/"
export const DASBOARD_API=BASE_URL+"/api/v2/home"
export const LAST_SEEN=BASE_URL +"/api/v1/logs/last-scan"
export const EDIT_BILLBOARD=BASE_URL +"/api/v2/billboard/"
export const CREATE_REMARK=BASE_URL +"/api/v1/billboard/create-remark/"
export const UPDATE_USERNAME=BASE_URL +"/api/v1/updateMe"
export const UPLOAD_IMAGE=BASE_URL +"/api/v2/billboard/" 
export const OTP_LOGIN=BASE_URL +"/api/v1/otp-login"
export const OTP_VALID=BASE_URL +"/api/v1/verify-otp"
export const OTP_RESEND = BASE_URL + "/api/v1/resend-otp";
export const MAP_API=BASE_URL +"/api/v1/verify-otp"
export const ADVERTISING_LIST=BASE_URL +"/api/v2/ad"
export const DETAILS_ADVERTISING=BASE_URL +"/api/v2/ad/"
export const SNAP=BASE_URL+"/api/v2/billboard/snap/"
export const GET_SNAP_LINK=BASE_URL+"/api/v2/billboard/snap/"
export const GET_LOGS=BASE_URL+"/api/v1/automation/check/"
export const ADD_ADD=BASE_URL+"/api/v2/ad"
export const ADDVERTISER_LIST=BASE_URL+"/api/v1/admin/alluser?role=Advertiser,Client&fields=_id,fullname,email,photo,phone,role,billboards"
export const GET_ALL_ROOMS = BASE_URL + "/api/v1/ticket/rooms";
export const GET_ALL_TICKET_COUNT = BASE_URL + "/api/v1/ticket/allticketcount";
export const GET_TICKET_DETAILS = BASE_URL + "/api/v1/ticket";
export const GET_CHAT_LIST = BASE_URL + "/api/v1/chat";
export const GET_NOTIFICATION = BASE_URL + "/api/v1/notification";
export const ALL_EMPLOYEES_LIST =BASE_URL + "/api/v1/admin/alluser?role=Employee&fields=_id,fullname,email,photo,role";
export const APPROVE_TICKET = BASE_URL + "/v1/ticket/approve/";
export const DELETE_ADD = BASE_URL + "/api/v2/ad/";
export const AUTOMATIC_COST=BASE_URL+"/api/v2/ad/get-cost/shoof2,shoof3,shoof4,shoof5/2024-06-22/2024-06-26/12?subtract=0"
export const UPDATE_ADD=BASE_URL+"/api/v2/ad/"

