import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    FormGroup,
    FormHelperText,
    FormLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as Logo } from "../../icon/logo.svg";
import Language from "../../components/select-language";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as global from "../../constant/global";
import * as Yup from "yup";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import {
    callHttpRequest,
    methodType,
} from "../../utility-files/api-caller/HttpRequest";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import { toast } from "react-toastify";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
    const { t } = useTranslation();
    const Params = useParams;
    let { token } = Params();

    const [error, setError] = useState("");
    const [pending, setPending] = useState(false);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            password: "",
            passwordConfirm: "",
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(8, t("auth_email.errors.invalidPass"))
                .required(t("auth_email.errors.requiredField")),
            passwordConfirm: Yup.string()
                .oneOf([Yup.ref("password")])
                .required(t("auth_email.errors.requiredField")),
        }),
        onSubmit: async (values) => {
            // console.log("<ResetPassword> On submitting", values);
            setPending(true);
            let request = getRequestForApi(
                global.RESET_PASSWORD + `${token}`,
                values,
                methodType.PATCH
            );
            const response = await callHttpRequest(request);
            if (response.status === 200) {
                const token = response.data.token;
                StorageHandling.setStorageData(
                    StorageHandling.storageType,
                    StorageHandling.storageKey.TOKEN,
                    token
                );
                toast.success(t("profile.components.common.passSuccess"));
                navigate("/dashboard");
                setPending(false);
            } else {
                setError(response.errors.errors);
                setPending(false);
            }
        },
    });

    return (
        <>
            {pending && <CustomLoader />}
            <Container>
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                    <Language />
                </Box>
                <Box sx={{ maxWidth: "520px", mx: "auto" }}>
                    <Box sx={{ textAlign: "center", mb: 5 }}>
                        <Logo />
                    </Box>
                    <Card elevation={0} sx={{ borderRadius: "10px" }}>
                        <CardContent sx={{ p: 5, "&:last-child": { pb: 5 } }}>
                            <Typography
                                variant="h1"
                                sx={{
                                    fontSize: "40px",
                                    fontWeight: "700",
                                    color: "#001D38",
                                    textAlign: "center",
                                    mb: 3,
                                    "@media(max-width:992px)": {
                                        fontSize: "30px",
                                    },
                                }}
                            >
                                {t("Auth_resetPassword.ResetPassword.title")}
                            </Typography>
                            <form onSubmit={formik.handleSubmit}>
                                <Stack direction={"column"} spacing={3}>
                                    <FormGroup>
                                        <FormLabel>
                                            {" "}
                                            {t(
                                                "Auth_resetPassword.ResetPassword.newPassword"
                                            )}
                                        </FormLabel>
                                        <TextField
                                            type="password"
                                            size="small"
                                            placeholder={t(
                                                "auth_email.placeholders.password"
                                            )}
                                            name="password"
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                        />
                                        {/* {!formik?.password?.length > 0 < 1 && (
                    <FormHelperText
                      sx={{
                        color: "rgba(255, 0, 0, 0.63)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      At least 8 alphanumerical characters
                    </FormHelperText>
                  )} */}
                                        <span className="text-danger">
                                            {formik.errors.password}
                                        </span>
                                    </FormGroup>
                                    <FormGroup>
                                        <FormLabel>
                                            {t(
                                                "Auth_resetPassword.ResetPassword.confirmPassword"
                                            )}
                                        </FormLabel>
                                        <TextField
                                            type="password"
                                            size="small"
                                            placeholder={t(
                                                "auth_email.placeholders.password"
                                            )}
                                            name="passwordConfirm"
                                            onChange={formik.handleChange}
                                            value={
                                                formik.values.passwordConfirm
                                            }
                                        />
                                        <span className="text-danger">
                                            {formik.errors.passwordConfirm}
                                        </span>
                                    </FormGroup>

                                    <Button
                                        variant="contained"
                                        submit="submit"
                                        onClick={() => {
                                            console.error(
                                                "<ResetPassword>Submit button",
                                                formik?.errors
                                            );
                                            formik?.handleSubmit();
                                        }}
                                        sx={{
                                            background:
                                                "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
                                            color: "#fff !important",
                                        }}
                                    >
                                        {t(
                                            "Auth_resetPassword.ResetPassword.submitButton"
                                        )}
                                    </Button>
                                </Stack>
                            </form>
                        </CardContent>
                    </Card>
                </Box>
            </Container>
        </>
    );
}
