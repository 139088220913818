import { Box } from "@mui/material";
import React from "react";
import { BASE_URL as SocketBaseUrl } from "../../constant/global";
import { ArrowCircleDown, CropFree } from "@mui/icons-material";

function ImagePreview({ src, setPreview }) {
  return (
    <Box>
      <Box
        style={{
          display: "contents",
          cursor: "pointer",
        }}
      >
        <img
          style={{
            width: "100px",
            height: "auto",
          }}
          src={`${SocketBaseUrl}/${src}`}
          alt=""
        />
      </Box>
      <Box textAlign="right">
        <a
          href={`${SocketBaseUrl}/${src}`}
          download={src && src?.split("/")[src?.split("/").length - 1]}
          target="_blank"
          rel="noreferrer"
        >
          <ArrowCircleDown sx={{ fontSize: "1.2rem" }} />
        </a>
        <CropFree
          onClick={() => {
            // console.log(
            //   "<ImagePreview> SocketBaseUrl",
            //   `${SocketBaseUrl}${src}`
            // );

            setPreview(`${SocketBaseUrl}/${src}`);
          }}
          sx={{ color: "#0d6efd", marginLeft: "5px", fontSize: "1.2rem" }}
        />
      </Box>
    </Box>
  );
}

export default ImagePreview;
