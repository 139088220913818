import { useTranslation } from "react-i18next";

export default function NoTickets() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        marginTop: "15%",
        color: "#fff",
        padding: "0px",
        textAlign: "center",
      }}
    >
      <h4>{t("Billboard_index.typography.no_data_available")}</h4>
    </div>
  );
}
