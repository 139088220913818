// SocketProvider.js
import { createContext, useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { BASE_URL as SocketBaseUrl } from "../../constant/global";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { useSearchParams } from "react-router-dom";

const SocketContext = createContext();
export const useSocket = () => {
  const { socket } = useContext(SocketContext);
  return socket;
};

const SocketProvider = ({ children }) => {
  const token =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);
  const ENDPOINT = SocketBaseUrl;
  const [socket, setSocket] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isUnreadMsgs, setIsUnreadMsgs] = useState([]);
  const location = window.location;
  const query = new URLSearchParams(window.location.search);
  const ticketIdRef = useRef(null); // useRef to store latest ticketId
  

  useEffect(() => {
    const handleLocationChange = () => {
      const newTicketId = new URLSearchParams(window.location.search).get(
        "ticket"
      );
      ticketIdRef.current = newTicketId; // Store the latest ticketId in the ref
      // console.log("<SocketProvider> ticketId updated:", newTicketId);
    };

    // Listen for `popstate` event, which is triggered by back/forward navigation
    window.addEventListener("popstate", handleLocationChange);

    // Override `pushState` and `replaceState` to trigger `popstate` on programmatic navigation
    const originalPushState = window.history.pushState;
    const originalReplaceState = window.history.replaceState;

    window.history.pushState = function (...args) {
      originalPushState.apply(this, args);
      window.dispatchEvent(new Event("popstate"));
    };

    window.history.replaceState = function (...args) {
      originalReplaceState.apply(this, args);
      window.dispatchEvent(new Event("popstate"));
    };

    // Initial check in case the component mounts with a URL containing a ticket
    handleLocationChange();

    // Clean up event listeners on component unmount
    return () => {
      window.removeEventListener("popstate", handleLocationChange);
      window.history.pushState = originalPushState;
      window.history.replaceState = originalReplaceState;
    };
  }, []);

  const receiveListener = (msg) => {
    const currentTicketId = ticketIdRef.current; // Access the latest ticketId from the ref
    // console.log(
    //   "<SocketProvider> currentTicketId !== msg?.room",
    //   currentTicketId !== msg?.room,
    //   currentTicketId,
    //   msg?.room
    // );
    if (currentTicketId !== msg?.room) {
      setIsUnreadMsgs((prevUnreadMsgs) => [...prevUnreadMsgs, msg?.room]);

      try {
        const ad = new Audio("/beep.mp3");
        ad.play();
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (!token) {
      console.warn("Token not found, socket connection aborted");
      setLoading(false);
      return; // Handle case where token is not available
    }

    const newSocket = io(ENDPOINT, {
      extraHeaders: {
        token: token,
      },
    });
    newSocket.on("connect", () => {
      setLoading(false);
    });
    newSocket?.on("receiveMessage", receiveListener);
    setSocket(newSocket);
    console.log("Socket Connected...");

    // Clean up the socket connection when the component unmounts
    return () => {
      console.log("Socket disconnect...");
      newSocket.disconnect();
      // setLoading(false);
    };
  }, [ENDPOINT, token]);

  if (loading) {
    return <CustomLoader />; // Or any loading spinner/component
  }

  return (
    <>
      <SocketContext.Provider value={{ socket, isUnreadMsgs, setIsUnreadMsgs }}>
        {children}
      </SocketContext.Provider>
    </>
  );
};

export { SocketProvider, SocketContext };
