export const fr = {
  Ad_Add: {
    CustomLoader: "Chargement...",
    Uploader: "Téléchargeur",
    VideoTitleHere: "Titre de la vidéo ici",
    AddType: "Type d'annonce",
    Local: "Local",
    Global: "Global",
    Partner: "Partenaire",
    AdvertiserDetails: "Détails de l'annonceur",
    SelectAdvertiser: "Sélectionner l'annonceur",
    Name: "Nom",
    PhoneNumber: "Numéro de téléphone",
    PhoneNumberPlaceholder: "Tapez le type de numéro de téléphone ici",
    CampaignDetails: "Détails de la campagne",
    CampaignStartDate: "Date de début de la campagne",
    CampaignEndDate: "Date de fin de la campagne",
    SelectABillboard: "Sélectionner un panneau d'affichage",
    AutomaticCalculation: "Calcul automatique",
    SalesAgentProposal: "Proposition de l'agent commercial",
    Upload: "Télécharger",
    UploadFiles: "Télécharger des fichiers",
    Save: "Enregistrer",
    YouShouldKnow: "Vous devriez savoir :",
    OnlyVideosShouldBeUploaded: "Seules les vidéos doivent être téléchargées",
    VideoResolutionRequirement:
      "La vidéo doit avoir une résolution de 1:2 PX ou être une vidéo TikTok",
    VideoDurationRequirement: "La vidéo doit durer jusqu'à 30 secondes",
    CompanyPolicyRequirement:
      "La vidéo qui contredit la politique de l'entreprise ne sera pas approuvée",
    adUploadSuccess: "Annonce téléchargée avec succès",
    adUploadFail:
      "Échec du téléchargement de l'annonce. Veuillez réessayer plus tard.",
    adUploadFail2:
      "Erreur lors du téléchargement de l'annonce. Veuillez réessayer plus tard.",
    partners: "Partenaires",
    endDateError:
      "La date de fin de la campagne doit être postérieure à la date de début",
    startDateValidation: "Veuillez sélectionner une date de début",
    endDateValidation: "Veuillez sélectionner une date de fin",
  },
  Ad_Details: {
    advertisementDetail: "Détail de l'annonce",
    advertiserDetails: "Détails de l'annonceur",
    noDataAvailable: "Aucune donnée disponible",
    phone: "Téléphone",
    email: "Email",
    campaignDetails: "Détails de la campagne",
    campaignPeriod: "Période de la campagne",
    from: "De",
    to: "À",
    billboard: "Panneau d'affichage",
    createdAt: "Créé le",
    campaignCost: "Coût de la campagne",
    automaticCalculation: "Calcul automatique",
    agentProposal: "Proposition de l'agent",
    finalCost: "Coût final",
    videoAdPreview: "Aperçu de l'annonce vidéo",
    date: "Date",
    adExposure: "Exposition de l'annonce",
    totalExposure: "Exposition totale",
    dataNotAvailable: "Données non disponibles",
    adType: "Type",
    adStatus: "Statut",
  },
  Ad_Edit: {
    advertisement_update: {
      title: "Mise à jour de l'annonce",
      video_title_label: "Titre de la vidéo ici",
      add_type_label: "Type d'annonce",
      advertiser_details_title: "Détails de l'annonceur",
      select_advertiser: "Sélectionner l'annonceur",
      advertiser_name_label: "Nom",
      phone_number_label: "Numéro de téléphone",
      email_label: "Email",
      campaign_details_title: "Détails de la campagne",
      campaign_start_date_label: "Date de début de la campagne",
      campaign_end_date_label: "Date de fin de la campagne",
      select_billboard_label: "Sélectionner un panneau d'affichage *",
      automatic_calculation_label: "Calcul automatique",
      sales_agent_proposal_label: "Proposition de l'agent commercial",
      upload_title: "Télécharger *",
      upload_files_text: "Télécharger des fichiers",
      resubmit_button_text: "Soumettre à nouveau",
      you_should_know_title: "Vous devriez savoir :",
      you_should_know_text: [
        "Seules les vidéos doivent être téléchargées",
        "La vidéo doit avoir une résolution de 1:2 PX ou être une vidéo TikTok",
        "La vidéo doit durer jusqu'à 30 secondes",
        "La vidéo qui contredit la politique de l'entreprise ne sera pas approuvée",
      ],
    },
  },
  Ad_index: {
    advertisements: {
      title: "Annonces",
      tabs: {
        all: "Tout",
        pending: "En attente",
        approved: "Approuvé",
        rejected: "Rejeté",
      },
      billboardCount: {
        totalBillboards: "Total des panneaux",
        activeBillboards: "Panneaux actifs",
        inactiveBillboards: "Panneaux inactifs",
        billboardsInMaintenance: "Panneaux en maintenance",
      },
      addAdvertisement: {
        add: "Ajouter",
        advertisements: "Annonces",
      },
      billboard: {
        title: "Panneau d'affichage",
        video: {
          src: "https://www.youtube.com/embed/0njtNjgy-cA?si=DyHFmHets3EahWqx",
          title: "Lecteur vidéo YouTube",
        },
      },
    },
    commonTable: {
      columns: {
        id: "ID",
        title: "Titre",
        type: "Type",
        startDate: "Date de début",
        endDate: "Date de fin",
        status: "Statut",
        actions: "Actions",
        cost_forecast: "Prévision des coûts",
        ad_exposures: "Expositions publicitaires",
      },
      rows: {
        noData: "Aucune donnée disponible",
      },
    },
  },
  Ad_main: {
    advertisements: {
      title: "Annonces",
      tabs: {
        all: "Tout",
        pending: "En attente",
        active: "Actif",
        approved: "Approuvé",
        unapproved: "Non approuvé",
        expired: "Expiré",
      },
      addButton: {
        add: "Ajouter",
      },
    },
    commonTable: {
      columns: {
        id: "ID",
        title: "Titre",
        type: "Type",
        startDate: "Date de début",
        endDate: "Date de fin",
        status: "Statut",
        actions: "Actions",
      },
      rows: {
        noData: "Aucune donnée disponible",
      },
    },
    deleteConfirmationModal: {
      open: "Ouvrir",
      close: "Fermer",
      itemName: "Nom de l'élément",
      refetch: "Rafraîchir",
    },
  },
  Ad_tabs: {
    advertisements: "Annonces",
    add: "Ajouter",
    all: "Tout",
    pending: "En attente",
    active: "Actif",
    approved: "Approuvé",
    unapproved: "Non approuvé",
    expired: "Expiré",
  },
  auth_email: {
    labels: {
      email: "Email",
      password: "Mot de passe",
      rememberMe: "Se souvenir de moi",
      submitButton: "Envoyer",
      submittingButton: "Envoi en cours...",
      showPassword: "Montrer le mot de passe",
      hidePassword: "Cacher le mot de passe",
      forgotPassword: "Mot de passe oublié ?",
    },
    placeholders: {
      email: "Entrez votre email",
      password: "Entrez votre mot de passe",
      emailSuccess:
        "Le code de vérification a été envoyé à votre adresse e-mail avec succès.",
      emailFail: "Identifiants non valides. Veuillez réessayer.",
    },
    errors: {
      requiredField: "Ce champ est obligatoire",
      invalidEmail: "Format de l'adresse email invalide",
      invalidPass: "Le mot de passe doit comporter au moins 8 caractères",
      invalidPhone:
        "Échec de l'envoi du code de vérification. Veuillez réessayer.",
      noUser: "Utilisateur non trouvé.",
    },
  },
  Auth_phone: {
    Email: "Email",
    Password: "Mot de passe",
    "Show Password": "Montrer le mot de passe",
    "Hide Password": "Cacher le mot de passe",
    "Remember Me": "Se souvenir de moi",
    "Submitting...": "Envoi en cours...",
    Submit: "Envoyer",
    "Forgot Password?": "Mot de passe oublié ?",
    Phone: "Téléphone",
    "Send OTP": "Envoyer OTP",
    "Sending...": "Envoi en cours...",
  },
  Auth_forgotPassword: {
    Advertisements: "Annonces",
    Add: "Ajouter",
    "Forgot Password?": "Mot de passe oublié ?",
    "Don’t worry! It happens. Please enter the email address associated with your account":
      "Ne vous inquiétez pas ! Cela arrive. Veuillez entrer l'adresse email associée à votre compte",
    Email: "Email",
    Submit: "Envoyer",
    "Send OTP": "Envoyer OTP",
    Phone: "Téléphone",
    "Remember Me": "Se souvenir de moi",
    "Show Password": "Montrer le mot de passe",
    "Hide Password": "Cacher le mot de passe",
    success: "Lien de réinitialisation du mot de passe envoyé à votre courrier",
  },
  Auth_index: {
    forgot_password_title: "Mot de passe oublié ?",
    forgot_password_subtitle:
      "Ne vous inquiétez pas ! Cela arrive. Veuillez entrer l'adresse email associée à votre compte",
    forgot_password_email_label: "Email",
    forgot_password_submit_button: "Envoyer",
    login_with_email_title: "Connexion avec email",
    login_with_phone_title: "Connexion avec téléphone",
    email_login_button: "Connexion avec email",
    phone_login_button: "Connexion avec téléphone",
    email_placeholder: "Entrez votre email",
    send_otp_button: "Envoyer OTP",
    submit_button: "Envoyer",
    show_password_button: "Montrer le mot de passe",
    hide_password_button: "Cacher le mot de passe",
    remember_me_label: "Se souvenir de moi",
    phone_label: "Téléphone",
    form_error_required: "Ce champ est obligatoire",
    form_error_email_invalid: "Veuillez entrer une adresse email valide",
    form_error_password_length:
      "Le mot de passe doit contenir au moins 6 caractères",
  },
  Auth_otp: {
    forgot_password: {
      title: "Mot de passe oublié ?",
      instruction:
        "Ne vous inquiétez pas ! Cela arrive. Veuillez entrer l'adresse email associée à votre compte",
      email_placeholder: "Entrez votre email",
      submit_button: "Envoyer",
    },
    login: {
      email: "Connexion avec email",
      phone: "Connexion avec téléphone",
      email_login_button: "Connexion avec email",
      phone_login_button: "Connexion avec téléphone",
    },
    otp_page: {
      title: "Le code de vérification",
      otp_label: "Le code de vérification à 4 chiffres",
      otp_placeholder: "TEXTE",
      sign_in_button: "Se connecter",
      otpSuccess: "Code de vérification vérifié avec succès.",
      otpFail: "Impossible de vérifier le code de vérification",
      otpValidation: "Veuillez saisir un code de vérification valide",
      otpResend: "Code de vérification envoyé avec succès",
      resendDisable: "Vous n'avez pas reçu le code ? Renvoyez-le dans",
      sec: "seconde",
      resendButton: "Renvoyer",
      verifyButton: "Vérification...",
    },
  },
  Auth_resetPassword: {
    ResetPassword: {
      title: "Réinitialiser le mot de passe",
      newPassword: "Nouveau mot de passe",
      confirmPassword: "Confirmer le mot de passe",
      submitButton: "Envoyer",
      validationMessages: {
        passwordLength: "Au moins 8 caractères alphanumériques requis",
        passwordMismatch: "Les mots de passe ne correspondent pas",
      },
    },
  },
  Billboard_main: {
    Billboards: {
      title: "Panneaux d'affichage",
      tabs: {
        all: "Tous",
        someTab1: "Onglet 1",
        someTab2: "Onglet 2",
        offline: "Hors ligne",
        online: "En ligne",
        billboard_address: "Adresse du panneau",
        owner: "Propriétaire",
        installation: "Installation",
        ads_amount: "Montant des publicités",
        ads_exposures: "Expositions des publicités",
        offline_started: "Début hors ligne",
        linked_tickets: "Tickets liés",
      },
      buttons: {
        arrowRight: "Flèche droite",
      },
    },
  },
  Billboard_Edit: {
    BillboardTraffic: {
      title: "Trafic des panneaux",
      placeholder: {
        billboardName: "Nom du panneau",
        contactName: "Nom du contact",
        address: "Adresse",
        phoneNumber: "Numéro de téléphone",
        email: "Email",
        remark: "Tapez ici",
        uploadFiles: "Télécharger des fichiers",
      },
      labels: {
        billboardLocation: "Emplacement du panneau",
        contactDetails: "Détails du contact",
        remarks: "Remarques",
        uploadGalleryImages: "Télécharger des images de galerie",
        advertisingContent: "Contenu publicitaire",
        amountAdsDisplayed: "Nombre d'annonces affichées",
        spotsPerAdPerDay: "Spots par annonce par jour",
        installationDate: "Date d'installation",
        nextPayment: "Prochain paiement",
        monthlyPayment: "Paiement mensuel",
      },
      messages: {
        imageUploadSuccess: "Téléchargement de l'image réussi",
        remarkSuccess: "Remarque créée avec succès !",
        error: "Erreur !",
      },
      buttons: {
        save: "Enregistrer",
      },
    },
  },
  Billboard_index: {
    typography: {
      billboards: "Panneaux d'affichage",
      installation_date: "Date d'installation",
      next_payment: "Prochain paiement",
      edit_billboard: "Éditer le panneau",
      billboard_ads: "Annonces sur panneaux",
      views_and_impressions: "Vues et impressions",
      offline_heat_map: "Carte thermique hors ligne",
      billboard_location: "Emplacement du panneau",
      interaction: "Interaction",
      qr_code: "Code QR",
      remarks: "Remarques",
      payments: "Paiements",
      spot_ad_daily: "Spot publicitaire quotidien",
      gallery: "Galerie",
      no_data_available: "Aucune donnée disponible",
      click_here_to_open_link: "Cliquez ici pour ouvrir le lien",
      download: "Télécharger",
      rushHour: "Heure de pointe aujourd'hui",
      week: "Cette semaine",
      month: "Ce mois-ci",
      year: "Cette année",
      activeAd: "Publicités actives",
    },
    select: {
      no_address_available: "Aucune adresse disponible",
    },
    date_format: {
      date: "Date",
    },
    button: {
      download: "Télécharger",
      sizes: ["300 *300", "400*400", "500*500", "1000*1000", "2000*2000"],
    },
    modal: {
      social: {
        facebook: "Facebook",
        whatsapp: "WhatsApp",
        linkedin: "LinkedIn",
        email: "Email",
        tel: "Téléphone",
      },
    },
    interaction: {
      qr_code: "Code QR",
    },
    formats: {
      amount: "Montant",
    },
  },
  Billboard_mapView: {
    errors: {
      coordinatesUnavailable: "Coordonnées non disponibles",
      mapContainerUnavailable: "Conteneur de carte non disponible",
    },
    messages: {
      noLocationAvailable: "Aucun emplacement disponible",
    },
  },
  Dashboard: {
    components: {
      customLoader: "Chargement...",
      billboardPlacement: "Placement des panneaux",
      dashboard: "Tableau de bord",
      now: "Maintenant",
      lastUpdate: "Dernière mise à jour",
      dailyStatistics: "Statistiques quotidiennes",
      rushHour: "Heure de pointe",
      impressions: "Impressions",
      map: "Carte",
      goToMap: "Aller à la carte",
      impressionsTitle: "Impressions",
      billboardStatus: "Statut du panneau",
      clearAll: "Tout effacer",
      respond: "Répondre",
      noMessage: "Aucun nouveau message",
      notification: "Notifications",
      read: "Lire",
      noNotification: "Aucune nouvelle notification",
      online: "En ligne",
      offline: "Hors ligne",
      profile: "Profil",
      logout: "Se déconnecter",
    },
  },
  modal: {
    components: {
      dialog: {
        deleteConfirmation: "Confirmation de suppression",
        close: "Fermer",
        areYouSure: "Êtes-vous sûr de vouloir supprimer ?",
        cancel: "Annuler",
        delete: "Supprimer",
        deleteSuccess: "Annonce supprimée avec succès.",
        deleteFail: "Échec de la suppression de l'annonce. Veuillez réessayer.",
      },
    },
  },
  profile: {
    components: {
      userProfile: {
        userProfile: "Profil utilisateur",
        name: "Nom et prénom",
        email: "Email",
        phoneNumber: "Numéro de téléphone",
        country: "Pays",
        city: "Ville",
        address: "Adresse",
        zipCode: "Code postal",
        save: "Enregistrer",
        edit: "Éditer",
      },
      changePassword: {
        changePassword: "Changer de mot de passe",
        newPassword: "Nouveau mot de passe",
        confirmPassword: "Confirmer le mot de passe",
        submitting: "Envoi en cours...",
        save: "Enregistrer",
        passValidation: "Le mot de passe doit comporter au moins 8 caractères",
        confPassValidation: "Les mots de passe ne correspondent pas",
      },
      common: {
        cancel: "Annuler",
        delete: "Supprimer",
        areYouSure: "Êtes-vous sûr de vouloir supprimer ?",
        updateSuccess:
          "Les détails de l'utilisateur ont été mis à jour avec succès",
        passSuccess: "Changement de mot de passe réussi",
      },
    },
  },
  Ticket_add: {
    add_ticket: {
      title: "Ajouter un ticket",
      labels: {
        ticket_name: "Nom du ticket",
        ticket_description: "Description du ticket",
        billboard_name: "Nom du panneau",
        closing_date: "Date de clôture",
        ticket_type: "Type de ticket",
        ticket_subtype: "Sous-type de ticket",
        ticket_assignee: "Assigné du ticket",
        ticket_followers: "Suiveurs du ticket",
      },
      placeholders: {
        ticket_name: "Nom du ticket",
        ticket_description: "Description",
        billboard_name: "Sélectionner un panneau",
        ticket_type: "Sélectionner un type de ticket",
        ticket_subtype: "Sélectionner un sous-type de ticket",
      },
      buttons: {
        approve: "Approuver",
        save: "Enregistrer",
        cancel: "Annuler",
      },
      errors: {
        ticket_name: "Le nom du ticket est requis",
        description: "La description est requise",
        billboard_name: "Le nom du panneau est requis",
        closing_date: "La date de clôture est requise",
        ticket_type: "Le type de ticket est requis",
        ticket_subtype: "Le sous-type de ticket est requis",
        ticket_assignee: "L'assigné du ticket est requis",
        ticket_followers: "Les suiveurs du ticket sont requis",
        duplicateName: "Ce nom de ticket est déjà pris",
        ticketSuccess: "Ticket créé avec succès",
      },
      admin_roles: ["Admin", "SuperAdmin"],
    },
  },
  Ticket_allTicket: {
    search: "Recherche",
    billboard_sort: "Tri par panneau",
    ticket_id: "ID du ticket:",
    created_at: "{date}",
    ticket_name: "{name}",
    message_prefix: "{name} : {message}",
    tickets_title: "Tickets",
    messages_tab: "Messages",
    details_tab: "Détails",
  },
  Ticket_details: {
    "Add Ticket": "Ajouter un ticket",
    "Ticket Name": "Nom du ticket",
    "Ticket Description": "Description du ticket",
    "Billboard Name": "Nom du panneau",
    "Closing Date": "Date de clôture",
    "Ticket Type": "Type de ticket",
    "Ticket Subtype": "Sous-type de ticket",
    "Ticket Assignee": "Assigné du ticket",
    "Ticket Followers": "Suiveurs du ticket",
    Search: "Recherche",
    Messages: "Messages",
    Details: "Détails",
    Less: "Moins",
    More: "Plus",
    "Ticket Assignee:": "Assigné du ticket :",
    creationDate: "Date de création:",
  },
  Ticket_index: {
    tickets: {
      title: "Tickets",
      tabs: {
        all: "Tous les tickets",
        approve: "Tickets approuvés",
        unapprove: "Tickets non approuvés",
        close: "Tickets fermés",
        disapprove: "Tickets désapprouvés",
        archieve: "Tickets archivés",
        inProgress: "Billets en cours",
        completed: "Billets terminés",
      },
      addButton: "Ajouter",
      noTicketsMessage: "Aucun ticket disponible.",
      ticketCounts: {
        approve: "Approuver le ticket",
        unapprove: "Désapprouver le ticket",
        close: "Fermer le ticket",
        disapprove: "Désapprouver le ticket",
        archieve: "Archiver le ticket",
      },
      drawer: {
        title: "Ajouter un ticket",
      },
    },
    common: {
      loaders: {
        custom: "Chargement...",
        fidgetSpinner: "Chargement...",
      },
      buttons: {
        approve: "Approuver",
        disapprove: "Désapprouver",
        delete: "Supprimer",
      },
      labels: {
        search: "Recherche",
        billboardSort: "Tri par panneau",
      },
      messages: {
        more: "Plus",
        less: "Moins",
      },
      placeholders: {
        search: "Recherche...",
      },
      statuses: {
        approve: "Approuvé",
        close: "Fermé",
      },
      ticketDetails: {
        assignee: "Assigné du ticket",
        followers: "Suiveurs du ticket",
      },
    },
  },
  Ticket_message: {
    header: {
      tickets: "Tickets",
    },
    tabs: {
      approve: "Approuver",
      unapprove: "Désapprouver",
      close: "Fermer",
      disapprove: "Désapprouver",
      archieve: "Archiver",
    },
    buttons: {
      add: "Ajouter",
    },
    messages: {
      noTickets: "Aucun ticket disponible.",
      uploadingFile: "Téléchargement du fichier...",
      typeHere: "Tapez ici...",
      cannotChatArchived: "Vous ne pouvez pas discuter sur un ticket archivé.",
      cannotChatClosed: "Vous ne pouvez pas discuter sur un ticket fermé.",
      cannotChatDisapproved:
        "Vous ne pouvez pas discuter sur un ticket désapprouvé.",
      cannotChatUnapproved:
        "Vous ne pouvez pas discuter sur un ticket non approuvé.",
      uploadFail: "No se puede cargar el archivo.",
      sizeExceed: "La taille du fichier dépasse 15 Mo",
    },
  },
};
