import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
} from "@mui/material";
import * as global from "../../../constant/global";
import CustomInput from "../../../utility-files/customInput/customIput";
import {
  callHttpRequest,
  methodType,
} from "../../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../../utility-files/api-caller/CommonRequest";
import * as StorageHandling from "../../../utility-files/storage-util/StorageHandling";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

function EmailLogin() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [rememberme, setRememberme] = useState(true);
  const [pending, setPending] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({ email: "", password: "" });
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(null); // reCAPTCHA state
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      toast.error("Please complete the reCAPTCHA verification.");
      return;
    }
    setPending(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("email", formData.email);
      formDataToSend.append("password", formData.password);
      formDataToSend.append("LedproAdmin", true);
      if (captchaValue) {
        formDataToSend.append("token", captchaValue);
      }
      let request = getRequestForApi(
        global.USER_LOGIN,
        formDataToSend,
        methodType.POST
      );
      const response = await callHttpRequest(request);

      if (response.status === 200) {
        // const token = response.data.token;
        // StorageHandling.setStorageData(
        //   StorageHandling.storageType,
        //   StorageHandling.storageKey.TOKEN,
        //   token
        // );
        StorageHandling.setStorageData(
          StorageHandling.storageType,
          StorageHandling.storageKey.email,
          formData.email
        );
        StorageHandling.setStorageData(
          StorageHandling.storageType,
          StorageHandling.storageKey.rememberMe,
          rememberme
        );
        toast.success(`${t("auth_email.placeholders.emailSuccess")}`);
        // toast.success('Logged in successfully!');
        navigate("/otp");
        setPending(false);
        setFormData({ email: "", password: "" });
      } else {
        setPending(false);
      }
    } catch (error) {
      console.error("Error sending data to API:", error);
      if (error.response.status === 404) {
        toast.error(t("auth_email.errors.noUser"));
      } else if (error.response.status === 409) {
        toast.error("reCAPTCHA verification expired");
      } else toast.error(`${t("auth_email.placeholders.emailFail")}`);
      // toast.error('Error occurred. Please try again.');
      setPending(false);
    }
  };

  const handleBlur = (fieldName, value) => {
    let errorMessage = "";
    if (fieldName === "email") {
      if (!value) {
        errorMessage = t("auth_email.errors.requiredField");
      } else if (!/\S+@\S+\.\S+/.test(value)) {
        errorMessage = t("auth_email.errors.invalidEmail");
      }
    } else if (fieldName === "password") {
      if (!value && !showPassword) {
        errorMessage = t("auth_email.errors.requiredField");
      } else if (value.length < 8 && showPassword) {
        errorMessage = t("auth_email.errors.invalidPass");
      }
    }
    setError((prevState) => ({
      ...prevState,
      [fieldName]: errorMessage,
    }));
  };

  // Handle captcha value
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Set the captcha token
  };
  return (
    <>
      {pending && <CustomLoader />}
      <Stack direction={"column"} spacing={2}>
        <FormGroup>
          <FormLabel>
            {t("auth_email.labels.email")} <span className="required">*</span>
          </FormLabel>
          <CustomInput
            setFieldData={setFormData}
            fieldType="email"
            id="email"
            className={"form-control py-0 ps-5"}
            placeholder={t("auth_email.placeholders.email")}
            onBlur={(e) => handleBlur("email", e.target.value)}
          />
          {error.email && (
            <Box sx={{ color: "red", fontSize: "12px" }}>{error.email}</Box>
          )}
        </FormGroup>
        <FormGroup>
          <FormLabel>
            {t("auth_email.labels.password")}
            <span className="required">*</span>
          </FormLabel>
          <CustomInput
            setFieldData={setFormData}
            fieldType="password"
            id="password"
            className={"form-control py-0 ps-5"}
            placeholder={t("auth_email.placeholders.password")}
            onBlur={(e) => handleBlur("password", e.target.value)}
            type={showPassword ? "text" : "password"}
          />
          {error.password && (
            <Box sx={{ color: "red", fontSize: "12px" }}>{error.password}</Box>
          )}
          <Box sx={{ mt: 1 }}>
            <Button
              size="small"
              onClick={togglePasswordVisibility}
              variant="text"
              sx={{ textTransform: "none" }}
            >
              {showPassword
                ? t("auth_email.labels.hidePassword")
                : t("auth_email.labels.showPassword")}
            </Button>
          </Box>
        </FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberme}
              onChange={() => setRememberme(!rememberme)}
            />
          }
          label={t("auth_email.labels.rememberMe")}
        />
        <FormGroup>
          <ReCAPTCHA
            sitekey={recaptchaSiteKey} // Replace with your actual site key
            onChange={handleCaptchaChange}
          />
        </FormGroup>
        <Button
          onClick={handleSubmit}
          disabled={!formData.email || !formData.password || pending}
          variant="contained"
          className={` ${
            !formData.email || !formData.password ? "disable-button" : ""
          }`}
          sx={{
            background:
              "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
            color: "#fff !important",
          }}
        >
          {pending
            ? t("auth_email.labels.submittingButton")
            : t("auth_email.labels.submitButton")}
        </Button>

        <Box
          sx={{
            color: "#001D38",
            fontSize: "18px",
            textAlign: "center",
            fontWeight: "700",
            textDecoration: "none",
            "@media(max-width:992px)": {
              fontSize: "14px",
            },
          }}
          component={Link}
          to="/forgot-password"
        >
          {t("auth_email.labels.forgotPassword")}
        </Box>
      </Stack>
    </>
  );
}

export default EmailLogin;
