import { Box } from "@mui/material";
import React from "react";
import { BASE_URL as SocketBaseUrl } from "../../constant/global";
import { ArrowCircleDown, CropFree } from "@mui/icons-material";

function VideoPreview({ src, setPreview }) {
  return (
    <Box>
      <Box
        style={{
          display: "contents",
          cursor: "pointer",
        }}
      >
        <video controls width="100px">
          <source src={`${SocketBaseUrl}/${src}`} type="video/webm" />
        </video>
      </Box>
      <Box textAlign="right">
        <a
          href={`${SocketBaseUrl}/${src}`}
          download={src && src?.split("/")[src?.split("/").length - 1]}
          target="_blank"
          rel="noreferrer"
        >
          <ArrowCircleDown sx={{ fontSize: "1.2rem" }} />
        </a>
        <CropFree
          onClick={() => setPreview(`${SocketBaseUrl}/${src}`)}
          sx={{ color: "#0d6efd", marginLeft: "5px", fontSize: "1.2rem" }}
        />
      </Box>
    </Box>
  );
}

export default VideoPreview;
