export default function FormLabel() {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "20px",
          fontWeight: 700,
          marginBottom: "5px",
          "@media(max-width:992px)": {
            fontSize: "14px",
          },
        },
      },
    },
  };
}
