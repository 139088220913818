import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MyMapComponent = () => {
  const { t } = useTranslation();
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [isMapContainerReady, setIsMapContainerReady] = useState(false);
  const billBoardDetail = useSelector((state) => state.billBoardDetail);

  useEffect(() => {
    const coordinate =
      billBoardDetail?.data?.coordinates ||
      billBoardDetail?.data?.location?.coordinates;
    if (!coordinate) {
      console.error(t("Billboard_mapView.errors.coordinatesUnavailable"));
      return;
    }

    const initMap = () => {
      const mapDiv = document.getElementById("map");
      if (!mapDiv) {
        console.error(t("Billboard_mapView.errors.mapContainerUnavailable"));
        return;
      }

      const map = new window.google.maps.Map(mapDiv, {
        center: {
          lat: coordinate.lat || coordinate[0],
          lng: coordinate.lang || coordinate[1],
        },
        zoom: 9,
      });

      new window.google.maps.Marker({
        position: {
          lat: coordinate.lat || coordinate[0],
          lng: coordinate.lang || coordinate[1],
        },
        map: map,
      });
    };

    if (!window.google) {
      const script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyA-SGeGL2gFSI3MsiwMqshTNmmQleaOA7A";
      script.async = true;
      script.onload = () => {
        setScriptLoaded(true);
        if (isMapContainerReady) {
          initMap();
        }
      };

      document.head.appendChild(script);

      return () => {
        document.head.removeChild(script);
      };
    } else {
      setScriptLoaded(true);
      if (isMapContainerReady) {
        initMap();
      }
    }
  }, [billBoardDetail, isMapContainerReady]);

  useEffect(() => {
    if (scriptLoaded && isMapContainerReady) {
      const coordinate =
        billBoardDetail?.data?.coordinates ||
        billBoardDetail?.data?.location?.coordinates;
      console.log("<MyMapComponent> coordinate", coordinate);
      if (coordinate) {
        const mapDiv = document.getElementById("map");
        if (mapDiv) {
          const map = new window.google.maps.Map(mapDiv, {
            center: {
              lat: coordinate.lat || coordinate[0],
              lng: coordinate.lang || coordinate[1],
            },
            zoom: 15,
          });

          new window.google.maps.Marker({
            position: {
              lat: coordinate.lat || coordinate[0],
              lng: coordinate.lang || coordinate[1],
            },
            map: map,
          });
        }
      }
    }
  }, [scriptLoaded, isMapContainerReady, billBoardDetail]);

  useEffect(() => {
    setIsMapContainerReady(true);
  }, []);

  if (
    !billBoardDetail?.data?.coordinates &&
    !billBoardDetail?.data?.location?.coordinates
  ) {
    return (
      <div className="centered-div">
        {t("Billboard_mapView.messages.noLocationAvailable")}
      </div>
    );
  }

  return <div id="map" style={{ height: "50vh", width: "100%" }}></div>;
};

export default React.memo(MyMapComponent);
