import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import * as global from "../../constant/global";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const DeleteConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  ItemName,
  refatch,
}) => {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const isRTL = localStorage.getItem("i18nextLng") === "ar";

  const deleteItem = async () => {
    setPending(true);
    let request, variables;
    request = getRequestForApi(
      global.DELETE_ADD + `${ItemName}`,
      variables,
      methodType.DELETE
    );
    await callHttpRequest(request)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          toast.success(t("modal.components.dialog.deleteSuccess"));
          refatch();
        } else {
          toast.error(t("modal.components.dialog.deleteFail"));
        }
      })
      .catch((err) => {
        setPending(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{ textAlign: isRTL ? "right" : "left" }}
    >
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isRTL ? "space-between" : "flex-start"}
        >
          {isRTL ? null : <DeleteIcon color="error" fontSize="large" />}
          <Typography
            variant="h6"
            color="error"
            sx={{ ml: isRTL ? 0 : 2, mr: isRTL ? 2 : 0 }}
          >
            {t("modal.components.dialog.deleteConfirmation")}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: isRTL ? "unset" : 8,
              left: isRTL ? 8 : "unset",
              top: 8,
              color: "grey.500",
            }}
          >
            <CloseIcon />
          </IconButton>
          {isRTL ? <DeleteIcon color="error" fontSize="large" /> : null}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("modal.components.dialog.areYouSure")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {t("modal.components.dialog.cancel")}
        </Button>
        <Button
          onClick={() => {
            deleteItem();
            onClose();
          }}
          color="error"
          variant="contained"
          autoFocus
        >
          {t("modal.components.dialog.delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
