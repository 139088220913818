import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";

export default function BarChart() {
  const matches = useMediaQuery("(max-width:600px)");
  const detailBillBoard = useSelector((state) => state.billBoardDetail);

  const dynamicData = detailBillBoard?.data?.views_impression || {};

  const xAxisData = Object.keys(dynamicData);
  const seriesData = Object.values(dynamicData);

  const option = {
    grid: {
      left: "0",
      right: "0",
      top: "3%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxisData,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: "#687588",
        margin: 20,
        rotate: 0,
      },
    },
    yAxis: {
      type: "value",

      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
      axisLabel: {
        color: "#687588",
      },
    },
    series: [
      {
        data: seriesData,
        type: "bar",
        showBackground: true,

        backgroundStyle: {
          color: "#493932",
          borderRadius: 10,
        },
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#F7C409" },
            { offset: 0.5, color: "#F7C409" },
            { offset: 1, color: "#B44C06" },
          ]),
          borderRadius: 10,
        },
        label: {
          show: true,
          position: "insideTop",
          color: "#160B01",
          fontSize: 12,
          fontWeight: "bold",
          backgroundColor: "rgba(255, 255, 255, 17)",
          padding: [4, 10],
          offset: [0, -10],
          borderRadius: 6,
          formatter: function (params) {
            return params.value;
          },
        },
      },
    ],
  };

  return (
    <ReactECharts
      option={option}
      style={{
        height: matches ? "250px" : "calc(100vh - 180px)",
        width: "100%",
      }}
    />
  );
}
