import React from 'react'
import '../CustomLoader/CustomLoader.css'
import { IMAGE_TYPES } from '../utility-files/default-values/DefaultValues'
import { Box } from '@mui/material'

function CustomLoader({ sx }) {
    return (
        <Box className='loader-wrapper' sx={sx}>
            <img src={IMAGE_TYPES.LOAD_GIF} alt="loader" />
        </Box>
    )
}

export default CustomLoader