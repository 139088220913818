import {
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommanTable from "../../components/Table";
import { Link } from "react-router-dom";
import { SVG } from "../../icon/svg.file";
import AdvertisementTabs from "./tabs";
import AddIcon from "@mui/icons-material/Add";
import * as global from "../../constant/global";
import {
  callHttpRequest,
  methodType,
} from "../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../utility-files/api-caller/CommonRequest";
import { getDateWithTimeFormat } from "../../utility-files/date-util/DateHandling";
import CustomLoader from "../../CustomLoader/CustomLoader";
import * as StorageHandling from "../../utility-files/storage-util/StorageHandling";
import { isIntegerValue } from "../../utility-files/data-util/DataHandler";
import DeleteConfirmationModal from "../modal/DeleteModal";
import { useTranslation } from "react-i18next";
import AdMobileTable from "./adMobileTable";

export default function Advertisement() {
  const matches = useMediaQuery("(max-width:768px)");
  const { t } = useTranslation();
  const [tabs, setTabs] = useState(1);
  const [error, setError] = useState();
  const [pending, setPending] = useState(false);
  const [adDetails, setAdDetails] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null); // State to store selected item ID
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 25,
    totalPages: 1,
    currentPage: 1,
    hasNext: false,
    hasPrevious: false,
  });
  const handleTabs = (id) => {
    setTabs(id);
  };

  useEffect(() => {
    if (id) {
      setTabs(parseInt(id));
    }
  }, [id]);

  const handleModal = (itemId) => {
    setShowModal(!showModal);
    setSelectedItemId(itemId);
  };

  const refatch = () => {
    getAdvertisement();
  };

  const isAuthenticated =
    localStorage.getItem(StorageHandling.storageKey.TOKEN) ||
    sessionStorage.getItem(StorageHandling.storageKey.TOKEN);

  const countryName =
    localStorage.getItem(StorageHandling.storageKey.USERDATA) ||
    sessionStorage.getItem(StorageHandling.storageKey.USERDATA);
  // console.log("countryName", countryName);
  useEffect(() => {
    if (isAuthenticated) {
      if (
        tabs === 1 ||
        tabs === 2 ||
        tabs === 3 ||
        tabs === 4 ||
        tabs === 5 ||
        tabs === 6
      ) {
        let status = "";
        switch (tabs) {
          case 1:
            status = "";
            getAdvertisement(status);
            break;
          case 2:
            status = "pending";
            getAdvertisement(status);
            break;
          case 3:
            status = "active";
            getAdvertisement(status);
            break;
          case 4:
            status = "approved";
            getAdvertisement(status);
            break;
          case 5:
            status = "unapproved";
            getAdvertisement(status);
            break;
          case 6:
            status = "expired";
            getAdvertisement(status);
            break;
          default:
            getAdvertisement();
            // Optional: Handle cases not listed (e.g., case 5 or other numbers)
            break;
        }
      }
    }
  }, [isAuthenticated, tabs, pagination.page]);

  const getAdvertisement = async (status = "") => {
    setPending(true);
    let request, variables;
    request = getRequestForApi(
      `${global.ADVERTISING_LIST}?status=${status}&limit=${pagination.limit}&page=${pagination.page}`,
      variables,
      methodType.GET
    );
    await callHttpRequest(request)
      .then((response) => {
        if (response?.status === 200 || response?.status === 201) {
          let arr = [];
          response?.data?.data?.forEach((element) => {
            if (element.adExposures?.length !== 0) {
              arr.push(element.adExposures[element.adExposures?.length - 1]);
            }
          });

          setAdDetails(response?.data);
          setPending(false);
          let page = 1;
          const paginitionValues = {
            totalPages: Math.ceil(
              (response?.data?.total || 1) / pagination.limit,
              10
            ),
            currentPage: response?.data?.page || 1,
            hasNext: response?.data?.hasNext || false,
            hasPrevious: response?.data?.hasPrevious || false,
          };
          // console.log(
          //   "[Advertisement]<getAdvertisement> paginitionValues",
          //   paginitionValues
          // );
          setPagination((prev) => ({ ...prev, ...paginitionValues }));
        }
      })
      .catch((err) => {
        setError(err);
        setPending(false);
      });
  };
  const columns = [
    {
      id: 1,
      name: t("Ad_Add.Name"),
      key: "name",
    },
    {
      id: 2,
      name: t("Ad_Details.adType"),
      key: "type",
    },
    {
      id: 3,
      name: t("Ad_index.commonTable.columns.cost_forecast"),
      key: "cost_forecast",
    },
    {
      id: 4,
      name: t("Ad_index.commonTable.columns.startDate"),
      key: "start_date",
    },
    {
      id: 5,
      name: t("Ad_index.commonTable.columns.endDate"),
      key: "end_date",
    },
    {
      id: 6,
      name: t("Ad_index.commonTable.columns.ad_exposures"),
      key: "ad_exposures",
    },
    {
      id: 6,
      name: t("Ad_index.commonTable.columns.actions"),
      key: "action",
    },
  ];

  const OnlineRows = Array.isArray(adDetails?.data)
    ? adDetails.data.filter((item) => item.country === `${countryName}`)
    : [];

  // console.log("selectedItemId", OnlineRows);
  const AllRowData = Array.isArray(OnlineRows)
    ? OnlineRows?.map((index) => {
        return {
          name: (
            <Link
              to={`/advertisement/detail/${index?._id}?id=1`}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {index.title}
            </Link>
          ),
          type: index.type,
          cost_forecast:
            index.campaign_cost || t("Ad_index.commonTable.rows.noData"),
          start_date: getDateWithTimeFormat(index.start_at),
          end_date: getDateWithTimeFormat(index.closing_at),
          ad_exposures: isIntegerValue(index.total_adExposure),

          action: (
            <IconButton
              LinkComponent={Link}
              to={`/advertisement/detail/${index?._id}?id=1`}
              disableRipple
              sx={{
                background: "#322E27",
                borderRadius: "10px",
                width: "30px",
                height: "30px",
              }}
            >
              <SVG.EyeIcon />
            </IconButton>
          ),
        };
      })
    : [];

  const PendingData = Array.isArray(OnlineRows)
    ? OnlineRows?.filter((item) => item.status === "pending").map((index) => {
        return {
          name: (
            <Link
              to={`/advertisement/detail/${index?._id}?id=2`}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {index.title}
            </Link>
          ),
          type: index.type,
          cost_forecast:
            index.campaign_cost || t("Ad_index.commonTable.rows.noData"),
          start_date: getDateWithTimeFormat(index.start_at),
          end_date: getDateWithTimeFormat(index.closing_at),
          ad_exposures: isIntegerValue(index.total_adExposure),

          action: (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <IconButton
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
                onClick={() => handleModal(index._id)}
              >
                <SVG.Delete />
              </IconButton>
              <IconButton
                LinkComponent={Link}
                to={`/advertisement/edit/${index?._id}?id=2`}
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
              >
                <SVG.Pencil />
              </IconButton>
              <IconButton
                LinkComponent={Link}
                to={`/advertisement/detail/${index?._id}?id=2`}
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
              >
                <SVG.EyeIcon />
              </IconButton>
              {/* Delete confirmation modal */}
            </Stack>
          ),
        };
      })
    : [];

  const ActiveData = Array.isArray(OnlineRows)
    ? OnlineRows?.filter(
        (item) =>
          item.status === "approved" && item.subStatus === "VIDEO_UPLOADED"
      ).map((index) => {
        return {
          name: (
            <Link
              to={`/advertisement/detail/${index?._id}?id=3`}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {index.title}
            </Link>
          ),
          type: index.type,
          cost_forecast:
            index.campaign_cost || t("Ad_index.commonTable.rows.noData"),
          start_date: getDateWithTimeFormat(index.start_at),
          end_date: getDateWithTimeFormat(index.closing_at),
          ad_exposures: isIntegerValue(index.total_adExposure),

          action: (
            <IconButton
              LinkComponent={Link}
              to={`/advertisement/detail/${index?._id}?id=3`}
              disableRipple
              sx={{
                background: "#322E27",
                borderRadius: "10px",
                width: "30px",
                height: "30px",
              }}
            >
              <SVG.EyeIcon />
            </IconButton>
          ),
        };
      })
    : [];

  const ApprovedData = Array.isArray(OnlineRows)
    ? OnlineRows?.filter(
        (item) =>
          item.status === "approved" && item.subStatus === "AWAITING_UPLOAD"
      ).map((index) => {
        return {
          name: (
            <Link
              to={`/advertisement/detail/${index?._id}?id=4`}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {index.title}
            </Link>
          ),
          type: index.type,
          cost_forecast: " 3,500",
          start_date: getDateWithTimeFormat(index.start_at),
          end_date: getDateWithTimeFormat(index.closing_at),
          ad_exposures: isIntegerValue(index.total_adExposure),

          action: (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <IconButton
                LinkComponent={Link}
                to={`/advertisement/detail/${index?._id}?id=4`}
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
              >
                <SVG.EyeIcon />
              </IconButton>
            </Stack>
          ),
        };
      })
    : [];

  const UnapprovedData = Array.isArray(OnlineRows)
    ? OnlineRows?.filter(
        (item) => item.status === "unapproved" || item.subStatus === "null"
      ).map((index) => {
        return {
          name: (
            <Link
              to={`/advertisement/detail/${index?._id}?id=5`}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {index.title}
            </Link>
          ),
          type: index.type,
          cost_forecast: index.campaign_cost,
          start_date: getDateWithTimeFormat(index.start_at),
          end_date: getDateWithTimeFormat(index.closing_at),
          ad_exposures: isIntegerValue(index.total_adExposure),

          action: (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <IconButton
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
                onClick={() => handleModal(index._id)}
              >
                <SVG.Delete />
              </IconButton>
              <IconButton
                LinkComponent={Link}
                to={`/advertisement/edit/${index?._id}?id=6`}
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
              >
                <SVG.Pencil />
              </IconButton>
              <IconButton
                LinkComponent={Link}
                to={`/advertisement/detail/${index?._id}?id=6`}
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
              >
                <SVG.EyeIcon />
              </IconButton>
            </Stack>
          ),
        };
      })
    : [];

  const ExpiredData = Array.isArray(OnlineRows)
    ? OnlineRows?.filter(
        (item) =>
          item.status === "expired" && item.subStatus === "AWAITING_REMOVAL"
      ).map((index) => {
        return {
          name: (
            <Link
              to={`/advertisement/detail/${index?._id}?id=5`}
              style={{ color: "#fff", textDecoration: "none" }}
            >
              {index.title}
            </Link>
          ),
          type: index.type,
          cost_forecast: index.campaign_cost,
          start_date: getDateWithTimeFormat(index.start_at),
          end_date: getDateWithTimeFormat(index.closing_at),
          ad_exposures: isIntegerValue(index.total_adExposure),

          action: (
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <IconButton
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
                onClick={() => handleModal(index._id)}
              >
                <SVG.Delete />
              </IconButton>
              <IconButton
                LinkComponent={Link}
                to={`/advertisement/edit/${index?._id}?id=5`}
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
              >
                <SVG.Pencil />
              </IconButton>
              <IconButton
                LinkComponent={Link}
                to={`/advertisement/detail/${index?._id}?id=5`}
                disableRipple
                sx={{
                  background: "#322E27",
                  borderRadius: "10px",
                  width: "30px",
                  height: "30px",
                }}
              >
                <SVG.EyeIcon />
              </IconButton>
            </Stack>
          ),
        };
      })
    : [];

  return (
    <>
      {/* {pending && <CustomLoader />} */}
      <Typography
        variant="h1"
        sx={{
          fontSize: "30px",
          color: "#fff",
          fontWeight: "600",
          mb: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          "@media(max-width:992px)": {
            fontSize: "24px",
          },
        }}
      >
        <span>{t("Ad_index.advertisements.title")}</span>
        <Button
          sx={{
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            background: "#322E27",
            color: "#fff",
            borderRadius: "8px",
            textTransform: "capitalize",
            "&:hover": {
              background: "#322E27",
              color: "#fff",
            },
          }}
          LinkComponent={Link}
          to="/advertisement/detail/add"
        >
          <AddIcon />{" "}
          <span>{t("Ad_index.advertisements.addAdvertisement.add")}</span>
        </Button>
      </Typography>
      <AdvertisementTabs handleTabs={handleTabs} tabs={tabs} />
      {tabs === 1 && (
        <>
          {matches ? (
            <AdMobileTable
              rowData={OnlineRows || []}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          ) : (
            <CommanTable
              columns={columns}
              rows={AllRowData || []}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          )}
        </>
      )}
      {tabs === 2 && (
        <>
          {matches ? (
            <AdMobileTable
              rowData={
                OnlineRows?.filter((item) => item.status === "pending") || []
              }
              handleModal={handleModal}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          ) : (
            <CommanTable
              columns={columns}
              rows={PendingData || []}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          )}
        </>
      )}
      {tabs === 3 && (
        <>
          {matches ? (
            <AdMobileTable
              rowData={
                OnlineRows?.filter(
                  (item) =>
                    item.status === "approved" &&
                    item.subStatus === "VIDEO_UPLOADED"
                ) || []
              }
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          ) : (
            <CommanTable
              columns={columns}
              rows={ActiveData || []}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          )}
        </>
      )}
      {tabs === 4 && (
        <>
          {matches ? (
            <AdMobileTable
              rowData={
                OnlineRows?.filter(
                  (item) =>
                    item.status === "approved" &&
                    item.subStatus === "AWAITING_UPLOAD"
                ) || []
              }
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          ) : (
            <CommanTable
              columns={columns}
              rows={
                ApprovedData ||
                t("Billboard_index.typography.no_data_available")
              }
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          )}
        </>
      )}
      {tabs === 5 && (
        <>
          {matches ? (
            <AdMobileTable
              rowData={
                OnlineRows?.filter(
                  (item) =>
                    item.status === "unapproved" || item.subStatus === "null"
                ) || []
              }
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          ) : (
            <CommanTable
              columns={columns}
              rows={UnapprovedData || []}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          )}
        </>
      )}
      {tabs === 6 && (
        <>
          {matches ? (
            <AdMobileTable
              rowData={
                OnlineRows?.filter(
                  (item) =>
                    item.status === "expired" &&
                    item.subStatus === "AWAITING_REMOVAL"
                ) || []
              }
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          ) : (
            <CommanTable
              columns={columns}
              rows={ExpiredData || []}
              currentPage={pagination.page}
              totalPages={pagination.totalPages}
              handlePageChange={(page) =>
                setPagination((prev) => ({ ...prev, page }))
              }
              isLoading={pending}
            />
          )}
        </>
      )}
      {/* Render your item data here */}
      <DeleteConfirmationModal
        open={showModal}
        onClose={handleModal}
        ItemName={selectedItemId}
        refatch={refatch}
      />
    </>
  );
}
