import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import {
  callHttpRequest,
  methodType,
} from "../../../utility-files/api-caller/HttpRequest";
import { getRequestForApi } from "../../../utility-files/api-caller/CommonRequest";
import * as global from "../../../constant/global";
import { toast } from "react-toastify";
import CustomLoader from "../../../CustomLoader/CustomLoader";
import * as StorageHandling from "../../../utility-files/storage-util/StorageHandling";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

export default function PhoneLogin() {
  const { t } = useTranslation();
  const [pending, setPending] = useState(false);
  const [rememberme, setRememberme] = useState(true);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const navigate = useNavigate();
  const [captchaValue, setCaptchaValue] = useState(null); // reCAPTCHA state
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  const handlePhoneChange = (value, country) => {
    const phoneNumber = value.slice(country.dialCode.length);
    setPhone(phoneNumber);
    setCountryCode(country.dialCode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Ensure captcha should be checked
    if (!captchaValue) {
      toast.error("Please complete the reCAPTCHA verification.");
      return;
    }
    setPending(true);

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("phone", `${countryCode}${phone}`);
      if (captchaValue) {
        formDataToSend.append("token", captchaValue);
      }
      const request = getRequestForApi(
        global.OTP_LOGIN,
        formDataToSend,
        methodType.POST
      );

      const response = await callHttpRequest(request);
      if (response.status === 200) {
        toast.success(t("auth_email.placeholders.emailSuccess"));
        StorageHandling.setStorageData(
          StorageHandling.storageType,
          StorageHandling.storageKey.USERPHONE,
          JSON.stringify({ phone, countryCode })
        );
        StorageHandling.setStorageData(
          StorageHandling.storageType,
          StorageHandling.storageKey.rememberMe,
          rememberme
        );
        navigate("/otp");
        setPhone("");
      } else {
        toast.error(t("auth_email.errors.invalidPhone"));
      }
    } catch (error) {
      if (error.response.status === 404) {
        toast.error(t("auth_email.errors.noUser"));
      } else if (error.response.status === 409) {
        toast.error("reCAPTCHA verification expired");
      } else toast.error("Something went wrong !");
      console.error("Error sending data to API:", error);
    } finally {
      setPending(false);
    }
  };

  // Handle captcha value
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value); // Set the captcha token
  };

  return (
    <>
      {pending && <CustomLoader />}
      <Stack
        direction={"column"}
        spacing={2}
        component="form"
        onSubmit={handleSubmit}
      >
        <FormGroup>
          <FormLabel>{t("Auth_phone.Phone")}</FormLabel>
          <PhoneInput
            country={"in"}
            value={`${countryCode}${phone}`}
            onChange={handlePhoneChange}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
          />
        </FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberme}
              onChange={() => setRememberme(!rememberme)}
            />
          }
          label={t("Auth_phone.Remember Me")}
        />
        <FormGroup>
          <ReCAPTCHA
            sitekey={recaptchaSiteKey} // Replace with your actual site key
            onChange={handleCaptchaChange}
          />
        </FormGroup>
        <Box sx={{ pt: 2 }}>
          <Button
            fullWidth
            variant="contained"
            sx={{
              background:
                "linear-gradient(180deg, rgba(247,196,9,1) 0%, rgba(180,76,6,1) 100%)",
              color: "#fff !important",
            }}
            type="submit"
            disabled={!phone || pending}
          >
            {pending ? t("Auth_phone.Sending...") : t("Auth_phone.Send OTP")}
          </Button>
        </Box>
      </Stack>
    </>
  );
}
