import { Box, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export default function Modal(props) {
  return (
    <>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <Box sx={{ textAlign: "right", mt: 2, mr: 2 }}>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              color: "#0A1629",
              borderRadius: "10px",
              background: "#F4F4F4",
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {props.children}
      </Dialog>
    </>
  );
}
