export const es = {
  Ad_Add: {
    CustomLoader: "Cargando...",
    Uploader: "Cargador",
    VideoTitleHere: "Título del vídeo aquí",
    AddType: "Tipo de anuncio",
    Local: "Local",
    Global: "Global",
    Partner: "Socio",
    AdvertiserDetails: "Detalles del anunciante",
    SelectAdvertiser: "Seleccionar anunciante",
    Name: "Nombre",
    PhoneNumber: "Número de teléfono",
    PhoneNumberPlaceholder: "Escriba el tipo de número de teléfono aquí",
    CampaignDetails: "Detalles de la campaña",
    CampaignStartDate: "Fecha de inicio de la campaña",
    CampaignEndDate: "Fecha de fin de la campaña",
    SelectABillboard: "Seleccionar una valla publicitaria",
    AutomaticCalculation: "Cálculo automático",
    SalesAgentProposal: "Propuesta del agente de ventas",
    Upload: "Subir",
    UploadFiles: "Subir archivos",
    Save: "Guardar",
    YouShouldKnow: "Debe saber:",
    OnlyVideosShouldBeUploaded: "Solo se deben subir vídeos",
    VideoResolutionRequirement:
      "El vídeo debe tener una resolución de 1:2 PX o ser un vídeo de TikTok",
    VideoDurationRequirement: "El vídeo debe durar hasta 30 segundos",
    CompanyPolicyRequirement:
      "No se aprobará el vídeo que contradiga la política de la empresa",
    adUploadSuccess: "Anuncio cargado exitosamente",
    adUploadFail:
      "Error al cargar el anuncio. Por favor, intente de nuevo más tarde.",
    adUploadFail2: "Error al cargar el anuncio. Inténtelo de nuevo más tarde.",
    partners: "Fogonadura",
    endDateError:
      "La fecha de finalización de la campaña debe ser posterior a la fecha de inicio",
    startDateValidation: "Por favor, seleccione la fecha de inicio",
    endDateValidation: "Por favor, seleccione la fecha de finalización",
  },
  Ad_Details: {
    advertisementDetail: "Detalle del anuncio",
    advertiserDetails: "Detalles del anunciante",
    noDataAvailable: "No hay datos disponibles",
    phone: "Teléfono",
    email: "Correo electrónico",
    campaignDetails: "Detalles de la campaña",
    campaignPeriod: "Periodo de la campaña",
    from: "Desde",
    to: "Hasta",
    billboard: "Valla publicitaria",
    createdAt: "Creado en",
    campaignCost: "Costo de la campaña",
    automaticCalculation: "Cálculo automático",
    agentProposal: "Propuesta del agente",
    finalCost: "Costo final",
    videoAdPreview: "Vista previa del anuncio en vídeo",
    date: "Fecha",
    adExposure: "Exposición del anuncio",
    totalExposure: "Exposición total",
    dataNotAvailable: "Datos no disponibles",
    adType: "Tipo",
    adStatus: "Estado",
  },
  Ad_Edit: {
    advertisement_update: {
      title: "Actualización del anuncio",
      video_title_label: "Título del vídeo aquí",
      add_type_label: "Tipo de anuncio",
      advertiser_details_title: "Detalles del anunciante",
      select_advertiser: "Seleccionar anunciante",
      advertiser_name_label: "Nombre",
      phone_number_label: "Número de teléfono",
      email_label: "Correo electrónico",
      campaign_details_title: "Detalles de la campaña",
      campaign_start_date_label: "Fecha de inicio de la campaña",
      campaign_end_date_label: "Fecha de fin de la campaña",
      select_billboard_label: "Seleccionar una valla publicitaria *",
      automatic_calculation_label: "Cálculo automático",
      sales_agent_proposal_label: "Propuesta del agente de ventas",
      upload_title: "Subir *",
      upload_files_text: "Subir archivos",
      resubmit_button_text: "Reenviar",
      you_should_know_title: "Debe saber:",
      you_should_know_text: [
        "Solo se deben subir vídeos",
        "El vídeo debe tener una resolución de 1:2 PX o ser un vídeo de TikTok",
        "El vídeo debe durar hasta 30 segundos",
        "No se aprobará el vídeo que contradiga la política de la empresa",
      ],
    },
  },
  Ad_index: {
    advertisements: {
      title: "Anuncios",
      tabs: {
        all: "Todos",
        pending: "Pendiente",
        approved: "Aprobado",
        rejected: "Rechazado",
      },
      billboardCount: {
        totalBillboards: "Vallas publicitarias totales",
        activeBillboards: "Vallas publicitarias activas",
        inactiveBillboards: "Vallas publicitarias inactivas",
        billboardsInMaintenance: "Vallas publicitarias en mantenimiento",
      },
      addAdvertisement: {
        add: "Agregar",
        advertisements: "Anuncios",
      },
      billboard: {
        title: "Valla publicitaria",
        video: {
          src: "https://www.youtube.com/embed/0njtNjgy-cA?si=DyHFmHets3EahWqx",
          title: "Reproductor de vídeos de YouTube",
        },
      },
    },
    commonTable: {
      columns: {
        id: "ID",
        title: "Título",
        type: "Tipo",
        startDate: "Fecha de inicio",
        endDate: "Fecha de fin",
        status: "Estado",
        actions: "Acciones",
        cost_forecast: "Pronóstico de Costos",
        ad_exposures: "Exposiciones de Anuncios",
      },
      rows: {
        noData: "No hay datos disponibles",
      },
    },
  },
  Ad_main: {
    advertisements: {
      title: "Anuncios",
      tabs: {
        all: "Todos",
        pending: "Pendiente",
        active: "Activo",
        approved: "Aprobado",
        unapproved: "No aprobado",
        expired: "Caducado",
      },
      addButton: {
        add: "Agregar",
      },
    },
    commonTable: {
      columns: {
        id: "ID",
        title: "Título",
        type: "Tipo",
        startDate: "Fecha de inicio",
        endDate: "Fecha de fin",
        status: "Estado",
        actions: "Acciones",
      },
      rows: {
        noData: "No hay datos disponibles",
      },
    },
    deleteConfirmationModal: {
      open: "Abrir",
      close: "Cerrar",
      itemName: "Nombre del elemento",
      refetch: "Refrescar",
    },
  },
  Ad_tabs: {
    advertisements: "Anuncios",
    add: "Agregar",
    all: "Todos",
    pending: "Pendiente",
    active: "Activo",
    approved: "Aprobado",
    unapproved: "No aprobado",
    expired: "Caducado",
  },
  auth_email: {
    labels: {
      email: "Correo electrónico",
      password: "Contraseña",
      rememberMe: "Recuérdame",
      submitButton: "Enviar",
      submittingButton: "Enviando...",
      showPassword: "Mostrar contraseña",
      hidePassword: "Ocultar contraseña",
      forgotPassword: "¿Olvidó su contraseña?",
    },
    placeholders: {
      email: "Ingrese su correo electrónico",
      password: "Ingrese su contraseña",
      emailSuccess:
        "Código de verificación enviado a su correo electrónico con éxito.",
      emailFail: "Credenciales no válidas. Inténtalo de nuevo.",
    },
    errors: {
      requiredField: "Este campo es obligatorio",
      invalidEmail: "Formato de dirección de correo electrónico inválido",
      invalidPass: "La contraseña debe tener al menos 8 caracteres.",
      invalidPhone:
        "No se pudo enviar el código de verificación. Inténtalo nuevamente.",
      noUser: "Usuario no encontrado.",
    },
  },
  Auth_phone: {
    Email: "Correo electrónico",
    Password: "Contraseña",
    "Show Password": "Mostrar contraseña",
    "Hide Password": "Ocultar contraseña",
    "Remember Me": "Recuérdame",
    "Submitting...": "Enviando...",
    Submit: "Enviar",
    "Forgot Password?": "¿Olvidó su contraseña?",
    Phone: "Teléfono",
    "Send OTP": "Enviar OTP",
    "Sending...": "Enviando...",
  },
  Auth_forgotPassword: {
    Advertisements: "Anuncios",
    Add: "Agregar",
    "Forgot Password?": "¿Olvidó su contraseña?",
    "Don’t worry! It happens. Please enter the email address associated with your account":
      "¡No se preocupe! Sucede. Por favor, introduzca la dirección de correo electrónico asociada con su cuenta",
    Email: "Correo electrónico",
    Submit: "Enviar",
    "Send OTP": "Enviar OTP",
    Phone: "Teléfono",
    "Remember Me": "Recuérdame",
    "Show Password": "Mostrar contraseña",
    "Hide Password": "Ocultar contraseña",
    success: "Enlace para restablecer contraseña enviado a tu correo",
  },
  Auth_index: {
    forgot_password_title: "¿Olvidó su contraseña?",
    forgot_password_subtitle:
      "¡No se preocupe! Sucede. Por favor, introduzca la dirección de correo electrónico asociada con su cuenta",
    forgot_password_email_label: "Correo electrónico",
    forgot_password_submit_button: "Enviar",
    login_with_email_title: "Iniciar sesión con correo electrónico",
    login_with_phone_title: "Iniciar sesión con teléfono",
    email_login_button: "Iniciar sesión con correo electrónico",
    phone_login_button: "Iniciar sesión con teléfono",
    email_placeholder: "Ingrese su correo electrónico",
    send_otp_button: "Enviar OTP",
    submit_button: "Enviar",
    show_password_button: "Mostrar contraseña",
    hide_password_button: "Ocultar contraseña",
    remember_me_label: "Recuérdame",
    phone_label: "Teléfono",
    form_error_required: "Este campo es obligatorio",
    form_error_email_invalid:
      "Por favor, introduzca una dirección de correo electrónico válida",
    form_error_password_length:
      "La contraseña debe tener al menos 6 caracteres",
  },
  Auth_otp: {
    forgot_password: {
      title: "¿Olvidó su contraseña?",
      instruction:
        "¡No se preocupe! Sucede. Por favor, introduzca la dirección de correo electrónico asociada con su cuenta",
      email_placeholder: "Ingrese su correo electrónico",
      submit_button: "Enviar",
    },
    login: {
      email: "Iniciar sesión con correo electrónico",
      phone: "Iniciar sesión con teléfono",
      email_login_button: "Iniciar sesión con correo electrónico",
      phone_login_button: "Iniciar sesión con teléfono",
    },
    otp_page: {
      title: "Código de verificación",
      otp_label: "Código de verificación de 4 dígitos",
      otp_placeholder: "TEXTO",
      sign_in_button: "Iniciar sesión",
      otpSuccess: "Código de verificación verificado exitosamente.",
      otpFail: "No se pudo verificar el código de verificación",
      otpValidation: "Por favor, introduzca un código de verificación válido",
      otpResend: "Código de verificación enviado exitosamente",
      resendDisable: "¿No has recibido el código? Vuelve a enviarlo.",
      sec: "segundo",
      resendButton: "Reenviar",
      verifyButton: "Verificando...",
    },
  },
  Auth_resetPassword: {
    ResetPassword: {
      title: "Restablecer contraseña",
      newPassword: "Nueva contraseña",
      confirmPassword: "Confirmar contraseña",
      submitButton: "Enviar",
      validationMessages: {
        passwordLength: "Se requieren al menos 8 caracteres alfanuméricos",
        passwordMismatch: "Las contraseñas no coinciden",
      },
    },
  },
  Billboard_main: {
    Billboards: {
      title: "Vallas publicitarias",
      tabs: {
        all: "Todos",
        someTab1: "Alguna pestaña 1",
        someTab2: "Alguna pestaña 2",
        offline: "Desconectado",
        online: "En línea",
        billboard_address: "Dirección de la cartelera",
        owner: "Propietario",
        installation: "Instalación",
        ads_amount: "Cantidad de anuncios",
        ads_exposures: "Exposiciones de anuncios",
        offline_started: "Desconexión iniciada",
        linked_tickets: "Tickets vinculados",
      },
      buttons: {
        arrowRight: "Flecha derecha",
      },
    },
  },
  Billboard_Edit: {
    BillboardTraffic: {
      title: "Tráfico de vallas publicitarias",
      placeholder: {
        billboardName: "Nombre de la valla publicitaria",
        contactName: "Nombre del contacto",
        address: "Dirección",
        phoneNumber: "Número de teléfono",
        email: "Correo electrónico",
        remark: "Escriba aquí",
        uploadFiles: "Subir archivos",
      },
      labels: {
        billboardLocation: "Ubicación de la valla publicitaria",
        contactDetails: "Detalles del contacto",
        remarks: "Observaciones",
        uploadGalleryImages: "Subir imágenes de la galería",
        advertisingContent: "Contenido publicitario",
        amountAdsDisplayed: "Cantidad de anuncios mostrados",
        spotsPerAdPerDay: "Puntos por anuncio por día",
        installationDate: "Fecha de instalación",
        nextPayment: "Próximo pago",
        monthlyPayment: "Pago mensual",
      },
      messages: {
        imageUploadSuccess: "Carga de imagen exitosa",
        remarkSuccess: "Comentario creado con éxito",
        error: "¡Error!",
      },
      buttons: {
        save: "Guardar",
      },
    },
  },
  Billboard_index: {
    typography: {
      billboards: "Vallas publicitarias",
      installation_date: "Fecha de instalación",
      next_payment: "Próximo pago",
      edit_billboard: "Editar valla publicitaria",
      billboard_ads: "Anuncios en vallas publicitarias",
      views_and_impressions: "Vistas e impresiones",
      offline_heat_map: "Mapa de calor fuera de línea",
      billboard_location: "Ubicación de la valla publicitaria",
      interaction: "Interacción",
      qr_code: "Código QR",
      remarks: "Observaciones",
      payments: "Pagos",
      spot_ad_daily: "Anuncio diario",
      gallery: "Galería",
      no_data_available: "No hay datos disponibles",
      click_here_to_open_link: "Haga clic aquí para abrir el enlace",
      download: "Descargar",
      rushHour: "Hora punta de hoy",
      week: "Esta semana",
      month: "Este mes",
      year: "Este año",
      activeAd: "Anuncios activos",
    },
    select: {
      no_address_available: "No hay dirección disponible",
    },
    date_format: {
      date: "Fecha",
    },
    button: {
      download: "Descargar",
      sizes: ["300 *300", "400*400", "500*500", "1000*1000", "2000*2000"],
    },
    modal: {
      social: {
        facebook: "Facebook",
        whatsapp: "WhatsApp",
        linkedin: "LinkedIn",
        email: "Correo electrónico",
        tel: "Teléfono",
      },
    },
    interaction: {
      qr_code: "Código QR",
    },
    formats: {
      amount: "Cantidad",
    },
  },
  Billboard_mapView: {
    errors: {
      coordinatesUnavailable: "Coordenadas no disponibles",
      mapContainerUnavailable: "Contenedor del mapa no disponible",
    },
    messages: {
      noLocationAvailable: "No hay ubicación disponible",
    },
  },
  Dashboard: {
    components: {
      customLoader: "Cargando...",
      billboardPlacement: "Colocación de vallas publicitarias",
      dashboard: "Tablero",
      now: "Ahora",
      lastUpdate: "Última actualización",
      dailyStatistics: "Estadísticas diarias",
      rushHour: "Hora punta",
      impressions: "Impresiones",
      map: "Mapa",
      goToMap: "Ir al mapa",
      impressionsTitle: "Impresiones",
      billboardStatus: "Estado de la valla publicitaria",
      clearAll: "Borrar todo",
      respond: "Responder",
      noMessage: "no hay mensajes nuevos",
      notification: "Notificaciones",
      read: "Leer",
      noNotification: "No hay ninguna notificación nueva",
      online: "En línea",
      offline: "Desconectado",
      profile: "Perfil",
      logout: "Cerrar sesión",
    },
  },
  modal: {
    components: {
      dialog: {
        deleteConfirmation: "Confirmación de eliminación",
        close: "Cerrar",
        areYouSure: "¿Está seguro de que desea eliminar?",
        cancel: "Cancelar",
        delete: "Eliminar",
        deleteSuccess: "Anuncio eliminado con éxito.",
        deleteFail:
          "No se pudo eliminar el anuncio. Por favor, inténtelo de nuevo.",
      },
    },
  },
  profile: {
    components: {
      userProfile: {
        userProfile: "Perfil de usuario",
        name: "Nombre completo",
        email: "Correo electrónico",
        phoneNumber: "Número de teléfono",
        country: "País",
        city: "Ciudad",
        address: "Dirección",
        zipCode: "Código postal",
        save: "Guardar",
        edit: "Editar",
      },
      changePassword: {
        changePassword: "Cambiar contraseña",
        newPassword: "Nueva contraseña",
        confirmPassword: "Confirmar contraseña",
        submitting: "Enviando...",
        save: "Guardar",
        passValidation: "La contraseña debe tener al menos 8 caracteres",
        confPassValidation: "Las contraseñas no coinciden",
      },
      common: {
        cancel: "Cancelar",
        delete: "Eliminar",
        areYouSure: "¿Está seguro de que desea eliminar?",
        updateSuccess: "Los detalles del usuario se actualizaron correctamente",
        passSuccess: "Cambio de contraseña exitoso",
      },
    },
  },
  Ticket_add: {
    add_ticket: {
      title: "Agregar ticket",
      labels: {
        ticket_name: "Nombre del ticket",
        ticket_description: "Descripción del ticket",
        billboard_name: "Nombre de la valla publicitaria",
        closing_date: "Fecha de cierre",
        ticket_type: "Tipo de ticket",
        ticket_subtype: "Subtipo de ticket",
        ticket_assignee: "Asignado del ticket",
        ticket_followers: "Seguidores del ticket",
      },
      placeholders: {
        ticket_name: "Nombre del ticket",
        ticket_description: "Descripción",
        billboard_name: "Seleccionar valla publicitaria",
        ticket_type: "Seleccionar tipo de ticket",
        ticket_subtype: "Seleccionar subtipo de ticket",
      },
      buttons: {
        approve: "Aprobar",
        save: "Guardar",
        cancel: "Cancelar",
      },
      errors: {
        ticket_name: "Se requiere nombre del ticket",
        description: "Se requiere descripción",
        billboard_name: "Se requiere nombre de la valla publicitaria",
        closing_date: "Se requiere fecha de cierre",
        ticket_type: "Se requiere tipo de ticket",
        ticket_subtype: "Se requiere subtipo de ticket",
        ticket_assignee: "Se requiere asignado del ticket",
        ticket_followers: "Se requieren seguidores del ticket",
        duplicateName: "Este nombre de ticket ya está tomado",
        ticketSuccess: "Ticket creado exitosamente",
      },
      admin_roles: ["Admin", "SuperAdmin"],
    },
  },
  Ticket_allTicket: {
    search: "Buscar",
    billboard_sort: "Ordenar por valla publicitaria",
    ticket_id: "ID del ticket:",
    created_at: "{date}",
    ticket_name: "{name}",
    message_prefix: "{name}: {message}",
    tickets_title: "Tickets",
    messages_tab: "Mensajes",
    details_tab: "Detalles",
  },
  Ticket_details: {
    "Add Ticket": "Agregar ticket",
    "Ticket Name": "Nombre del ticket",
    "Ticket Description": "Descripción del ticket",
    "Billboard Name": "Nombre de la valla publicitaria",
    "Closing Date": "Fecha de cierre",
    "Ticket Type": "Tipo de ticket",
    "Ticket Subtype": "Subtipo de ticket",
    "Ticket Assignee": "Asignado del ticket",
    "Ticket Followers": "Seguidores del ticket",
    Search: "Buscar",
    Messages: "Mensajes",
    Details: "Detalles",
    Less: "Menos",
    More: "Más",
    "Ticket Assignee:": "Asignado del ticket:",
    creationDate: "Fecha de creación:",
  },
  Ticket_index: {
    tickets: {
      title: "Tickets",
      tabs: {
        all: "Todos los tickets",
        approve: "Tickets aprobados",
        unapprove: "Tickets no aprobados",
        close: "Tickets cerrados",
        disapprove: "Tickets desaprobados",
        archieve: "Tickets archivados",
        inProgress: "Entradas en proceso",
        completed: "Entradas completadas",
      },
      addButton: "Agregar",
      noTicketsMessage: "No hay tickets disponibles.",
      ticketCounts: {
        approve: "Aprobar ticket",
        unapprove: "Desaprobar ticket",
        close: "Cerrar ticket",
        disapprove: "Desaprobar ticket",
        archieve: "Archivar ticket",
      },
      drawer: {
        title: "Agregar ticket",
      },
    },
    common: {
      loaders: {
        custom: "Cargando...",
        fidgetSpinner: "Cargando...",
      },
      buttons: {
        approve: "Aprobar",
        disapprove: "Desaprobar",
        delete: "Eliminar",
      },
      labels: {
        search: "Buscar",
        billboardSort: "Ordenar por valla publicitaria",
      },
      messages: {
        more: "Más",
        less: "Menos",
      },
      placeholders: {
        search: "Buscar...",
      },
      statuses: {
        approve: "Aprobado",
        close: "Cerrado",
      },
      ticketDetails: {
        assignee: "Asignado del ticket",
        followers: "Seguidores del ticket",
      },
    },
  },
  Ticket_message: {
    header: {
      tickets: "Tickets",
    },
    tabs: {
      approve: "Aprobar",
      unapprove: "Desaprobar",
      close: "Cerrar",
      disapprove: "Desaprobar",
      archieve: "Archivar",
    },
    buttons: {
      add: "Agregar",
    },
    messages: {
      noTickets: "No hay tickets disponibles.",
      uploadingFile: "Subiendo archivo...",
      typeHere: "Escriba aquí...",
      cannotChatArchived: "No puede chatear en un ticket archivado.",
      cannotChatClosed: "No puede chatear en un ticket cerrado.",
      cannotChatDisapproved: "No puede chatear en un ticket desaprobado.",
      cannotChatUnapproved: "No puede chatear en un ticket no aprobado.",
      uploadFail: "No se puede cargar el archivo.",
      sizeExceed: "El tamaño del archivo supera los 15 MB",
    },
  },
};
