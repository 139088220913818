"use client";
import { useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "./typography";
import ComponentsOverrides from "./overrides";

export default function ThemeCustomization({ children }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const themeTypography = Typography(`"Manrope", sans-serif`);
  const themeTypography = Typography(
    `${
      localStorage.getItem("i18nextLng") === "ar"
        ? `"Cairo", sans-serif`
        : `"Manrope", sans-serif`
    }`
  );
  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1536,
        },
      },

      typography: themeTypography,
    }),
    [themeTypography]
  );

  const themes = createTheme(themeOptions);
  themes.components = ComponentsOverrides();

  return <ThemeProvider theme={themes}>{children}</ThemeProvider>;
}
