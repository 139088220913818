import React from "react";
import ReactECharts from "echarts-for-react";
import * as echarts from "echarts";
import { Box, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PieChart({ totalCount, OnlineCount, OfflineCount }) {
  const { t } = useTranslation();
  const option = {
    tooltip: {
      show: true,
      trigger: "item",
      formatter: "{b}: {c} ({d}%)",
      backgroundColor: "#333",
      textStyle: {
        color: "#fff",
        fontFamily:
          localStorage.getItem("i18nextLng") === "ar"
            ? `"Cairo", sans-serif`
            : `"Manrope", sans-serif`,
      },
    },
    legend: {
      top: "-10%",
      left: "center",
      textStyle: {
        color: "#fff",
        fontFamily:
          localStorage.getItem("i18nextLng") === "ar"
            ? `"Cairo", sans-serif`
            : `"Manrope", sans-serif`,
      },
      data: ["Online", "Offline"],
    },
    series: [
      {
        name: "Billboard Status",
        type: "pie",
        radius: ["55%", "80%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "transparent",
          borderWidth: 0,
          fontFamily:
            localStorage.getItem("i18nextLng") === "ar"
              ? `"Cairo", sans-serif`
              : `"Manrope", sans-serif`,
        },
        label: {
          show: true,
          position: "outside",
          formatter: "{b}: {c}",
          color: "#fff",
          fontFamily:
            localStorage.getItem("i18nextLng") === "ar"
              ? `"Cairo", sans-serif`
              : `"Manrope", sans-serif`,
        },
        data: [
          {
            value: OnlineCount,
            name: t("Dashboard.components.online"),
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#F7C409" },
                { offset: 1, color: "#B44C06" },
              ]),
              fontFamily:
                localStorage.getItem("i18nextLng") === "ar"
                  ? `"Cairo", sans-serif`
                  : `"Manrope", sans-serif`,
            },
          },
          {
            value: OfflineCount,
            name: t("Dashboard.components.offline"),
            itemStyle: {
              color: "#FFD023",
            },
          },
        ],
        emphasis: {
          focus: "self",
        },
        labelLine: {
          show: true,
          lineStyle: {
            color: "#fff",
            fontFamily:
              localStorage.getItem("i18nextLng") === "ar"
                ? `"Cairo", sans-serif`
                : `"Manrope", sans-serif`,
          },
        },
      },
    ],
  };

  return (
    <Box sx={{ position: "relative" }}>
      <ReactECharts option={option} />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          fontSize: "18px",
          color: "#fff",
          fontWeight: "600",
        }}
      >
        {totalCount}
      </Box>
      <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            spacing={localStorage.getItem("i18nextLng") === "ar" ? 0 : 1}
            alignItems="center"
          >
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "100%",
                background: "linear-gradient(0deg, #F7C409 0%, #B44C06 100%)",
              }}
            />
            <Box
              sx={{
                color: "#fff",
                mr: localStorage.getItem("i18nextLng") === "ar" ? 1 : 0,
              }}
            >
              {t("Dashboard.components.online")}
            </Box>
          </Stack>
          <Box sx={{ fontSize: "18px", fontWeight: "600" }}>
            <Link
              to={`/billboard?id=3`}
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              {OnlineCount}
            </Link>
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction="row"
            spacing={localStorage.getItem("i18nextLng") === "ar" ? 0 : 0}
            alignItems="center"
          >
            <Box
              sx={{
                width: "12px",
                height: "12px",
                borderRadius: "100%",
                background: "#FFD023",
              }}
            />
            <Box
              sx={{
                color: "#fff",
                mr: localStorage.getItem("i18nextLng") === "ar" ? 1 : 0,
                ml: localStorage.getItem("i18nextLng") === "ar" ? 0 : 1,
              }}
            >
              {t("Dashboard.components.offline")}
            </Box>
          </Stack>
          <Box sx={{ fontSize: "18px", fontWeight: "600" }}>
            <Link
              to={`/billboard?id=2`}
              style={{ color: "#fff", textDecoration: "underline" }}
            >
              {OfflineCount}
            </Link>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
}
