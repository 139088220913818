import { Box, Button, IconButton, Stack, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { SVG } from "../../icon/svg.file";
import { useSelector } from "react-redux";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { stripHtmlTags } from "../../utility-files/helper-function/HelperFunction";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { FidgetSpinner } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

export default function Details({ loading }) {
  const matches = useMediaQuery("(max-width:600px)");
  const { t } = useTranslation();
  const selectedTicket = useSelector((state) => state.selectedTicket);
  const [selectedAssigne, setSelectedAssigne] = useState([]);
  const [selectedFollower, setSelectedFollower] = useState([]);
  const [isText, setIsText] = useState(false);

  const handleTextShow = () => {
    setIsText(!isText);
  };

  let follower_name_full = [];
  let assigned_name_full = [];

  // const handleChangeAssigne = (e) => {
  //   const { value, checked } = e.target;
  //   if (value.includes("all") && !selectedAssigne.includes("all")) {
  //     setSelectedAssigne(checked ? ["all"] : [...props.listOfEmployees, "all"]);
  //   } else if (selectedAssigne.includes("all") && !value.includes("all")) {
  //     setSelectedAssigne([]);
  //   } else {
  //     setSelectedAssigne(value);
  //   }
  // };

  const DescriptionData = [
    {
      Title: t("Ticket_allTicket.ticket_id"),
      level: selectedTicket?.data?.ticket?._id.substr(-5) || "",
    },
    {
      Title: `${t("Ticket_details.Billboard Name")}:`,
      level: selectedTicket?.data?.ticket?.billboard || "",
    },
    {
      Title: t("Ticket_details.creationDate"),
      level: selectedTicket?.data?.ticket?.createdAt
        ? moment(selectedTicket?.data?.ticket?.createdAt).format("DD/MM/YYYY")
        : "NA",
    },
    {
      Title: `${t("Ticket_details.Closing Date")}:`,
      level: selectedTicket?.data?.ticket?.closing_at
        ? moment(selectedTicket?.data?.ticket?.closing_at).format("DD/MM/YYYY")
        : "NA",
    },
    {
      Title: `${t("Ticket_details.Ticket Type")}:`,
      level: selectedTicket?.data?.ticket?.ticket_type || "",
    },
    {
      Title: `${t("Ticket_details.Ticket Subtype")}:`,
      level: selectedTicket?.data?.ticket?.ticket_sub_type || "",
    },
  ];

  // Strip HTML tags from the description
  const description = stripHtmlTags(
    selectedTicket?.data?.ticket?.description || ""
  );
  return (
    <>
      {!selectedTicket?.data ? (
        <FidgetSpinner />
      ) : (
        <>
          {/* {!selectedTicket?.data?.ticket && <CustomLoader />} */}
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              mb: 1,
              textAlign:
                localStorage.getItem("i18nextLng") === "ar" ? "right" : "left",
            }}
          >
            {selectedTicket?.data?.ticket.ticket_name.length > 33
              ? selectedTicket?.data?.ticket.ticket_name.substring(0, 32) +
                "..."
              : selectedTicket?.data?.ticket.ticket_name}
          </Box>
          <Box sx={{ fontSize: "14px", mb: 3 }}>
            <p className="textElps">
              {isText
                ? description
                : description.length > 100
                ? description.substring(0, 99) + "..."
                : description}
              {description.length > 100 ? (
                <span
                  onClick={handleTextShow}
                  className={`expend-text ${isText ? "" : "keybord-rotate"}`}
                >
                  {isText ? "Less" : "More"} <KeyboardArrowUpIcon />
                </span>
              ) : (
                ""
              )}
            </p>
          </Box>
          <Stack
            direction={"column"}
            spacing={1}
            sx={{ color: "#fff", fontSize: "14px" }}
          >
            {DescriptionData?.map((item, index) => (
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                key={index}
              >
                <Box
                  sx={{
                    width: "120px",
                    textAlign:
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "right"
                        : null,
                    ml:
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "10px !important"
                        : null,
                  }}
                >
                  {item.Title}
                </Box>
                <Box>{item.level}</Box>
              </Stack>
            ))}

            <Box>
              <Stack
                direction={"row"}
                spacing={2}
                // sx={{ mt: 2 }}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    width: "120px",
                    textAlign:
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "right"
                        : null,
                  }}
                >
                  {`${t("Ticket_details.Ticket Assignee")}:`}
                </Box>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    flex: 1,
                    mr:
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "16px !important"
                        : null,
                  }}
                >
                  {selectedTicket?.data?.ticketAssignee?.map((index) => (
                    <Stack key={index} direction={"column"} spacing={1}>
                      <Stack
                        direction={"row"}
                        spacing={{ xs: 1, lg: 2 }}
                        alignItems={"center"}
                        justifyContent={{
                          xs: "flex-start",
                          lg: "space-between",
                        }}
                        sx={{
                          background: "#282520",
                          color: "#fff",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          "@media(max-width:992px)": {
                            wordBreak: "break-all",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            ml:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {index?.user?.fullname}
                        </Box>

                        {!matches && <SVG.Email />}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Box>
            <Box>
              <Stack
                direction={"row"}
                spacing={2}
                // sx={{ mt: 2 }}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    width: "120px",
                    textAlign:
                      localStorage.getItem("i18nextLng") === "ar"
                        ? "right"
                        : null,
                  }}
                >
                  {" "}
                  {`${t("Ticket_details.Ticket Followers")}:`}
                </Box>
                <Stack direction="column" spacing={1} sx={{ flex: 1 }}>
                  {selectedTicket?.data?.ticketFollower?.map((index) => (
                    <Stack key={index} direction={"column"} spacing={1}>
                      <Stack
                        direction={{ xs: "column", lg: "row" }}
                        spacing={3}
                        alignItems={"center"}
                        justifyContent={{ xs: "center", lg: "space-between" }}
                        sx={{
                          background: "#282520",
                          color: "#fff",
                          borderRadius: "5px",
                          padding: "10px 15px",
                          "@media(max-width:992px)": {
                            wordBreak: "break-all",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            ml:
                              localStorage.getItem("i18nextLng") === "ar" &&
                              "10px !important",
                          }}
                        >
                          {index?.user?.fullname}
                        </Box>
                        {!matches && <SVG.Email />}
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            </Box>
          </Stack>
          {/* <Stack direction={"row"} spacing={2} alignItems={"center"} sx={{ mt: 5 }}>
        <Button
          sx={{
            background: "#349734",
            textTransform: "capitalize",
            color: "#fff",
            borderRadius: "10px",
            px: 3,
            "&:hover": {
              background: "#349734",
              color: "#fff",
            },
          }}
        >
          Approve
        </Button>
        <Button
          sx={{
            background: "#F01F1F",
            textTransform: "capitalize",
            color: "#fff",
            borderRadius: "10px",
            px: 3,
            "&:hover": {
              background: "#F01F1F",
              color: "#fff",
            },
          }}
        >
          Disapprove
        </Button>
        <IconButton>
          <SVG.Delete />
        </IconButton>
      </Stack> */}
        </>
      )}
    </>
  );
}
