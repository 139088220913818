export default function TextField() {
  return {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            background: "#F0F0F0",
            border: "1px solid #D8DAE1",
            borderRadius: "5px",
            "&:hover": {
              background: "#fff",
            },

            "&:before": {
              display: "none",
            },
            "&:after": {
              display: "none",
            },
          },
          input: {
            "&:-webkit-autofill": {
              "-webkit-box-shadow": "0 0 0 30px #2d2923 inset !important",
              "-webkit-text-fill-color": "#fff !important",
            },
          },

          "& fieldset": {
            display: "none",
          },
        },
      },
    },
  };
}
