/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  Box,
  Dialog,
  DialogContent,
  Button,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { BASE_URL } from "../constant/global";
import { isPlatform } from "@ionic/react";
import CloseIcon from "@mui/icons-material/Close";

export default function GallerySlider({ fileName = [] }) {
  const matches = useMediaQuery("(max-width:768px)");
  const [open, setOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  // Ensure fileName is an array and reverse it to ensure consistent order
  const reversedFileNames = Array.isArray(fileName)
    ? [...fileName].reverse()
    : [];

  const handleClickOpen = (index) => {
    setSelectedImageIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImageIndex(null);
  };

  const handleNext = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex + 1) % reversedFileNames?.length
    );
  };

  const handlePrevious = () => {
    setSelectedImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + reversedFileNames?.length) % reversedFileNames?.length
    );
  };

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  const showDots = reversedFileNames?.length > responsive?.mobile?.items;

  return (
    <>
      {reversedFileNames && reversedFileNames?.length > 0 ? (
        <Box
          sx={{
            "& .react-multi-carousel-list": {
              pb: 3,
            },
          }}
        >
          <Carousel responsive={responsive} showDots={showDots} arrows={false}>
            {reversedFileNames?.map((file, index) => {
              const imageUrl = `${BASE_URL}/media/billboards/${file}`;
              return (
                <Box
                  sx={{ p: 1 }}
                  key={index}
                  onClick={() => handleClickOpen(index)}
                >
                  <img
                    src={imageUrl}
                    alt={`Image ${index}`}
                    style={{
                      width: "100%",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  />
                </Box>
              );
            })}
          </Carousel>
          <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            PaperProps={{
              style: { minWidth: matches ? "100%" : "auto" },
            }}
          >
            <DialogContent
              sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#000",
              }}
            >
              {matches ? (
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={(theme) => ({
                    position: "absolute",
                    right: 27,
                    top: 22,
                    color: "#fff",
                    background: "#000 !important",
                  })}
                >
                  <CloseIcon />
                </IconButton>
              ) : null}
              <Button
                onClick={handlePrevious}
                sx={{
                  position: "absolute",
                  left: matches ? "0px" : "5px",
                  zIndex: 1,
                  color: "white",
                  background: matches ? "#000 !important" : "transparent",
                  minWidth: "auto",
                }}
              >
                <ArrowBack />
              </Button>
              {selectedImageIndex !== null && (
                <img
                  src={`${BASE_URL}/media/billboards/${reversedFileNames[selectedImageIndex]}`}
                  alt="Selected"
                  style={{
                    width:
                      matches || isPlatform("ios") || isPlatform("android")
                        ? "100%"
                        : "600px",
                    height:
                      matches || isPlatform("ios") || isPlatform("android")
                        ? "auto"
                        : "400px",
                    objectFit: "contain",
                  }}
                />
              )}
              <Button
                onClick={handleNext}
                sx={{
                  position: "absolute",
                  right: matches ? "0px" : "5px",
                  zIndex: 1,
                  color: "white",
                  background: matches ? "#000 !important" : "transparent",
                  minWidth: "auto",
                }}
              >
                <ArrowForward />
              </Button>
            </DialogContent>
          </Dialog>
        </Box>
      ) : (
        <div className="centered-div">No Image Available</div>
      )}
    </>
  );
}

GallerySlider.propTypes = {
  fileName: PropTypes?.arrayOf(PropTypes?.string),
};
