

export const rows = [1, 2, 3, 4, 5].map(() => {
  return {
    // billboard_address: "13th Street. 47 W 13th St, New York",
    // owner: "James Burles",
    // offline_started: "03/02/2023 06:30",
    // linked_tickets: "4",
    // action: "action",
  };
});
