export const TicketType = {
    sales: "Sales",
    maintenance: "Maintainance",
    advertising: "Advertising",
    installation: "Installation",
    other: "Other",
};

export const TicketSubType = {
    sales: {
        payment: "Payment",
        contract: "Contract"
    },
    maintenance: {
        repair: "Repair",
        ongoing: "Ongoing"
    },
    advertising: {
        upload_ad: "Upload Ad",
        remove_ad: "Remove Ad"
    },
    installation: {
        installation : 'Installation'
    },
    other : {
        other : 'Other'
    }
};


export const TicketStatus = {
    approve: "Approve",
    close: "Close"
};

export const TicketCloseReson = {
    'payment has been transfered' : 'TRANSFERRED',
    'a contract was signed': 'SIGNED',
    'the equipment has been repaired' : "REPAIRED",
    'maintenance permormed' : 'MAINTENANCE',
    'installation done' : 'INSTALLATION',
    'Ad has been uploaded' : "UPLOAD",
    'Ad has been removed' : 'REMOVED',
    'ticket is closed' : 'CLOSED',
    'no required' : 'NOREQUIRED'
  }

  export const TicketNewCloseReason = {

    'Payment Made':'Payment Made',
    'Payment Not Made':'Payment Not Made',
    'A Contract Signed':'A Contract Signed',
    'A Contract Not Signed': 'A Contract Not Signed',
    'Successfully Repaired':'Successfully Repaired',
    'Repair Failed':'Repair Failed',
    'Done':'Done',
    'Cancelled':'Cancelled',
    'AD Uploaded':'AD Uploaded',
    'The Ad Needs Editing':'The Ad Needs Editing',
    'AD Removed':'AD Removed',
    'Successfully Installation': 'Successfully Installation',
    'Installation Failed':'Installation Failed',
   
  }
 
 