import { ReactComponent as Logo } from "./logo.svg";
import { ReactComponent as SidebarLogo } from "./sidebar-logo.svg";
import { ReactComponent as Dashboard } from "./dashboard.svg";
import { ReactComponent as Ticket } from "./ticket.svg";
import { ReactComponent as Billboard } from "./billboard.svg";
import { ReactComponent as Ads } from "./ads.svg";
import { ReactComponent as Search } from "./search.svg";
import { ReactComponent as Email } from "./email.svg";
import { ReactComponent as Bell } from "./bell.svg";
import { ReactComponent as Arrow } from "./arrow.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Logout } from "./logout.svg";
import { ReactComponent as UserIcon } from "./userProfileIcon.svg";
import { ReactComponent as PhoneIcon } from "./phone.svg";
import { ReactComponent as EditIcon } from "./edit.svg";
import { ReactComponent as EmailIcon } from "./emailicon.svg";
import { ReactComponent as UploadIcon } from "./upload.svg";
import { ReactComponent as Clock } from "./clock-circle.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as MapIcon } from "./streets.svg";
import { ReactComponent as CloseIcon } from "./closeIcon.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as EyeIcon } from "./eyeIcon.svg";
import { ReactComponent as ArrowDown } from "./arrow-down.svg";
import { ReactComponent as ED } from "./ed.svg";
import { ReactComponent as Sound } from "./sound.svg";
import { ReactComponent as Download } from "./download.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as ArrowRight } from "./arrowRight.svg";
import { ReactComponent as UploadUp } from "./uploadUp.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as CalenderIcon } from "./calender.svg";
import { ReactComponent as BillBoardIcon } from "./billboardIcon.svg";
import { ReactComponent as BiCost } from "./corb.svg";
import { ReactComponent as Paper } from "./paper-clip.svg";
import { ReactComponent as Send } from "./send.svg";
import { ReactComponent as Delete } from "./delete.svg";
import { ReactComponent as CalenIcon } from "./calenderIcon.svg";
import { ReactComponent as AutomaticCalculation } from "./automatic_calculation.svg";
import { ReactComponent as AgentProposal } from "./agent_proposal.svg";
import { ReactComponent as FinalCost } from "./fnal_cost.svg";

export const SVG = {
  Logo,
  SidebarLogo,
  Dashboard,
  Ticket,
  Billboard,
  Ads,
  Search,
  Email,
  Bell,
  Arrow,
  User,
  Logout,
  UserIcon,
  PhoneIcon,
  EditIcon,
  EmailIcon,
  UploadIcon,
  Clock,
  Eye,
  MapIcon,
  CloseIcon,
  Close,
  EyeIcon,
  ArrowDown,
  ED,
  Sound,
  Download,
  Copy,
  ArrowRight,
  UploadUp,
  Pencil,
  CalenderIcon,
  BillBoardIcon,
  BiCost,
  Paper,
  Send,
  Delete,
  CalenIcon,
  AutomaticCalculation,
  AgentProposal,
  FinalCost,
};
